import { FC } from "react";
import { Eservice } from "../cards/base-cards/CardEservice";
import styles from './table-items.module.css';
import logo from '../../../assets/icons/logo-app.svg';
import VerifiedSvg from "../../../../../shared/components/icons/VerifiedSvg";
import { IconSize } from "../../../../../shared/utils/enums";
import { Checkbox } from "../../../../../shared/components/antd";

interface RowTableSelectableProps {
    item:  Eservice;
    onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
    dropDownData?: { label: string; value: string; icon: JSX.Element }[];
    icon?:string;
    isSelected:boolean;
    onSelect:(item:Eservice, isSelected: boolean)=>void;
  }

export const RowTableSelectable: FC<RowTableSelectableProps> = (props) => {

    const handleCheckboxChange = () => {
        props.onSelect(props.item, !props.isSelected);
      };

      return (
        <tr key={props.item.id}>
          <td className={styles.fCaseContainer}>
            <div className={styles.fCase}>
         <Checkbox label={""} onChange={handleCheckboxChange} checked={props.isSelected} ></Checkbox>     

              <img
                src={props.item.imageUrl || logo}
                alt={`${props.item.name} logo`}
                className="w-8 h-8 rounded-full"
              />
            </div>
          </td>
          <td className={styles.nameCaseContainer}>
            <div className={styles.nameCaseMulti}>
              <span>{props.item.name}</span>
              <span><span className={styles.textInfo}>propriété de </span> {props.item.guichetName}</span>
              {props.item.verification && (
                <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
              )}
            </div>
          </td>
          <td className={styles.aCaseContainer}>
            <div className={styles.aCase}>{props.item.isKaraz ? 'karaz' : 'Externe'}</div>
          </td>
          <td className={styles.aCaseContainer}>
            <div className={styles.aCase}><i className="material-flex-icon text-lg text-blue-600">{props.icon}</i>{props.item.industry || 'N/A'}</div>
          </td>
         
        </tr>
      );
    };
    
