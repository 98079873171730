import { FC } from 'react';
import styles from './avatar.module.css';
import classNames from 'classnames';
interface AvatarProps {
  fullname: string;
  height?: string;
  width?: string;
  fontSize?: string;
  logoUser?: string; 
}

export const AvatarComponent: FC<AvatarProps> = ({
  fullname,
  height = "2rem",
  width = "2rem",
  fontSize = "13px",
  logoUser
}) => {
  const nameParts = fullname.trim().split(' ');
  const firstInitial = nameParts[0][0];
  const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '';
  const initials = `${firstInitial}${lastInitial}`.toUpperCase();
  
  let avatarContent;

  if (logoUser) {
    avatarContent = (
      <img
        className={styles.imgProfile}
        src={logoUser} 
        alt="User Avatar"
        style={{ height, width }} 
      />
    );
  } else {
    if (initials === 'undefined' || !fullname.trim() || fullname =='N/A') {
      avatarContent = (
        <img
          className={styles.imgProfile}
          src={'/karazal/DownloadFile?gedId=&or=img/defaultAvatar.png'} 
          alt="Default Avatar"
          style={{ height, width }} 
        />
      );
    } else {
      avatarContent = (
        <div
          className={classNames(styles['avatar-component-css-var'], styles.avatarStyle)}
          style={{ height, width, fontSize }}
        >
          {initials}
        </div>
      );
    }
  }

  return avatarContent;
};
