import React from 'react';
interface EserviceFonctionalityProps {
  imageURL: string;
  label: string;
  description: string;
}

const EserviceFonctionality: React.FC<EserviceFonctionalityProps> = ({
  imageURL,
  label,
  description
}) => {
  return (
    <div className="flex gap-4 items-center p-4 cursor-pointer">
      <img src={imageURL} className='w-10 h-10 dark:bg-white dark:rounded-md'/>
      <div className='flex flex-col'>
        <h4 className="text-base font-medium text-gray-600 dark:text-gray-300 text-start">
          {label}
        </h4>
        <p className="text-sm font-normal text-gray-400 dark:text-gray-500 text-start">{description}</p>
      </div>
    </div>
  );
};

export default EserviceFonctionality;
