import classNames from "classnames";
import {FC, ReactNode, useEffect} from "react";
import styles from './base-modal.module.css'
import {useHandleComponentVisibility} from "../../../hooks";

interface ModalProps{
    isOpen:boolean;
    afterClose?:()=>void;
    children:ReactNode;
    className?:string
    closeOnClickOutside?:boolean
}

export const BaseModal :FC<ModalProps>= (props) => {
   const {className="",isOpen=true,closeOnClickOutside=false}=props;
   const {ref,isOpen:hasOpen,setOpenStatus}=useHandleComponentVisibility(closeOnClickOutside,props.afterClose);
    const modalStyle=classNames({
        [styles.modal]:true,
        [className]:true,
        [styles.hideModal]:!hasOpen,
        [styles.showModal]:hasOpen
    })
    const modalContainerStyle=classNames({
        [styles.modalOverlay]:true,
        [styles.closeModal]:!hasOpen,
    })
    useEffect(() => {
        setOpenStatus(isOpen);
    }, [isOpen]);
   console.log("base modal",hasOpen);
    return (
        <>
            <div className={modalContainerStyle}>
                <div className={modalStyle} ref={ref}>
                    {props.children}
                </div>
            </div>
        </>
    );
};
