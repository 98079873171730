import {CardEservice , Section} from "../../../components/ui";
import {useEffect, useState} from "react";
import {ListEserviceRequest} from "../../../types/data.types";
import {useGetEserviceList, useMutateFavoriteEservice} from "../../../api";
import {Card} from "../../../components/ui/cards/Card";
import {EmptyData, SimpleModal} from "../../../../../shared/components";
import {Eservice} from "../../../components/ui/cards/base-cards/CardEservice";
import {convertToEserviceCard} from "../../../api/eservice/formatteur";
import {useNavigate} from "react-router-dom";
import {useHandleDomLocation} from "../../../hooks";
import { ACCES_IMPOSSIBLE_MODAL } from "../../../../../shared/utils/constants";
import {useQueryClient} from "@tanstack/react-query";
import {useDispatch} from "react-redux";
import {FavoriteEserviceRequest} from "../../../types";
import { getDropDownDataPermissions } from "../../../utils/helpers/permissions.helper";
import { setSelectedEservice } from "../../../features/eservices/eservicesSlice";

export const FavoriteEserviceWidget=()=>{
    const queryClient=useQueryClient()
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const {profilePathName}=useHandleDomLocation();
    const [showNotImplemented,setShowNotImplemented]=useState<boolean>(false);
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>,onClick:()=>navigate(`/${profilePathName}/catalogue`)},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>,onClick:()=>setShowNotImplemented(!showNotImplemented)},
    ]
    const [allEservicesParams, ] =
        useState<ListEserviceRequest>({
            numPage: 1,
            pageSize: 5,
            contributeur: false,
            status: '',
            reference: '',
            favorite: true,
            industry: '',
            entity: '',
            guichetName:'',
            idGuichet:'',
            Fields: 'ALL'
        });
    const getEserviceList = useGetEserviceList(allEservicesParams);
    const toggleFavoriteEservice =useMutateFavoriteEservice();
    const handleSetSelectedEservice = (window: Eservice) => {
        dispatch(setSelectedEservice(window));
    };
    const handleNavigateToEservice = (eserviceId: string,windowId:string, guichetName: string,) => {
        localStorage.setItem('guichetName', guichetName);
        navigate(`guichets/${windowId}/eservices/${eserviceId}`);
    };
    const handleSelectMenuEservice=(selection:Eservice)=> {
        const selectedEservice = convertToEserviceCard(getEserviceList.customHandler.data?.data).find(
            (eservice: Eservice) => eservice.id === selection.id
        );
        if (selectedEservice) {
            handleSetSelectedEservice(selectedEservice);
            handleNavigateToEservice(selectedEservice.id,selectedEservice.guichetId,"");
        }
    }

    const handleToggleFavoriteEservice=(data:FavoriteEserviceRequest)=>{
        toggleFavoriteEservice.mutate(data)
    }

    useEffect(() => {
        if (toggleFavoriteEservice.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ["list-services"] });
    }, [toggleFavoriteEservice.customHandler.isSuccess]);



    const renderFavoriteEserviceData=()=>{
        if((getEserviceList.isFetching || getEserviceList.customHandler.isSuccess) && (getEserviceList.customHandler.data?.pagination.totalLength!==0)) return (
            <div className="grid grid-cols-2 gap-2 gap-y-2">
                { convertToEserviceCard(getEserviceList.customHandler.data?.data).map(
                    (eservice: Eservice, index: number) =>
                        <CardEservice
                            key={index}
                            eservice={eservice as Eservice}
                            setFavoriteEservice={handleToggleFavoriteEservice}
                            dropDownData={getDropDownDataPermissions(eservice.role,eservice)}
                            cardAction={{
                                onSelectDropDown: (selection) => handleSelectMenuEservice(selection)
                            }}
                        />

                )}
                <Card toAdd onClick={()=>setShowNotImplemented(!showNotImplemented)}/>
            </div>)
        return (<div className="flex justify-center h-full items-center">
            <EmptyData title="Aucun e-service pour l'instant" description="La liste des e-services sera affichée ici"  searchIcon />
        </div>)
    }
    return (
        <Section title="e-Services favoris / fréquemment visités" materialIconLabel="grid_view" className="h-full" menuList={menuList}>
            {renderFavoriteEserviceData()}
            <SimpleModal isOpen={showNotImplemented} closeOnClickOutside={true}  afterClose={()=>setShowNotImplemented(!showNotImplemented)} title={"Accès impossible"}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>
        </Section>
    )
}