import { BreadCrumb, Tabs } from "../../../../../../../shared/components";
import { FC, useState } from "react";
import styles from '../eservice.param.module.css';
import { PageContentContainer } from "../../../../../layouts";
import { EServiceInfoTab } from "../tabs/internal-eservice-tabs/EserviceInfoTab";
import { EserviceMembresTab } from "../tabs/internal-eservice-tabs/EserviceMembresTab";
import { useGetIndustriesList } from "../../../../../api";
import { EserviceContributeursTab } from "../tabs/internal-eservice-tabs/EserviceContributeursTab";
import { EserviceUsersTab } from "../tabs/internal-eservice-tabs/EserviceUsersTab";
import { MaterialIcon } from "../../../../../../../shared/components/icons"
import { useHandleDomLocation } from "../../../../../hooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../shared/stores";
import { getTabListWithPermissions } from "../../../../../utils/helpers/permissions.helper";

export const InternalEServiceParamsInterface: FC = () => {
  const [selectedTab] = useState("eService");
  const getIndustriesList = useGetIndustriesList();
  const { profilePathName } = useHandleDomLocation();
  const { guichetId } = useParams<{ guichetId: string }>();
  const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
  const BreadCrumbItems = [
    { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}/guichets/${guichetId}` },
    { title: "e-Services", href: `/${profilePathName}/guichets/${guichetId}/eservices` },
    { title: selectedEservice.name || '', href: `/${profilePathName}/guichets/${guichetId}/eservices` }

  ]
  const tabList = [
    {
      name: 'eService', label: <><span className="icon">bubble_chart</span>E-service</>, children:
        <EServiceInfoTab data={{ industriesList: getIndustriesList?.customHandler?.data }}
          isLoading={{ industriesList: getIndustriesList.isLoading }}
          isSuccess={{ industriesList: getIndustriesList.customHandler.isSuccess }} />
    },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, children:<></>,disabled:true },
    { name: 'schemaRelational', label: <><span className="icon">schema</span>Schéma relationnel</>, children: <></>,disabled:true },
    { name: 'membres', label: <><span className="icon">manage_accounts</span>Membres</>, children: <EserviceMembresTab /> },
    { name: 'contributeurs', label: <><span className="icon">work_outline</span>Contributeurs</>, children: <EserviceContributeursTab /> },
    { name: 'user', label: <><span className="icon">group</span>Utilisateurs</>, children: <EserviceUsersTab /> },
    { name: 'control', label: <><span className="icon">login</span>Contrôles d’accès</>, children: <></>,disabled:true },
    { name: 'catalog', label: <><span className="icon">share</span>Partage sur catalogue</>, children: <></>,disabled:true },
    { name: 'delete', label: <span className={styles.deleteEservice}><span className="icon">delete</span>Suppression du e-service</span>, children: <></>,disabled:true },
  ];

  return (
    <PageContentContainer className="flex flex-col gap-1 !h-[100%]">
      <div className='p-5'>
        <BreadCrumb items={BreadCrumbItems} ></BreadCrumb>
      </div>
      <div className={styles.tabsContainer}>
        <Tabs tabs={getTabListWithPermissions(tabList,selectedEservice.role)} align="horizontal" current={selectedTab} defSelected={selectedTab}  />
      </div>
    </PageContentContainer >
  );
};

