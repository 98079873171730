import { FC } from 'react';
import TopMenuItem, { TopMenuItemProps } from '../menu-item/TopMenuItem';
import UserOrgSwitcher, { SwitcherItemProps } from '../menu-item/SwitcherItem';
import KSW_LOGO from '../../../../shared/assets/icons/ksw-logo.svg';
import KSW_DARK_LOGO from '../../../../shared/assets/icons/ksw-dark-logo.svg';

import styles from './menu.module.css';
import UserTopMenuItem from '../menu-item/UserTopMenuItem';
import { UserProfile } from '../../../../shared/types';
import {
  clearCookiesUser,
  clearUserProfile
} from '../../../../shared/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { MaterialIcon } from '../../../../shared/components/icons';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../../auth/features/auth/authSlice';
export interface TopMenuProps {
  topMenuItems: TopMenuItemProps[];
  user?: UserProfile;
  switcherItems: SwitcherItemProps[];
  handleItemClick: (item: string) => void;
  selectedItem: string;
  handleSwitch: (item: string) => void;
  selected: string;
}

export const TopMenu: FC<TopMenuProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOut = () => {
    console.log('enter');
    clearUserProfile();
    clearCookiesUser();
    dispatch(setAuthenticated(false));
    navigate('/landing', { replace: true });
  };
  console.log(props.user?.id);
  return (
    <div
      className={`${styles['kls-app-menu-module-css-var']} ${styles.topMenu}`}>
      <div className="w-10 h-10 rounded-lg p-4 bg-white/70 flex justify-center items-center border border-slate-200 shadow-sm hover:bg-slate-200/70 hover:border-slate-300 cursor-pointer dark:bg-slate-800/70 dark:border-slate-700 dark:hover:bg-slate-700/70 dark:hover:border-slate-600 ">
        <img className={styles.lightLogo} src={KSW_LOGO} />
        <img className={styles.darkLogo} src={KSW_DARK_LOGO} />
      </div>
      <div className={styles.topMenuItemsContainer}>
        {props.topMenuItems.map((item: TopMenuItemProps, index: number) => (
          <TopMenuItem
            handleClick={props.handleItemClick}
            selectedItem={props.selectedItem}
            key={index}
            label={item.label}
            link={item.link}
            materialIconLabel={item.materialIconLabel}></TopMenuItem>
        ))}
      </div>
      <div className={styles.topMenuSwitcherUser}>
        <div className={styles.switcherContainer}>
          {props.switcherItems.map((item: SwitcherItemProps, index: number) => (
            <UserOrgSwitcher
              handleClick={props.handleSwitch}
              selectedItem={props.selected}
              key={index}
              label={item.label}
              link={item.link}
              materialIconLabel={item.materialIconLabel}></UserOrgSwitcher>
          ))}
        </div>
        <MaterialIcon
          label="notifications_none"
          className="text-2xl text-white"
        />
        <UserTopMenuItem
          avatarUrl={props?.user?.avatarUrl}
          fullName={props?.user?.fullName}
          email={props?.user?.id}
          signOut={signOut}
        />
      </div>
    </div>
  );
};
