import { PermissionsEservice } from "./permission.types";
import { PermissionCheck } from "./permissionCheck";
import { RoleEservice } from "./role.types";

export type RolePermissionsEservice = {
  [role in RoleEservice]: {
    actions: Partial<Record<PermissionsEservice['actions'], PermissionCheck>>;
    menus: {
      topMenu: Partial<Record<PermissionsEservice['menus']['topMenu'], PermissionCheck>>;
    };
  };
};

export const EserviceRoles: RolePermissionsEservice = {
  Administrateur: {
    actions: {
      devenirContributeur: true,
      editer: true,
      aperçu: true,
      cloner: true,
      parametres: true,
      acceder: true,
      rejoindre: false,
      updateEservice: true,
    },
    menus:{
      topMenu:{
        catalog:true,
        contributeurs:true,
        control:true,
        delete:true,
        eService:true,
        membres:true,
        modules:true,
        schemaRelational:true,
        user:true
      }
    }
  },
  'Citizen developer': {
    actions: {
      devenirContributeur: true,
      editer: true,
      aperçu: true,
      cloner: true,
      parametres: true,
      acceder: true,
      rejoindre: false,
      updateEservice: false,
    },
    menus:{
      topMenu:{
        catalog:false,
        contributeurs:true,
        control:true,
        delete:false,
        eService:true,
        membres:false,
        modules:true,
        schemaRelational:true,
        user:false
      }
    }
  },
  Contributeur: {
    actions: {
      devenirContributeur: false,
      editer: false,
      aperçu: true,
      cloner: false,
      parametres: false,
      acceder: false,
      rejoindre: true,
      updateEservice: false,
    },
    menus:{
      topMenu:{
        catalog:false,
        contributeurs:false,
        control:false,
        delete:false,
        eService:false,
        membres:false,
        modules:false,
        schemaRelational:false,
        user:false
      }
    }
  },
};
