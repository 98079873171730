import { useEffect, useState } from 'react';
import { BreadCrumb, Tabs } from '../../../../../shared/components';
import { MaterialIcon } from '../../../../../shared/components/icons';
import { useHandleDomLocation } from '../../../hooks';
import { PageContentContainer } from '../../../layouts';
import { EservicesMarketplaceTab } from './tabs/eservices-marketplace-tab/EservicesMarketplaceTab';
import EserviceDetails from './tabs/eservices-marketplace-info/EseviceDetails';
import { useGetEserviceMarketPlaceDetails } from '../../../api/market-place/caller';
import {
  EserviceMarketPlaceData,
  EserviceMarketPlaceDetailsRequest
} from '../../../types/data/eservice.types';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

export const WindowMarketPlace = () => {
  const { profilePathName } = useHandleDomLocation();
  const navigate = useNavigate();
  const [showEserviceDetails, setShowEserviceDetails] =
    useState<boolean>(false);

  const [selectedEservice, setSelectedEservice] = useState<
    EserviceMarketPlaceData | undefined
  >();

  const returnAction=()=> {
    setShowEserviceDetails(false);
    setSelectedEservice(undefined);
    navigate('../eservices',{relative:"path"})
  }

  const setItem = (eserviceId: string) => {
    setServiceDetailsRequest((prevData) => ({
      ...prevData,
      serviceId: eserviceId
    }));
  };

  const [serviceDetailsRequest, setServiceDetailsRequest] =
    useState<EserviceMarketPlaceDetailsRequest>({ serviceId: '' });

  const getServiceDetails = useGetEserviceMarketPlaceDetails(
    serviceDetailsRequest
  );

  useEffect(() => {
    if (getServiceDetails.isSuccess) {
      setSelectedEservice(getServiceDetails.data.body[0]);
      setShowEserviceDetails(true);
    }
  }, [getServiceDetails.status]);

  const tabs = [
    {
      name: 'eServices',
      label: (
        <div className="flex items-center">
          <span>E-services</span>
        </div>
      ),
      children: <EservicesMarketplaceTab setSelectedItem={setItem} />
    },
    {
      name: 'composants',
      label: (
        <div className="flex items-center">
          <span>Composants métiers</span>
        </div>
      ),
      children: <div></div>
    }
  ];

  const BreadCrumbItems = [
    {
      icon: <MaterialIcon center label="home" className="text-base" />,
      href: `/${profilePathName}`
    },
    { title: 'Marketplace', href: '' }
  ];

  return (
    <PageContentContainer className="px-2.5 py-6 flex flex-col gap-3">
      {showEserviceDetails &&
      selectedEservice &&
      !getServiceDetails.isFetching ? (
        <EserviceDetails returnAction={returnAction} service={selectedEservice} />
      ) : getServiceDetails.isFetching ? (
        <Spin className="mt-[25%]" spinning size="large"></Spin>
      ) : (
        <>
          <BreadCrumb items={BreadCrumbItems} />
          <Tabs tabs={tabs} overflow />
        </>
      )}
    </PageContentContainer>
  );
};
