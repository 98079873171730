import {ChangeEvent, FC, useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {AllGuichetsRequest, AllGuichetsResponse} from "../../../../../../types";
import {useGetAllOrganizations, useRequestAssociateOrganization} from "../../../../../../api";
import {useParams} from "react-router-dom";
import classNames from "classnames";
import {Spin} from "antd";
import {WidowElementCard} from "../../../../../../components/ui";
import {Button, EmptyData, InputSearch, NotificationCard} from "../../../../../../../../shared/components";
import {NotificationType} from "../../../../../../../../shared/utils/enums";
import {OrganizationIdentityTabsProps, OrganizationInterfaces} from "../OrganizationIdentityTab";

const ALL_ORGANZIATIONS_INITIAL_STATE =   {
    numPage: 1,
    pageSize: 6,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    typeGuichet: '',
    reference: '',
    domainName: '',
    organisme: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
    objectType: 'ORGANISM'
}

export const OrganizationJoiningInterface:FC<OrganizationIdentityTabsProps> = (props) => {
    const queryClient = useQueryClient()
    const {className=""}=props;
    const [allOrganizationsRequest,setAllOrganizationsRequest]=useState<AllGuichetsRequest>(ALL_ORGANZIATIONS_INITIAL_STATE)
    const [selectedOrganization,setSelectedOrganization]=useState<AllGuichetsResponse|undefined>()
    const getAllOrganizations=useGetAllOrganizations(allOrganizationsRequest);
    const requestAssociateOrganization=useRequestAssociateOrganization();
    const {guichetId}=useParams();
    const{messageApi}=props;
    const [isErrorDisplayed,setErrorDisplayStatus]=useState<boolean>();

    const alertKey = 'join-organization-tab';

    useEffect(() => {
        if (requestAssociateOrganization.isPending) {
            messageApi.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [requestAssociateOrganization.isPending]);

    useEffect(() => {
        if (requestAssociateOrganization.customHandler.isError) {
            messageApi.error({
                key: alertKey,
                content:requestAssociateOrganization.customHandler.errorMessage ,
                duration: 2.5
            });
        }
    }, [requestAssociateOrganization.customHandler.isError]);


    useEffect(() => {
        if (requestAssociateOrganization.customHandler.isSuccess) {
            messageApi.success({
                key: alertKey,
                content: requestAssociateOrganization.customHandler.successMessage,
                duration: 2.5
            });
            if (requestAssociateOrganization.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
        }
    }, [requestAssociateOrganization.customHandler.isSuccess]);

    const rootStyles=classNames({
        "flex flex-col justify-center gap-4":true,
        [className]:true
    })
    const cardStyles=(checked:boolean)=>(classNames({
        "notDisplay":checked,
        "transition-colors transition-visibility duration-700":true
    }))
    const selectedCardContainerStyles=(enabled:boolean)=>(classNames({
        "show":enabled,
        "notDisplayV2":!enabled,
        "transition-all duration-700":true
    }))
    const renderOrganizationImage=(gedId:string|undefined)=>{
        if (gedId)  return <img className="rounded-full" src={`/karazal/DownloadFile?gedId=${gedId}`} alt=""/>
        return <></>;
    }
    const handleSearchChange=(e:ChangeEvent<HTMLInputElement>)=>{
        setAllOrganizationsRequest({...allOrganizationsRequest,name:e.target.value})
    }

    const handleClearSearch=()=>{
        setAllOrganizationsRequest({...allOrganizationsRequest,name:""})
    }
    const handleCheckOrganization=(id:string)=>{
        setSelectedOrganization(getAllOrganizations.customHandler.data?.data.find((item)=>item.guichetId===id))
    }
    const handleUncheckOrganization=()=>{
        setSelectedOrganization(undefined)
    }
    const renderLoader=()=>{
        if (getAllOrganizations.isFetching || getAllOrganizations.isLoading) return  <div className="flex min-h-24 justify-center items-center"><Spin size="large"/></div>
        return <></>
    }
    const handleAssociateOrganization=()=>{
        if (!(guichetId && selectedOrganization?.guichetId)) {setErrorDisplayStatus(true);return;}
        const request ={guichetId,organisationId:selectedOrganization?.guichetId};
        requestAssociateOrganization.mutate(request);
    }
    const handleSwitch=()=>{
        if (props.onSwitch) props.onSwitch(OrganizationInterfaces.CREATION);
    }
    const renderOrganizations=()=>{
        if (getAllOrganizations.customHandler.isSuccess && !getAllOrganizations.isFetching && getAllOrganizations.customHandler.data?.pagination.totalLength!==0)
            return  getAllOrganizations.customHandler.data?.data.map((data)=>(
                <div key={data.guichetId} className={cardStyles(data.guichetId===selectedOrganization?.guichetId)}>
                    <WidowElementCard  title={data.name} checked={data.guichetId===selectedOrganization?.guichetId} state={data.status || ""} onCheck={handleCheckOrganization} icon={renderOrganizationImage(data.logoGedId)} cardKey={data.guichetId}/>
                </div>
            ))
        else if(!getAllOrganizations.isFetching &&  getAllOrganizations.customHandler.isError)
            return (<EmptyData errorIcon title="Un problème est survenu" description="Impossible de charger les données. Veuillez vérifier votre connexion ou réessayer plus tard."/>)
        else if(!getAllOrganizations.isFetching) return <EmptyData  searchIcon title="Aucune organisation trouvée" description="Essayez de modifier vos critères de recherche."/>
        return (<></>);
    }
    const renderErrorCard=()=>{
        if (isErrorDisplayed) return (
            <NotificationCard
                className="w-1/2 !mb-2"
                type={NotificationType.ERROR}
                closeable
                onClose={()=>setErrorDisplayStatus(false)}
            >
                <div className="flex flex-col gap-2 justify-center text-xs font-normal">
                    <p>
                        Veuillez sélectionner l&#39;organisation à laquelle votre guichet appartient.
                        Si vous ne retrouvez pas votre organisation, vous pouvez la créer.
                    </p>
                    <p>
                        NB : Notez que des documents justificatifs vous seront demandés
                        pour pouvoir fournir le statut “Vérifiée” à une nouvelle organisation.
                    </p>
                </div>
            </NotificationCard>
        )
        return <></>
    }
    const renderErrorCardContainerStyles=classNames({
        ["max-h-[20vh] transition-all duration-700 overflow-y-hidden"]:true,
        ["max-h-0 "]:!isErrorDisplayed
    })
    return (
        <div className={rootStyles}>
            <div className={renderErrorCardContainerStyles}>
                {renderErrorCard()}
            </div>
            <span className="font-semibold text-sm text-blue-700">Associer votre guichet à une organisation</span>
            <InputSearch size="lg" onChange={handleSearchChange} onClear={handleClearSearch} placeholder="Rechercher"/>
            <div className="flex flex-col gap-6 w-full min-h-44 ">
                {
                    <div className={selectedCardContainerStyles(selectedOrganization!==undefined)}>
                        {selectedOrganization &&   <WidowElementCard  title={selectedOrganization.name} checked={true} state={selectedOrganization.status || ""} onCheck={handleUncheckOrganization} icon={renderOrganizationImage(selectedOrganization.logoGedId)} cardKey={selectedOrganization.guichetId}/>}
                    </div>
                }
                <div className="flex flex-col gap-2 w-full">
                    {renderOrganizations()}
                </div>
                {renderLoader()}
            </div>
            <div className="flex gap-3 justify-end w-full">
                <Button size="sm" label="Créer organisation"   color="primary" styling="outline" onClick={handleSwitch}/>
                <Button size="sm" label="Associer organisation"   color="primary" styling="solid" onClick={handleAssociateOrganization}/>
            </div>
        </div>)
}
