import {
  GenericResponse,
  GenericResponseWrapper
} from '../../../../shared/types';
import { PageResponse } from '../../../../shared/types/api.type';
import { HttpMethods } from '../../../../shared/utils/enums';
import {
  fetchGenericJaxRsRequestFromFormData,
  fetchGenericJaxRsRequestFromJson
} from '../../../../shared/utils/helpers';
import {
  AllGuichetsRequest,
  AllGuichetsResponse,
  FavoriteGuichetRequest,
  OnboardingGuichetCreationRequest,
  OnboardingGuichetCreationResponse,
  OnboardingJoinGuichetRequest,
  RequestJoinAdministrateusPage,
  AdministrateusPageRequest,
  RejectWindowInvitation
} from '../../types/';
import { WindowInvitationRequest, WindowInvitationResponse } from '../../types/data/eservice.types';
import {
  ADD_FAVORITE_WINDOW_API_PATH,
  ALL_WINDOW_API_PATH,
  JOIN_WINDOW_API_PATH,
  SUBMIT_WINDOW_CREATION_API_PATH, ADMINISTRATEUS_PAGE_LIST_REQUEST_API_PATH,
  SEND_WINDOW_ESERVICE_API_PATH,
  REJECT_WINDOW_INVITATION_API_PATH
} from './apiPath';

export const getAllGuichets = (
  data: AllGuichetsRequest
): Promise<GenericResponse<PageResponse<AllGuichetsResponse[]>>> => {
  return fetchGenericJaxRsRequestFromFormData(
    data,
    ALL_WINDOW_API_PATH,
    HttpMethods.POST
  );
};
export const addFavoriteGuichet = (
  data: FavoriteGuichetRequest
): Promise<GenericResponse<null>> => {
  return fetchGenericJaxRsRequestFromFormData(
    data,
    ADD_FAVORITE_WINDOW_API_PATH,
    HttpMethods.POST
  );
};
export const postCreateWindow = (
  data: OnboardingGuichetCreationRequest
): Promise<GenericResponseWrapper<OnboardingGuichetCreationResponse>> => {
  return fetchGenericJaxRsRequestFromFormData(
    data,
    SUBMIT_WINDOW_CREATION_API_PATH,
    HttpMethods.POST
  );
};
export const postJoinWindow = (
  data: OnboardingJoinGuichetRequest
): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(
    data,
    JOIN_WINDOW_API_PATH,
    HttpMethods.POST
  );
};
export const getRequestJoinAdministrateusPage = (
  data: RequestJoinAdministrateusPage
): Promise<GenericResponse<PageResponse<AdministrateusPageRequest[]>>> => {
  return fetchGenericJaxRsRequestFromFormData(
    data,
    ADMINISTRATEUS_PAGE_LIST_REQUEST_API_PATH,
    HttpMethods.POST
  );
};

export const postSendWindowInvitation = (data: WindowInvitationRequest): Promise<GenericResponse<WindowInvitationResponse>> => {
  return fetchGenericJaxRsRequestFromFormData(data, SEND_WINDOW_ESERVICE_API_PATH, HttpMethods.POST);
};

export const postRejectWindowInvitation = (data: RejectWindowInvitation): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data, REJECT_WINDOW_INVITATION_API_PATH, HttpMethods.POST);
};