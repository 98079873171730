import { BreadCrumb } from "../../../../shared/components"
import { MaterialIcon } from "../../../../shared/components/icons"
import FooterComponent from "../landing-page/FooterComponent"
import NavbarComponent from "../landing-page/NavbarComponent"
import { AvisComponent } from "./AvisComponent"
import { CatalogComponent } from "./CatalogComponent"
import styles from './catalogue.module.css';
import { DomaineComponent } from "./DomaineComponent"


export const CatalogueEservicesPage=()=>{
    const BreadCrumbItems=[
        {icon:<MaterialIcon center label="home" className="text-base"/>,href:''},
        {title:"Catalogue e-services",href: ""},
      ]
    return <div className={styles['catalog-eservice-page-css-var']}>
    <NavbarComponent/>
    <div className="dark:bg-black"><BreadCrumb items={BreadCrumbItems} ></BreadCrumb></div>
    <CatalogComponent/>
    <DomaineComponent/>
    <AvisComponent/>
    <FooterComponent/>
    </div>
}