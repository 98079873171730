import FilterSvg from "../../../../../../../../shared/components/icons/FilterSvg.tsx";
import { FilterBar } from "../../../../../../components/ui/filter-bar/FilterBar.tsx";
import { FilterBarContentProps } from "../../../../../../components/ui/filter-bar/FilterBarContent.tsx";
import { FilterBarInputProps } from "../../../../../../components/ui/filter-bar/FilterBarInput.tsx";
import { fBsEserv } from "../../../../../../mockdata/MockDataFilterBar.ts";
import { Table } from "../../../../../../components/ui/table/Table.tsx";
import { EmptyData, SimpleModal } from "../../../../../../../../shared/components";
import { ESERVICE_WIDGET_MOCK_DATA } from "../../../../../../mockdata/MockDataMember.ts";
import { useState } from "react";
import { ACCES_IMPOSSIBLE_MODAL } from "../../../../../../../../shared/utils/constants/components.constants.tsx";


export const EserviceUsersTab = () => {
const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
const [page, setPage] = useState<number>(1);
const [selectedRowOption, setSelectedRowOption] = useState<{ id: string; selectedValue: string; emailPersonnel: string; nameUser: string; logoUser: string; role: string; ownerLogin: string; note: string } | null>(null);

    const fBc: FilterBarContentProps = {
        label: 'Utilisateurs',
        searchLength: 9,
        buttons: []
    }


    const fBi: FilterBarInputProps = {
        filterBarInputId: 'search',
        filterBarInputPlaceHolder: 'Rechercher',
        filterBarInputType: 'text',
        filterBarInputValue: '',
        filterButton: {
          handleClick: () => {
          },
          icon: FilterSvg,
          label: 'Filtres',
          selected: false
        },
    
        isGridDisplayMode: false,
       
        onChange: () => {
          
        },
        onClear: () => {
          
        },
        onSearch: () => {
         
        },
       
        
      };

    return(
          <div className="flex flex-col gap-7 p-5 h-[100%]">
<FilterBar
      filterBarContent={fBc}
      filterBarInput={fBi}
      filterBarSortBy={fBsEserv}
    />
{ESERVICE_WIDGET_MOCK_DATA.length < 1 ? <EmptyData
                            icon={emptyIcon}
                            title={"Aucun pour l'instant"}
                            description={"La liste sera affichée ici"}
                        /> : 
                                <Table
                    type="users"
                    setCurrentPage={(page) => setPage(page) }
                    currentPage={page}
                    headerItems={{
                        items: ['Utilisateur', '', 'login']
                    }}
                    onSelectDropDownForMembers={setSelectedRowOption}
                    rows={ESERVICE_WIDGET_MOCK_DATA} 
                    totalItems={9}    
                    />
                }  
              <SimpleModal isOpen={selectedRowOption!=null}  closeOnClickOutside={true}  afterClose={()=>setSelectedRowOption(null)} title={"Accès impossible"}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>   
                
</div>);
}