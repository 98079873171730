export const PHONE_NUMBER_DATA = [
    { country: 'Algeria', prefix: '+213', placeholder: '551 23 45 67', countryCode: 'DZ' },
    { country: 'Angola', prefix: '+244', placeholder: '923 456 789', countryCode: 'AO' },
    { country: 'Benin', prefix: '+229', placeholder: '97 12 34 56', countryCode: 'BJ' },
    { country: 'Botswana', prefix: '+267', placeholder: '71 234 567', countryCode: 'BW' },
    { country: 'Burkina Faso', prefix: '+226', placeholder: '70 12 34 56', countryCode: 'BF' },
    { country: 'Burundi', prefix: '+257', placeholder: '79 12 34 56', countryCode: 'BI' },
    { country: 'Cameroon', prefix: '+237', placeholder: '6712 3456', countryCode: 'CM' },
    { country: 'Cape Verde', prefix: '+238', placeholder: '991 23 45', countryCode: 'CV' },
    { country: 'Chad', prefix: '+235', placeholder: '63 12 34 56', countryCode: 'TD' },
    { country: 'Comoros', prefix: '+269', placeholder: '321 23 45', countryCode: 'KM' },
    { country: 'Congo (Brazzaville)', prefix: '+242', placeholder: '06 123 4567', countryCode: 'CG' },
    { country: 'Congo (Kinshasa)', prefix: '+243', placeholder: '81 123 4567', countryCode: 'CD' },
    { country: 'Djibouti', prefix: '+253', placeholder: '77 12 34 56', countryCode: 'DJ' },
    { country: 'Egypt', prefix: '+20', placeholder: '100 123 4567', countryCode: 'EG' },
    { country: 'Equatorial Guinea', prefix: '+240', placeholder: '222 123 456', countryCode: 'GQ' },
    { country: 'Eritrea', prefix: '+291', placeholder: '712 3456', countryCode: 'ER' },
    { country: 'Eswatini', prefix: '+268', placeholder: '7612 3456', countryCode: 'SZ' },
    { country: 'Ethiopia', prefix: '+251', placeholder: '91 123 4567', countryCode: 'ET' },
    { country: 'Gabon', prefix: '+241', placeholder: '06 123 4567', countryCode: 'GA' },
    { country: 'Gambia', prefix: '+220', placeholder: '301 2345', countryCode: 'GM' },
    { country: 'Ghana', prefix: '+233', placeholder: '24 123 4567', countryCode: 'GH' },
    { country: 'Guinea', prefix: '+224', placeholder: '621 23 45 67', countryCode: 'GN' },
    { country: 'Guinea-Bissau', prefix: '+245', placeholder: '955 123 456', countryCode: 'GW' },
    { country: 'Ivory Coast', prefix: '+225', placeholder: '01 23 45 67', countryCode: 'CI' },
    { country: 'Kenya', prefix: '+254', placeholder: '712 345 678', countryCode: 'KE' },
    { country: 'Lesotho', prefix: '+266', placeholder: '5012 3456', countryCode: 'LS' },
    { country: 'Liberia', prefix: '+231', placeholder: '886 123 456', countryCode: 'LR' },
    { country: 'Libya', prefix: '+218', placeholder: '91 234 5678', countryCode: 'LY' },
    { country: 'Madagascar', prefix: '+261', placeholder: '32 12 345 67', countryCode: 'MG' },
    { country: 'Malawi', prefix: '+265', placeholder: '881 234 567', countryCode: 'MW' },
    { country: 'Mali', prefix: '+223', placeholder: '65 12 34 56', countryCode: 'ML' },
    { country: 'Mauritania', prefix: '+222', placeholder: '45 12 34 56', countryCode: 'MR' },
    { country: 'Mauritius', prefix: '+230', placeholder: '5251 2345', countryCode: 'MU' },
    { country: 'Morocco', prefix: '+212', placeholder: '612-345678', countryCode: 'MA' },
    { country: 'Mozambique', prefix: '+258', placeholder: '82 123 4567', countryCode: 'MZ' },
    { country: 'Namibia', prefix: '+264', placeholder: '81 123 4567', countryCode: 'NA' },
    { country: 'Niger', prefix: '+227', placeholder: '94 12 34 56', countryCode: 'NE' },
    { country: 'Nigeria', prefix: '+234', placeholder: '801 234 5678', countryCode: 'NG' },
    { country: 'Rwanda', prefix: '+250', placeholder: '788 123 456', countryCode: 'RW' },
    { country: 'Senegal', prefix: '+221', placeholder: '77 123 45 67', countryCode: 'SN' },
    { country: 'Seychelles', prefix: '+248', placeholder: '251 2345', countryCode: 'SC' },
    { country: 'Sierra Leone', prefix: '+232', placeholder: '76 123 456', countryCode: 'SL' },
    { country: 'Somalia', prefix: '+252', placeholder: '61 123 4567', countryCode: 'SO' },
    { country: 'South Africa', prefix: '+27', placeholder: '71 123 4567', countryCode: 'ZA' },
    { country: 'South Sudan', prefix: '+211', placeholder: '91 123 4567', countryCode: 'SS' },
    { country: 'Sudan', prefix: '+249', placeholder: '91 123 4567', countryCode: 'SD' },
    { country: 'Tanzania', prefix: '+255', placeholder: '655 123 456', countryCode: 'TZ' },
    { country: 'Togo', prefix: '+228', placeholder: '90 12 34 56', countryCode: 'TG' },
    { country: 'Tunisia', prefix: '+216', placeholder: '21 234 567', countryCode: 'TN' },
    { country: 'Uganda', prefix: '+256', placeholder: '712 345678', countryCode: 'UG' },
    { country: 'Zambia', prefix: '+260', placeholder: '95 123 4567', countryCode: 'ZM' },
    { country: 'Zimbabwe', prefix: '+263', placeholder: '77 123 4567', countryCode: 'ZW' },
];
export const REQUEST_MODULE_STATUS={
    DRAFT:"Brouillon",
    ACCEPTED :"Acceptée",
    REJECTED :"Refusée",
    DELETED:"Supprimée",
}

export const ACCES_IMPOSSIBLE_MODAL =<div className="flex flex-col items-center gap-5 p-5 w-[500px]">
                                       <span className='text-gray-800 text-center text-xl font-bold dark:text-white'>Cher utilisateur</span>
                                       <span className='text-gray-500 font-normal text-center text-sm dark:text-slate-200'>Nous travaillons activement pour rendre cette fonctionnalité disponible bientôt. Merci pour votre patience!</span>
                                        </div>;
