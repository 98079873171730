import { FilterBar } from '../../../components/ui/filter-bar/FilterBar';
import { useEffect, useState } from 'react';
import {
  ButtonFilterProps,
  FilterBarContentProps
} from '../../../components/ui/filter-bar/FilterBarContent';
import StarSvg from '../../../../../shared/components/icons/StarSvg';
import PeopleSvg from '../../../../../shared/components/icons/PeopleSvg';
import { FilterBarInputProps } from '../../../components/ui/filter-bar/FilterBarInput';
import FilterSvg from '../../../../../shared/components/icons/FilterSvg';
import LocalisationSvg from '../../../../../shared/components/icons/LocalisationSvg';
import GridSvg from '../../../../../shared/components/icons/GridSvg';
import ListSvg from '../../../../../shared/components/icons/ListSvg';
import { Table } from '../../../components/ui/table/Table';
import { PageContentContainer } from '../../../layouts';
import { useNavigate } from 'react-router-dom';
import { FilterBarSortByProps } from '../../../components/ui/filter-bar/FilterBarSortBy';
import { CardGrid } from '../../../components/ui/card-grid/CardGrid';
import {
  useFavoriteGuichet,
  useGetAllGuichets
} from '../../../api/window/caller';
import { MaterialIcon } from '../../../../../shared/components/icons';
import { DropDownListButtonProps } from '../../../../../shared/types';
import { useDispatch } from 'react-redux';
import { setSelectedGuichet } from '../../../features/guichets/guichetsSlice';
import { useHandleDomLocation } from '../../../hooks';
import {
  convertToGuichetCard,
  useGetAllOrganizations,
  useGetIndustriesList
} from '../../../api';
import { Guichet } from '../../../components/ui/cards/base-cards/CardGuichet';
import { convertToItemDropDown } from '../../../api/window/formatter';
import { AllGuichetsRequest, FavoriteGuichetRequest } from '../../../types';
import { CreateWindowPage } from './CreateWindowPage';
import { BaseModal, BreadCrumb, EmptyData, Map, SimpleModal } from '../../../../../shared/components';
import { ACCES_IMPOSSIBLE_MODAL } from '../../../../../shared/utils/constants';

export const WindowListPage = () => {
  const navigate = useNavigate();

  const { isWindowProfile, profilePathName } = useHandleDomLocation();

  const [favorite, setFavorite] = useState<boolean>(false);

  const [contribute, setContribute] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [showGrid, setShowGrid] = useState<boolean>(true);

  const [showTable, setShowTable] = useState<boolean>(false);

  const [showMap, setShowMap] = useState<boolean>(false);

  const [totalItems, setTotalItems] = useState(0);

  const [sortBy, setSortBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'recent', label: 'Récent' });
  const [displayCreateGuichet, setDisplayCreateGuichet] =
    useState<boolean>(false);
    const [displayJoindreGuichet, setDisplayJoindreGuichet] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [domaine, setDomaine] = useState<string>('');

  const [organisme, setOrganisme] = useState<string>('');

  const [role, setRole] = useState<string>('');

  const [secteur, setSecteur] = useState<string>('');
  const [categorie, setCategorie] = useState<string>('');

  const [page, setPage] = useState<number>(1);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  const [selectedCardOption, setSelectedCardOption] = useState<{
    id: string;
    selectedValue: string;
  } | null>(null);

  const [dataFavoriteRequest, setDataFavoriteRequest] =
    useState<FavoriteGuichetRequest>({
      guichetId: '',
      favorite: false
    });

  const [guichetsParams, setGuichetsParams] = useState<AllGuichetsRequest>({
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    typeGuichet: '',
    reference: '',
    domainName: '',
    organisme: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
    objectType: 'GUICHET'
  });

  const [guichetsValue, setGuichetsValue] = useState<Guichet[]>([]);

  const [position] = useState<L.LatLngLiteral>({
    lat: 31.7917,
    lng: -7.0926
  });

  const getGuichets = useGetAllGuichets(guichetsParams);

  const getOrganizations = useGetAllOrganizations({
    ...{
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      key: '',
      name: '',
      status: '',
      typeGuichet: '',
      reference: '',
      domainName: '',
      organisme: '',
      email: '',
      activation: '',
      verification: '',
      tags: '',
      type: '',
      role: '',
      favorite: false,
      industry: '',
      entity: '',
      recent: '',
      Fields: 'ALL'
    },
    objectType: 'ORGANISM'
  });
  const dispatch = useDispatch();

  const handleSetSelectedGuichet = (guichet: Guichet) => {
    dispatch(setSelectedGuichet(guichet));
  };

  const getIndustriesList = useGetIndustriesList();
  useFavoriteGuichet(dataFavoriteRequest);
  useEffect(() => {
    if (
      organisme.length > 0 ||
      domaine.length > 0 ||
      secteur.length > 0 ||
      role.length > 0
    ) {
      setReset(true);
    } else {
      setReset(false);
    }
    setIsFetching(true);
    setGuichetsParams((prevData) => ({
      ...prevData,
      name: searchValue,
      favorite: favorite,
      secteur: secteur,
      organisme: organisme,
      typeGuichet: categorie,
      industry: domaine.replace(/\s*\(.*?\)/g, ''),
      role: role,
      recent: sortBy.value,
      numPage: page,
      contributeur: contribute,
      pageSize: showMap ? 1000 : 10
    }));
  }, [
    showMap,
    page,
    contribute,
    categorie,
    organisme,
    secteur,
    role,
    domaine,
    sortBy,
    searchValue,
    favorite
  ]);
  useEffect(() => {
    if (getGuichets.isFetching) {
      setIsFetching(true);
    }
    if (getGuichets.isSuccess) {
      if ('error' in getGuichets.data) {
        navigate('/authentication', { replace: true });
        return;
      }
      const guichets = getGuichets.data.body.data;
      const totalItems = getGuichets.data.body.pagination.totalLength;
      if (guichets !== undefined) {
        if (totalItems !== undefined) {
          setTotalItems((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setGuichetsValue((prevData) => {
          return showGrid && page > 1
            ? [...prevData, ...convertToGuichetCard(guichets)]
            : convertToGuichetCard(guichets);
        });
        setIsFetching(false);
      }
    }
  }, [guichetsParams, getGuichets.status]);

  useEffect(() => {
    if (selectedCardOption?.id) {
      const selectedGuichet = guichetsValue.find(
        (guichet) => guichet.id === selectedCardOption.id
      );
      if (selectedGuichet) {
        handleSetSelectedGuichet(selectedGuichet);
        handleNavigateToGuichet(selectedGuichet.id, selectedGuichet.name);
      }
    }
  }, [selectedCardOption]);

  const handleNavigateToGuichet = (guichetId: string, guichetName: string) => {
    localStorage.setItem('guichetName', guichetName);
    navigate(`${guichetId}/eservices`);
  };
  const DropDownButtonRightIcon = <MaterialIcon label="expand_more" />;
  const renderNewWidowButton: () => DropDownListButtonProps | object = () => {
    if (isWindowProfile)
      return {
        dropDownListButton: {
          buttonAttribute: {
            label: 'Nouveau guichet',
            onClick: () => console.log('Primary button clicked for Card 1'),
            color: 'primary',
            styling: 'solid',
            size: 'sm',
            rightIcon: DropDownButtonRightIcon
          },
          menuAttributes: {
            action: true,
            list: [
              {
                label: 'Créer guichet',
                value: 'new',
                icon: (
                  <MaterialIcon
                    label="add"
                    className="text-base text-blue-600"
                  />
                ),
                onClick: () => {
                  setDisplayCreateGuichet(true);
                }
              },
              {
                label: 'Rejoindre un guichet',
                value: 'existing',
                icon: (
                  <MaterialIcon
                    label="corporate_fare"
                    className="text-base text-pink-600"
                  />
                ),
                onClick: () => {
                  setDisplayJoindreGuichet(true);
                }
              }
            ]
          }
        }
      };

    return {};
  };
  const renderContribute = (): ButtonFilterProps => {
    return {
      icon: PeopleSvg,
      label: 'Où je participe',
      isSelected: contribute,
      handleClick: (contribute) => {
        if (page > 1) {
          setPage(1);
        }
        setGuichetsValue([]);
        setContribute(!contribute);
      }
    };
  };

  const fBc: FilterBarContentProps = {
    label: 'Guichets',
    searchLength: totalItems,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setGuichetsValue([]);
          setFavorite(!favorite);
        }
      },
      ...(profilePathName === 'guichet' ? [renderContribute()] : [])
    ],
    ...renderNewWidowButton()
  };

  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher...',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: !showMap
      ? {
          handleClick: (showFilters) => {
            setShowFilters(!showFilters);
          },
          icon: FilterSvg,
          label: 'Filtres',
          selected: showFilters,
          reset: reset,
          resetClick: () => {
            setDomaine('');
            setOrganisme('');
            setSecteur('');
            if (role !== '') {
              setContribute(false);
              setRole('');
            }
            setReset(false);
          }
        }
      : undefined,
    mapButton: {
      handleClick: (showMap) => {
        if (showMap) {
          return;
        }
        setPage(1);
        setShowGrid(false);
        setShowTable(false);
        setShowMap(!showMap);
      },
      icon: LocalisationSvg,
      selected: showMap
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setPage(1);
        setShowMap(false);
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setPage(1);
        setShowMap(false);
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setGuichetsValue([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setGuichetsValue([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setGuichetsValue([]);
      setSearchValue('');
    },
    onSearch: () => {}
  };
  const fBs: FilterBarSortByProps = {
    items: [
      {
        data: convertToItemDropDown(getOrganizations.customHandler.data?.data),
        label: 'Organisation(s)',
        placeHolder: 'Organisation',
        selectedValue: organisme,
        multiple: true,
        clearable: true,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }
          if (e == organisme) return;
          setGuichetsValue([]);
          setOrganisme(e);
        }
      },
      {
        data: getIndustriesList.customHandler.data,
        label: 'Domaine(s)',
        placeHolder: 'Domaine',
        selectedValue: domaine,
        multiple: true,
        clearable: true,
        onChange: (e: string) => {
          console.log('secteur', e);
          if (page > 1) {
            setPage(1);
          }
          if (e == domaine) return;
          setGuichetsValue([]);
          setDomaine(e);
        }
      },
      {
        data: [
          {
            label: 'Public',
            value: 'Public'
          },
          {
            label: 'Privé',
            value: 'Privé'
          },
          {
            label: 'Public-privé',
            value: 'Public-privé'
          }
        ],
        label: 'Secteur',
        placeHolder: 'Secteur',
        selectedValue: secteur,
        clearable: true,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }
          if (e == secteur) return;
          setGuichetsValue([]);
          setSecteur(e);
        }
      },
      ...(isWindowProfile
        ? [
            {
              data: [
                { label: 'Propriétaire', value: 'Propriétaire' },
                { label: 'Super administrateur', value: 'Super administrateur' }
              ],
              label: 'Rôle',
              placeHolder: 'Rôle',
              selectedValue: role,
              clearable: true,
              onChange: (e: string) => {
                if (page > 1) {
                  setPage(1);
                }
                if (e == role) return;
                if (!contribute) setContribute(true);
                setGuichetsValue([]);
                setRole(e);
              }
            }
          ]
        : [])
    ]
  };
  const fBMap: FilterBarSortByProps = {
    items: [
      {
        data: [
          {
            label: 'Guichet',
            value: 'Guichet'
          },
          {
            label: 'e-Shop',
            value: 'e-Shop'
          }
        ],
        label: 'Catégorie(s)',
        placeHolder: 'Catégorie',
        selectedValue: categorie,
        onChange: (e: string) => {
          console.log('secteur', e);
          if (page > 1) {
            setPage(1);
          }
          if (e == categorie) return;
          setGuichetsValue([]);
          setCategorie(e);
        }
      },
      ...(categorie !== 'e-Shop'
        ? [
            {
              data: getIndustriesList.customHandler.data,
              label: 'Domaine(s)',
              placeHolder: 'Domaine',
              multiple: true,
              clearable: true,
              selectedValue: domaine,
              onChange: (e: string) => {
                if (page > 1) {
                  setPage(1);
                }
                if (e == domaine) return;
                setGuichetsValue([]);
                setDomaine(e);
              }
            }
          ]
        : []),

      {
        data: [{ label: '', value: '' }],
        label: 'Préfecture/province',
        placeHolder: 'Préfecture/province',
        selectedValue: '',
        onChange: () => {}
      },
      {
        data: [
          {
            label: '',
            value: ''
          }
        ],
        label: 'Arrondissement/commune',
        placeHolder: 'Arrondissement/commune',
        selectedValue: '',
        onChange: () => {}
      }
    ]
  };
  const BreadCrumbItems = [
    {
      icon: <MaterialIcon center label="home" className="text-base" />,
      href: `/${profilePathName}`
    },
    { title: 'Guichets' }
  ];
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 h-[100%] rounded-md">
      <BreadCrumb items={BreadCrumbItems} />
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarSortBy={fBs}
      />

      {/* Grid Loader */}
      {showGrid &&
        (guichetsValue.length > 0 || isFetching ? (
          <CardGrid
            setFavoriteParams={setDataFavoriteRequest}
            dataDomaine={getIndustriesList.customHandler.data}
            isFetching={isFetching}
            type="guichet"
            totalPages={Math.ceil(totalItems / 10)}
            currentPage={page}
            fetchMoreData={(nextPage: number) => {
              setPage(nextPage);
            }}
            items={guichetsValue}
            onSelectDropDown={setSelectedCardOption}
          />
        ) : (
          <EmptyData title={''} description="Aucun résultat" />
        ))}

      {/* Map Loader */}
      {showMap && (
        <Map
          clearFilters={() => {
            setDomaine('');
            setCategorie('');
          }}
          isFetching={isFetching}
          openAction={setSelectedCardOption}
          filtersData={fBMap}
          position={position}
          markersData={guichetsValue}
        />
      )}

      {/* Table Loader */}
      {showTable &&
        (guichetsValue.length > 0 || isFetching ? (
          <Table
            isFitching={isFetching}
            setFavoriteParams={setDataFavoriteRequest}
            type="guichet"
            dataDomaine={getIndustriesList.customHandler.data}
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalItems}
            onSelectDropDown={setSelectedCardOption}
            headerItems={{
              items: ['', 'Nom', 'Domaine', 'Rôle']
            }}
            rows={guichetsValue}
          />
        ) : (
          <EmptyData title={''} description="Aucun résultat" />
        ))}
      <BaseModal
        className="w-fit rounded-lg py-2 px-1 dark:bg-slate-800"
        isOpen={displayCreateGuichet}
        afterClose={() => setDisplayCreateGuichet(false)}>
        <CreateWindowPage
          notifySuccess={() => {
            setDisplayCreateGuichet(false);
          }}
        />
      </BaseModal>
      <SimpleModal isOpen={displayJoindreGuichet}  closeOnClickOutside={true}  afterClose={()=>setDisplayJoindreGuichet(!displayJoindreGuichet)} title={"Accès impossible"}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>
    </PageContentContainer>
  );
};
