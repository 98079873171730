import { PageContentContainer } from '../../../layouts';
import { OrgCreationStep } from '../../../components/ui';
import { useCreateWindow, useGetIndustriesList } from '../../../api';
import { FC, useEffect } from 'react';
import { OnboardingGuichetCreationRequest } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { useHandleTranslation } from '../../../../../shared/hooks';
import { message } from 'antd';
import { classNames } from '@react-pdf-viewer/core';
import { useQueryClient } from '@tanstack/react-query';
interface CreateWindowPageProps {
  notifySuccess?: () => void;
}
export const CreateWindowPage: FC<CreateWindowPageProps> = ({ notifySuccess }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const postCreateOrganization = useCreateWindow();
  const getIndustriesList = useGetIndustriesList();
  const { t: tW } = useHandleTranslation('window');
  
  const queryClient = useQueryClient();  
  const handleSubmitGuichetCreationStep = (
    data: OnboardingGuichetCreationRequest
  ) => {
    postCreateOrganization.mutate(data);
  };
  const navigateToWindowList = () => {
    const newPath = window.location.pathname.replace('/create-window', '');
    navigate(newPath);
    if (notifySuccess) {
        notifySuccess();
        queryClient.invalidateQueries({ queryKey: ['all-window'] });
    }
  };
  useEffect(() => {
    if (postCreateOrganization.customHandler.isSuccess) {
      messageApi
        .success({
          content: 'Guichet a été créé avec succès',
          duration: 2.5
        })
        .then(navigateToWindowList);
    }
  }, [postCreateOrganization.customHandler.isSuccess]);
  const PageContainerStyle = classNames({
    ['w-fit flex h-[100%] p-0 m-0 overflow-auto']: notifySuccess ? true : false,
    ['flex items-center justify-center h-[100%] overflow-auto py-3']: !notifySuccess
      ? true
      : false
  });
  return (
    <PageContentContainer
      className={PageContainerStyle}
      slate={notifySuccess ? false:true}>
      {contextHolder}
      <OrgCreationStep
        inModal={notifySuccess && true}
        rootClassName={notifySuccess ? "!m-0 !rounded-none":""}
        data={{ industriesList: getIndustriesList.customHandler.data }}
        isLoading={{
          postCreateOrganization: postCreateOrganization.isPending,
          industriesList: getIndustriesList.isLoading
        }}
        isSuccess={{
          postCreateOrganization:
            postCreateOrganization.customHandler.isSuccess,
          industriesList: getIndustriesList.customHandler.isSuccess
        }}
        backButton={{
          label: tW('orgCreation.standard.back_button'),
          onClick: () => navigateToWindowList()
        }}
        submitError={postCreateOrganization.customHandler.errorMessage}
        onSubmit={handleSubmitGuichetCreationStep}
      />
    </PageContentContainer>
  );
};
