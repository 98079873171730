import { FC } from 'react';
import { MaterialIcon } from '../../../../shared/components/icons';
import styles from './landing.module.css';
interface NavBarSelectProps {
    label: string;
    options: string[];
    isOpen: boolean;
    toggleDropdown: () => void;
    onSelect: (option: string) => void;
}

const NavBarSelect:FC<NavBarSelectProps> = ({ label, options, isOpen, toggleDropdown, onSelect }) => {
  return (<div className={styles.navbarSelectContainer}>
      <div className={styles.navbarSelectLabel} onClick={toggleDropdown}>
        {label} <MaterialIcon label="keyboard_arrow_down" className={styles.navbarSelectLabel}/>
      </div>
      
      {isOpen && (
        <div className={styles.navbarSelectMenu}>
          {options.map((option, index) => (
            <span 
              key={index} 
              className={styles.navbarSelectItem}
              onClick={() => onSelect(option)}
            >
              {option}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavBarSelect;
