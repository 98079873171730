import {FC, ReactNode} from "react";
import classNames from "classnames";
import styles from "./list-item.module.css"
interface  ListItemProps{
    label:string,
    icon:ReactNode,
    size?:"sm"|"md",
    onClick:()=>void
    selected?:boolean
    labelColor?:string;
}

export const ListItem:FC<ListItemProps>=(props)=>{
    const {size="sm",selected=false}=props
     const rootStyle=classNames({
         [styles.rootContainer]:true,
         [styles.selectedItemContainer]:selected,
         ["h-8"]:size==="sm",
         ["h-9"]:size==="md",
         [styles["portal-list-list_item-css-var"]]:true
     })
    const iconStyle=classNames({
        ["material-flex-icon text-emerald-600"]:true,
        ["notDisplay"]:!selected,
        ["show"]:selected
    })
    const labelContainerStyle=classNames({
        "flex gap-2 items-center":true,
        "w-4/5":selected,
        "w-full":!selected
    })
    return (
        <div className={rootStyle} onClick={props.onClick} >
            <div className={labelContainerStyle}>
                {props.icon}
                <span style={{color:props.labelColor}} className={styles.label}>{props.label}</span>
            </div>
          <span className={iconStyle}>check</span>
        </div>
    )
}