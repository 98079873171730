import { configureStore } from '@reduxjs/toolkit';
import guichetsReducer from '../../modules/portal/features/guichets/guichetsSlice'
import eservicesReducer from '../../modules/portal/features/eservices/eservicesSlice'
import authReducer from '../../modules/auth/features/auth/authSlice'

export const store = configureStore({
  reducer: {
    guichets: guichetsReducer,
    auth: authReducer,
    eservices: eservicesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
