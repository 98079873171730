import { Button } from 'kls-ui';
import React, { useEffect, useState } from 'react';
import { MaterialIcon } from '../../../../../../../../shared/components/icons';
import { InstallEserviceMarketPlaceRequest } from '../../../../../../types/data/eservice.types';
import { useInstallEserviceMarketPlace } from '../../../../../../api/market-place/caller';
import { message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../shared/stores';

interface EserviceInfoProps {
  organization: string;
  title: string;
  version: string;
  description: string;
  logo: string;
  img: string;
  isFree: boolean;
  idService: string;
  cloneUrl: string;
  returnAction: () => void;
}

const EserviceInfo: React.FC<EserviceInfoProps> = ({
  organization,
  title,
  logo,
  img,
  version,
  description,
  isFree,
  cloneUrl,
  returnAction,
  idService
}) => {
  const [installEserviceParams, setInstallEserviceParams] =
    useState<InstallEserviceMarketPlaceRequest>({
      idService: '',
      cloneUrl: '',
      description: '',
      idGuichet: ''
    });
  const [messageApi, contextHolder] = message.useMessage();
  const { selectedGuichet } = useSelector((state: RootState) => state.guichets);
  const handleInstallClick = () => {
    setInstallEserviceParams((prevData) => {
      return {
        ...prevData,
        idService: idService,
        idGuichet: 'id' in selectedGuichet ? selectedGuichet.id : '',
        cloneUrl: cloneUrl,
        description: description
      };
    });
  };
  const installEserviceMethod = useInstallEserviceMarketPlace(
    installEserviceParams
  );
  useEffect(() => {
    if (installEserviceMethod.isSuccess) {
      
      if (installEserviceMethod.data.data.status == 200) {
        messageApi
          .success({
            content: 'e-Service a été installé avec succès.',
            duration: 2.5
          })
          .then(() => returnAction());
      } else {
        messageApi
          .error({
            content: 'e-Service est déjà installé!',
            duration: 2.5
          })
          .then(() => returnAction());
      }
    }
  }, [installEserviceMethod.status]);
  return (
    <>
      {contextHolder}
      {installEserviceMethod.isFetching ? (
        <Spin className="mt-[25%]" spinning size="large"></Spin>
      ) : (
        <div className="p-5 grid grid-cols-[30%_70%] gap-3">
          <img src={img} className="w-full h-80 rounded-md"></img>
          <div className="flex flex-col gap-5">
            <div className="flex gap-3 items-center">
              <img src={logo} className="w-6 h-6"></img>
              <p className="text-sm text-gray-500 dark:text-slate-50">
                {organization}
              </p>
              <MaterialIcon label="circle" className="text-green-500" />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800  dark:text-slate-50 mb-1">
                {title}{' '}
                <span className="text-xs font-normal text-gray-500 dark:text-slate-400">
                  Version {version}
                </span>
              </h3>
            </div>
            <p className="text-gray-600 dark:text-slate-300 text-sm leading-relaxed ">
              {description}
            </p>
            <div className="flex justify-between items-center">
              <p className="text-violet-600 text-base font-semibold">
                {isFree ? 'Gratuit' : 'Payant'}
              </p>
              <div className="flex gap-5">
                <Button
                  size="md"
                  label="Aperçu"
                  color="primary"
                  styling="outline"
                  args={{ onClick: () => alert('Aperçu clicked') }}
                />
                <Button
                  size="md"
                  label="Installer"
                  color="primary"
                  styling="solid"
                  args={{ onClick: () => handleInstallClick() }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EserviceInfo;
