import { useMutation } from '@tanstack/react-query';
import {
  checkForDataConsistency,
  checkFile,
} from './api';

export const useCheckFile = () => {
  return useMutation({
    mutationFn: async ({ file, guichetId }: { file: File; guichetId: string }) => await checkFile(file, guichetId),
  });
};

export const useCheckForDataConsistency = () => {
  return useMutation({
    mutationFn: async ({ fileName, guichetId }: { fileName: string; guichetId: string }) => await checkForDataConsistency(fileName, guichetId),
  });
};
