
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../shared/components';
import styles from './catalogue.module.css';
import { USERS_FEEDBACK } from '../../utils/constants/helpers.constants';
import { FeedbackCarousselComponent } from './FeedbackCarousselComponent';

export const AvisComponent = ()=>{
    const navigate=useNavigate();

    return(<div className="flex flex-col items-center gap-5 p-5 dark:bg-slate-800">   
        <span className={styles.rscTitle}>Avis utilisateurs</span>
        <FeedbackCarousselComponent feedbacks={USERS_FEEDBACK}></FeedbackCarousselComponent> 
        <Button size={'lg'} color={"secondary"} styling={"solid"}  label="S'inscrire maintenant" onClick={()=>navigate("/signup")} />
        </div>);
} 