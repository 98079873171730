import { FC, useCallback} from 'react';
import styles from './table-items.module.css';
import { Menu } from '../../../../../shared/components/index.tsx';
import { MaterialIcon } from '../../../../../shared/components/icons/index.ts';
import { AvatarComponent } from '../../../../../shared/components/avatar/AvatarComponent.tsx';
import {  USERS_ACTIONS_ALL, USERS_ACTIONS_INVITAP } from '../../../utils/constants/components.constants.tsx';
import {  WindowInvitationListResponse } from '../../../types/index.ts';
import { capitalizeFirstLetter } from '../../../../../shared/utils/helpers/components.helper.ts';
interface RowTableAdminProps {
  item: WindowInvitationListResponse;
  onSelectDropDown?: (
    selection: { 
        id: string; 
        selectedValue: string;
        firstName: string;
        emailPersonnel: string; 
        nameUser: string; 
        logoUser: string; 
        role: string;
        loginUser: string;
        roleDetails:string;
        email:string;
        

        }) => void;
  type: string;
}
export const RowTableAdministratorInvitation: FC<RowTableAdminProps> = (props) => {

  const getDropDownData = useCallback((type: string) => {
    if (!type) return;
    if (type === 'WindowInvitation') {
      return USERS_ACTIONS_INVITAP;
    }
    return USERS_ACTIONS_ALL;
  }, []);


  const handleSelect = (value: string) => {
    if (props.onSelectDropDown) {
      props.onSelectDropDown({
        id: props.item.idRequest,
        selectedValue: value,
        emailPersonnel: props.item.emailPersonnel,
        nameUser: props.item.nameUser,
        logoUser: props.item.logoUser,
        role: props.item.roleUserApproved,
        loginUser: props.item.loginUser,
        roleDetails: props.item.roleUserApproved,
        email: props.item.emailPersonnel,
        firstName: props.item.nameUser,
      });
    }
  };
  
  
  const menuIcon = (
    <MaterialIcon label="more_vert" className={styles.menuIcon} />
  );
console.log('props.item', props.item.idRequest)
  const renderMenu = () => {

    if (props.type) {
      return (
        <Menu
        className={styles.moreIcon}
        icon={menuIcon}
        onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])} 
        list={getDropDownData(props.type)}
      >
      </Menu>
      
      );
    }
    return null;
  };
  return (
    <tr key={props.item.idRequest}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          {props.item.logoUser && props.item.logoUser !== "" ? (
            <img
              src={props.item.logoUser}
              alt={props.item.firstName}
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <AvatarComponent fullname={props.item.firstName || 'I N'} />
          )}
        </div>
      </td>
      <td className={`${styles.aCaseContainer} `}>
        <div className={`${styles.aCase} !grid`}>
          {props.item.firstName || 'N/A'} 
          <span className={styles.emailPersonnel}>
          {props.item.emailPersonnel}
          {props.item.email || 'N/A'}
          </span></div>
      </td>

        <td className={styles.aCaseContainer}>
          <div className={styles.aCase}>
            {props.item.loginUser || 'N/A'}</div>
        </td>
      

        <td className={`${styles.aCaseContainer} w-16`}>
  <div className={`${styles.cardContainer} flex justify-center items-center`}>
    <span className={`${styles.statutContainerAdmin} whitespace-nowrap`}>
    {props.item.roleDetails?.role === 'SUPER_ADMINISTRATEUR' ? 'Super administrateur' : props.item.roleDetails?.role || 'N/A'}
    </span>
  </div>
</td>
      
        
          <td className={`${styles.lCaseContainer} w-12 h-12 dark:border-b-slate-700`}>
            <div className={styles.lCase}>
              <span> {capitalizeFirstLetter(props.item.roleStatusUser)}
                </span>
                <div className={styles.headerIcons}>
                  {renderMenu()}
                  </div>
                   </div>
          </td>
        
    </tr>
  );
};
