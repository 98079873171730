import {Controller, FieldPath, FieldValues} from "react-hook-form";
import {ControlledUiProps, SelectProps} from "../../types";
import {MaterialIconSelect as NativeMaterialIconSelect} from "../drop-down/select/MaterialIconSelect";

type MaterialIconSelectProps = Omit<SelectProps,'options'>

export interface ControlledMaterialIconSelectProps <TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends MaterialIconSelectProps, ControlledUiProps<TFieldValues,TName>{}

export const MaterialIconSelect = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledMaterialIconSelectProps<TFieldValues, TName>) => {
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange } }) => (
                <NativeMaterialIconSelect  {...props}
                               onChange={onChange}
                               errorMessage={props.error}
                               withError
                />
            )}
        />

    );
};

