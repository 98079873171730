import React, { useCallback } from 'react';
import styles from './user-card.module.css';
import { Menu } from '../../../../../shared/components';
import { MaterialIcon } from '../../../../../shared/components/icons';
import { EserviceMembersListResponse } from '../../../types/index';
import { AvatarComponent } from '../../../../../shared/components/avatar/AvatarComponent';
import { USERS_ACTIONS_ALL, USERS_ACTIONS_AP, USERS_ACTIONS_INVITAP, USERS_ACTIONS_NONAP } from '../../../utils/constants/components.constants';
interface UserCardProps {
  firstName: string;
  lastName?: string;
  email: string;
  profilePicture: string;
  color: string;
  role?: RoleTagProps;
  listBasedRole?:boolean;
  type?:string;
  onSelectDropDownForMembers?: (selection: {
    id: string,
    selectedValue: string,
    emailPersonnel:string,
    nameUser:string,
    logoUser:string,
    role:string,
    ownerLogin:string,
    note:string,
  }) => void;
  member?: EserviceMembersListResponse;
}

export interface RoleTagProps {
  role?: string;
  roleType?: string;
  status?: string;
}

export const RoleTag: React.FC<RoleTagProps> = ({ role,  status }) => {
  return (
    <div className={styles.roleContainer}>
      <span className={`${styles[status?.toLowerCase() || '']}`}></span>
      <span className={`${styles.roleText} ${styles[role?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\s+/g, '_') || '']}`}>{role}</span>
      <span className={styles.roleType}></span>
    </div>
  );
};

const EllipseSVG = (stroke: string) => {
  const svg = `<svg width="77" height="101" viewBox="0 0 77 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.48364 8.22322C8.71167 4.1064 16.9226 1.94045 25.3204 1.93538C33.7182 1.93031 42.018 4.08629 49.415 8.19429C56.8119 12.3023 63.0552 18.2229 67.5394 25.3822C72.0236 32.5415 74.5967 40.6965 75.0091 49.0565C75.4215 57.4166 73.6593 65.698 69.8933 73.098C66.1274 80.498 60.4854 86.7654 53.5144 91.2926C46.5434 95.8199 38.48 98.4533 30.1058 98.9376C21.7316 99.4219 13.3309 97.7406 5.71802 94.0568" stroke="${stroke}" stroke-width="2.27629"/>
  </svg>`;

  const base64SVG = `data:image/svg+xml;base64,${btoa(svg)}`;
  return base64SVG;
};

const ProfilePicture: React.FC<{ profilePicture: string; color: string; firstName?: string; lastName?: string , fullName?:string, email?:string; }> = ({
  profilePicture,
  color,
  firstName,
  lastName,
  fullName,
  email
}) => {
  const svgBackground = EllipseSVG(color);
  const full = fullName ? fullName : (firstName || "") + (lastName ?? "");

  const finalName = full || (email ? email.charAt(0) : "");
  return (
    <div
      className={styles.profilepictureWrapper}
      style={{
        backgroundImage: `url(${svgBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'right',
      }}
    >
      {profilePicture === "" ? (
        <AvatarComponent fullname={finalName} height='8rem' width='8rem' fontSize='30px'></AvatarComponent>
      ) : (
        <img
          src={profilePicture}
          className={styles.profileImage}
          alt="Profile"
          style={{
            borderColor: color,
          }}
        />
      )}
    </div>
  );
};

export const UserCard: React.FC<UserCardProps> = ({ profilePicture, color, firstName, role, email,type,onSelectDropDownForMembers,member }) => {
 
  const getDropDownData = useCallback((type: string|undefined) => {
    if (!type) return;

    if (type === 'memberApprouve') {
      return USERS_ACTIONS_AP;
    }
    if (type === 'memberNonApprouve')
      return USERS_ACTIONS_NONAP;

    if (type === 'contributeurNonApprouve' )
      return USERS_ACTIONS_NONAP;

    if (type === 'invitationNonApprouve' || type === 'admin')
      return USERS_ACTIONS_INVITAP; 

    if (type === 'contributeurApprouve')
      return;

    return USERS_ACTIONS_ALL;
  }, []);

const handleSelect = (value: string) => {
  if (onSelectDropDownForMembers ) {
    if(member){
    onSelectDropDownForMembers({
      id: member.idRequest,
      selectedValue: value,
      emailPersonnel:member.emailPersonnel,
      nameUser:member.nameUser,
      logoUser:member.logoUser,
      role:member.roleUserDemanded,
      ownerLogin:member.loginUser,
      note:member.note
    });
  }
   
    return;
  }
};

  const menuIcon = <MaterialIcon label="more_horiz" center className="text-md text-slate-600 text-xl" />
  const roleLabel = role?.role === 'SUPER_ADMINISTRATEUR' ? 'Super administrateur' : role?.role;

  return (
    <div className={styles.usercardWrapper}>
      <div className={styles.viewMore}>
        <Menu list={getDropDownData(type)} icon={menuIcon} onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])} />
      </div>
           <ProfilePicture color={color} 
           profilePicture={profilePicture} 
           firstName={firstName|| 'I N'} 
           fullName={member?.nameUser}
            email={email}/>
            
      <div className={styles.userInfo}>
        <p className={styles.userName}>{firstName|| 'N/A'} </p>
        <p className={styles.userEmail}>{email}</p>
      </div>
      <RoleTag 
    role={roleLabel} 
    roleType={role?.roleType} 
    status={role?.status} 
  />


    </div>
  );
};
