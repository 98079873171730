import { useState, useEffect } from 'react';
import {
  LoginActivation,
  LoginResendCode,
  LoginResponse,
  LoginUser,
  LoginActivationResponse
} from '../../types';
import LoginAnotherMethodOTP from './login-steps/LoginAnotherMethodOTP';
import LoginSendOTP from './login-steps/LoginSendOTP';
import LoginVerificationOTP from './login-steps/LoginVerificationOTP';
import classNames from 'classnames';
import { OuterContainer } from '../../../../shared/components';
import {
  usePostLoginData,
  usePostLoginActivationAccount,
  usePostLoginResendCode
} from '../../api';

import { useNavigate } from 'react-router-dom';
import {
  saveUserProfile,
  setCookiesUser
} from '../../../../shared/utils/helpers';
import { UserProfile } from '../../../../shared/types';
import { USER_AVATAR_ICON_PATH } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import {
  setAuthenticated, setComingFromSignupStatus,
  setOnboardingStatus
} from '../../features/auth/authSlice';

enum LoginStep {
  STEP_ONE = 'STEP_ONE',
  STEP_TWO = 'STEP_TWO',
  STEP_THREE = 'STEP_THREE'
}

const LoginPage = () => {
  const dispatch=useDispatch();
  const [currentStep, setCurrentStep] = useState<LoginStep>(LoginStep.STEP_ONE);
  const [transactionToken, setTransactionToken] = useState<
    string | undefined
  >();
  const [deadline, setDeadline] = useState<string | undefined>();
  const [authRequired, setAuthRequired] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginUser | undefined>();
  const postLoginData = usePostLoginData();
  const postLoginActivationAccount = usePostLoginActivationAccount();
  const postLoginResendCode = usePostLoginResendCode();
  const navigate = useNavigate();
  /***Redux Auth Store */
  const handleLogin = (value: boolean) => {
    dispatch(setAuthenticated(value)); // Dispatch an action to set authenticated
  };
console.log("login data",postLoginData);
  const handleOnboarding = (value: boolean) => {
    dispatch(setOnboardingStatus(value)); // Dispatch an action to set onboarding status
  };
  const submitLogin = (data: LoginUser) => {
    postLoginData.mutate(data);
    setLoginData(data);
  };
  const handleComingFromSignUpState=()=>{
    dispatch(setComingFromSignupStatus(false))
  }

  const submitLoginActivation = (otpCode: string) => {
    if (loginData && transactionToken) {
      const data: LoginActivation = {
        ...loginData,
        transactionOTP: transactionToken,
        codeVerification: otpCode
      };
      postLoginActivationAccount.mutate(data);
    }
  };

  const resendVerificationCode = () => {
    const data: LoginResendCode = {
      deliveryMethod: 'EMAIL',
      transactionOTP: transactionToken!
    };
    postLoginResendCode.mutate(data);
  };
  const storeUserProfile = (
    response: LoginResponse | LoginActivationResponse
  ) => {
    const userProfile: UserProfile = {
      email: response?.email.slice(1, -1),
      fullName: response?.userName,
      entity: response?.userEntity,
      id: response?.userQN,
      entityLabel: response.userEntity.split('/').filter(Boolean).pop(),
      avatarUrl: response?.userAvatarGedId ? `${USER_AVATAR_ICON_PATH.prefix}${response.userAvatarGedId}${USER_AVATAR_ICON_PATH.suffix}` : '',
      userId: response.userId,
      onboardingAccomplished:
        response.onboardingOptions.Accomplished?.toString()
    };
    setCookiesUser(response.karazAccessToken);
    saveUserProfile(userProfile);
  };
  useEffect(() => {
    if (postLoginData.customHandler.isSuccess) {
      const loginData = postLoginData.customHandler.data;
      setTransactionToken(loginData?.transaction_token);
      setDeadline(loginData?.otpParams?.expired);
      setAuthRequired(loginData?.auth_required || false);

      if (loginData?.auth_required) {
        setCurrentStep(LoginStep.STEP_TWO);
      } else {
        storeUserProfile(loginData as LoginResponse);
        const accomplished =
          postLoginData.customHandler.data?.onboardingOptions.Accomplished;
        if (accomplished !== undefined) {
          handleOnboarding(accomplished);
        }
        handleLogin(true);
        navigate('/');
        handleComingFromSignUpState()
      }
    }
  }, [postLoginData.customHandler.isSuccess]);
  useEffect(() => {
    if (postLoginResendCode.customHandler.isSuccess) {
      setDeadline(postLoginResendCode?.customHandler?.data?.expired);
    }
  }, [postLoginResendCode.customHandler.isSuccess]);

  useEffect(() => {
    if (postLoginActivationAccount.customHandler.isSuccess) {
      const loginData = postLoginActivationAccount.customHandler.data;
      storeUserProfile(loginData as LoginActivationResponse);
      navigate('/');
      handleComingFromSignUpState()
    }
  }, [postLoginActivationAccount.customHandler.isSuccess]);

  const loginStyles = (step: LoginStep) =>
    classNames({
      ['show']: currentStep === step,
      ['notDisplayV2']: currentStep !== step
    });
  return (
    <OuterContainer>
      <LoginSendOTP
        className={loginStyles(LoginStep.STEP_ONE)}
        onSubmit={submitLogin}
        isLoading={postLoginData.isPending}
        isError={postLoginData.customHandler.isError}
        submitError={postLoginData.customHandler.errorMessage}
      />
      {authRequired && (
        <LoginVerificationOTP
          className={loginStyles(LoginStep.STEP_TWO)}
          onSubmit={submitLoginActivation}
          isLoading={{
            verificationCode: postLoginActivationAccount.isPending,
            resendVerificationToken: postLoginResendCode.isPending
          }}
          isError={{
            verificationCode: postLoginActivationAccount.customHandler.isError,
            resendVerificationToken: postLoginResendCode.customHandler.isError
          }}
          isSuccess={{
            verificationCode:
              postLoginActivationAccount.customHandler.isSuccess,
            resendVerificationToken: postLoginResendCode.customHandler.isSuccess
          }}
          deadline={deadline}
          resendVerificationCode={resendVerificationCode}
          onImpossibleAccess={() => setCurrentStep(LoginStep.STEP_THREE)}
        />
      )}
      {authRequired && (
        <LoginAnotherMethodOTP
          className={loginStyles(LoginStep.STEP_THREE)}
          onSubmit={submitLoginActivation}
          isLoading={{
            verificationCode: postLoginActivationAccount.isPending,
            resendVerificationToken: postLoginResendCode.isPending
          }}
          isError={{
            verificationCode: postLoginActivationAccount.customHandler.isError,
            resendVerificationToken: postLoginResendCode.customHandler.isError
          }}
          isSuccess={{
            verificationCode:
              postLoginActivationAccount.customHandler.isSuccess,
            resendVerificationToken: postLoginResendCode.customHandler.isSuccess
          }}
          deadline={deadline}
          resendVerificationCode={resendVerificationCode}
        />
      )}
    </OuterContainer>
  );
};

export default LoginPage;
