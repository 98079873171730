import {IdentityCreateOrganizationRequest} from "../../types";
import {IdentityCreateOrganizationApiRequest} from "../../types";

export const mapCreateOrganizationRequest=(data:IdentityCreateOrganizationRequest):IdentityCreateOrganizationApiRequest=>{

    return {
        name: data.name,
        email:data.email,
        phoneNumber:data.phoneNumber.suffix,
        indicatif:data.phoneNumber.prefix,
        identifiant:data.identifier,
        typeAdress:data.coordinates.longitude && data.coordinates.latitude ? "PHYSICAL":"Virtuelle",
        logoAsBase64:data.logo.base64,
        logoFormat:data.logo.format,
        guichetId:data.windowId,
        longitude:data.coordinates.longitude,
        latitude:data.coordinates.latitude,
        type:"ORGANISM",
        site:data.webSite,
        description:data.description,
        acronyme:data.acronym
    };
}