import {FC, ReactNode} from 'react';
import styles from './section.module.css';
import { Pagination } from 'kls-ui';
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import {Menu} from "../../../../../../shared/components";
import {ListItemType} from "../../../../../../shared/types";
import {MaterialIcon} from "../../../../../../shared/components/icons";

interface SectionProps {
  title?: string;
  materialIconLabel?:string;
  headerColor?:string
  menuList?:ListItemType[];
  onMenuChange?:(value:string[])=>void
  nested?:boolean;
  className?:string;
  withPagination?: boolean;
  itemsPerPage?: number;
  totalItems?: number;
  onPageChange?: (page: number) => void;
  currentPage?: number;
  children?: ReactNode;
}

export  const Section: FC<SectionProps> = (props) => {
  const {className="",nested=false,onMenuChange=()=>{}}=props
  const { t } = useTranslation();
  const hasChildren = !!props.children;
  const itemsPerPage = props.itemsPerPage || 10;
  const totalItems = props.totalItems || 0;
  const maxPage = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (props.onPageChange) {
      props.onPageChange(page);
    }
  };
  const rootStyles=classNames({
    [styles["portal-section-css-var"]]:true,
    [styles.sectionWrapper]:true,
    [styles.nestedWrapper]:nested
  })
  const sectionContentStyle=classNames({
    [styles.sectionContent]:true,
    [className]:true,
  })
  const renderLabelStyle=()=>{
    if (props.headerColor) return {style:{color:props.headerColor}}
    return {};
  }
  const renderIconStyle=()=>{
    if (props.headerColor) return {style:{backgroundColor:props.headerColor}}
    return {};
  }
  const renderMenuIcon=()=>{
    if(nested)  return <MaterialIcon label="east" className="text-xl"/>
      return <MaterialIcon label="more_vert" className="text-xl" />
  }
  return (
      <div className={rootStyles}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitleContainer}>
            {props.materialIconLabel && (
                <span className={styles.icon} {...renderIconStyle()}>
                   <MaterialIcon className="text-base" center label={props.materialIconLabel}/>
                </span>
            )}
            <span {...renderLabelStyle()} className={styles.sectionTitle}>{props.title}</span>
          </div>
          <div className={styles.sectionAction}>
              <Menu icon={renderMenuIcon()} list={props.menuList} action onChange={onMenuChange}/>
          </div>
        </div>
        <div className={sectionContentStyle}>
          {hasChildren ? (
              props.children
          ) : (
              <p className={styles.emptyResult}>{t('empty_data')}</p>
          )}
        </div>
        {hasChildren && props.withPagination && maxPage > 1 && (
            <div className={styles.sectionPagination}>
              <Pagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  maxPage={maxPage}
                  handlePageChange={handlePageChange}
                  currentIndex={props.currentPage || 1}
              />
            </div>
        )}
      </div>
  );
};

