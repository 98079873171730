import {Select} from "./Select";
import {MaterialIconItem, SelectProps} from "../../../types";
import materialIconsJson from "../../../assets/resources/material_icons.json"
import {MaterialIcon} from "../../icons";
import {forwardRef, ForwardRefRenderFunction} from "react";
const materialIcons: MaterialIconItem[] = materialIconsJson;

type ForwardedMaterialIconSelectProps = Omit<SelectProps,'options'>
const  ForwardedMaterialIconSelect:ForwardRefRenderFunction<HTMLInputElement,ForwardedMaterialIconSelectProps > = (props,ref)=>{
  const materialIconsOptions=materialIcons.map((item:MaterialIconItem)=>({label:item.label,value:item.value,icon:<MaterialIcon center className="text-3xl text-blue-400" label={item.value}/>}))
  return  <Select ref={ref} options={materialIconsOptions} showSelectedIcon vertical  {...props} />
}
export const MaterialIconSelect = forwardRef(ForwardedMaterialIconSelect);
