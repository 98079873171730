import { PermissionsGuichet } from "./permission.types";
import { PermissionCheck } from "./permissionCheck";
import { RoleGuichet } from "./role.types";


export type RolePermissionsGuichet = {
  [role in RoleGuichet]: {
    actions: Partial<Record<PermissionsGuichet['actions'], PermissionCheck>>;
    menus: {
      topMenu: Partial<Record<PermissionsGuichet['menus']['topMenu'], PermissionCheck>>;
      sideMenu: Partial<Record<PermissionsGuichet['menus']['sideMenu'], PermissionCheck>>;
    };
  };
};

export const GuichetRoles: RolePermissionsGuichet = {
    "Propriétaire": {
    actions: {
      creer: true,
      modifier: true,
      supprimer: false,
      voirDetails: true,
    },
    menus: {
      topMenu: {
        accueil: true,
        organisations: true,
        guichets: true,
        catalogue: true,
      },
      sideMenu: {
        assistance:true,
        dashboard: true,
        identity: true,
        eservices: true,
        offre: true,
        parametres: true,
        monitoring: true,
        securite: true,
        membres: true,
        administrateurs: true,
        developer_zone: true,
        market_place: true,
        communaute: true,
        client: true,
        suppression_organisation: true,
        suppression_eshop: true,
      },
    },
  },
  'Super Administrateur': {
    actions: {
      creer: true,
      modifier: true,
      supprimer: true,
      voirDetails: true,
    },
    menus: {
      topMenu: {
        accueil: true,
        organisations: true,
        guichets: true,
        catalogue: true,
      },
      sideMenu: {
        assistance:true,
        dashboard: true,
        identity: true,
        eservices: true,
        offre: false,
        parametres: true,
        monitoring: true,
        securite: true,
        membres: true,
        administrateurs: true,
        developer_zone: true,
        market_place: true,
        communaute: true,
        client: true,
        suppression_eshop: false,
        suppression_organisation: false
      },
    },
  },
  'Usager': {
    actions: {
      creer: false,
      modifier: false,
      supprimer: false,
      voirDetails: true,
    },
    menus: {
      topMenu: {
        accueil: true,
        organisations: true,
        guichets: true,
        catalogue: true,
      },
      sideMenu: {
        assistance:true,
        dashboard: true,
        identity: false,
        eservices: true,
        offre: false,
        parametres: false,
        monitoring: false,
        securite: false,
        membres: false,
        administrateurs: false,
        developer_zone: false,
        market_place: false,
        communaute: false,
        client: false,
        suppression_eshop: false,
        suppression_organisation: false
      },
    },
  },
};
