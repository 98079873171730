import {useHandleDomLocation} from "../hooks";
import {Viewer, Worker} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const PDFViewer =()=>{
    const {fileUrl}=useHandleDomLocation();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const renderViewer=()=>{
        if(fileUrl) return ( <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
                fileUrl={decodeURIComponent(fileUrl)}
                plugins={[
                    defaultLayoutPluginInstance,
                ]}
            />
        </Worker>)
        return ;
    }
    return(
        <div className="fixed top-0 h-screen w-full">
            {renderViewer()}
        </div>
    )
}
