import { FC, useCallback } from 'react';
import { IconSize } from '../../../../../shared/utils/enums';
import styles from './table-items.module.css';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg.tsx';
import logo from '../../../assets/icons/logo-app.svg';
import { MaterialIcon } from '../../../../../shared/components/icons/MaterialIcon.tsx';
import { Menu } from '../../../../../shared/components/index.tsx';
import { USERS_ACTIONS_ALL, USERS_ACTIONS_NONAP } from '../../../utils/constants/components.constants.tsx';
import { EserviceMembersListResponse } from '../../../types/index.ts';

const staticData = {
  "body": {
    "data": [
      {
        "changeTime": "2024/12/04 15:04:28",
        "creationTime": "2024/12/04 14:45:32",
        "emailPersonnel": "cahcine@ribatis.com",
        "eserviceLogo": "",
        "eserviceName": "test",
        "guichetName": "Agence urbaine de Guelmim Oued Noun",
        "idRequest": "9a08dafe6e08602aa2274ffc5aef802b",
        "loginUser": "chaymaaahcine@karaz.org",
        "logoUser": "",
        "nameUser": "chaymaa ahcine",
        "note": "",
        "organizationVerification": "Non vérifiée",
        "roleStatusUser": "ACTIF",
        "roleUserApproved": "ADMINISTRATEUR",
        "roleUserDemanded": "ADMINISTRATEUR",
        "statistiqueGroup": 0,
        "statistiqueProfil": 0,
        "statistiqueRessource": 0,
        "statusRequest": "ACCEPT",
        "typeRequest": "INVITATION"
      },
      {
        "changeTime": "2024/12/04 14:39:39",
        "creationTime": "2024/12/04 14:39:24",
        "emailPersonnel": "cahcine@ribatis.com",
        "eserviceLogo": "",
        "eserviceName": "test",
        "guichetName": "Agence urbaine de Guelmim Oued Noun",
        "idRequest": "d607702060cbc57c967fc46407d75117",
        "loginUser": "chaymaaahcine@karaz.org",
        "logoUser": "",
        "nameUser": "chaymaa ahcine",
        "note": "",
        "organizationVerification": "Non vérifiée",
        "roleStatusUser": "ACTIF",
        "roleUserApproved": "ADMINISTRATEUR",
        "roleUserDemanded": "ADMINISTRATEUR",
        "statistiqueGroup": 0,
        "statistiqueProfil": 0,
        "statistiqueRessource": 0,
        "statusRequest": "ACCEPT",
        "typeRequest": "INVITATION"
      },
      {
        "changeTime": "2024/12/04 14:39:11",
        "creationTime": "2024/12/04 14:38:56",
        "emailPersonnel": "cahcine@ribatis.com",
        "eserviceLogo": "",
        "eserviceName": "test",
        "guichetName": "Agence urbaine de Guelmim Oued Noun",
        "idRequest": "51f2f9158af4c4d2a9d50a3ffc79fd7f",
        "loginUser": "chaymaaahcine@karaz.org",
        "logoUser": "",
        "nameUser": "chaymaa ahcine",
        "note": "",
        "organizationVerification": "Non vérifiée",
        "roleStatusUser": "ACTIF",
        "roleUserApproved": "ADMINISTRATEUR",
        "roleUserDemanded": "ADMINISTRATEUR",
        "statistiqueGroup": 0,
        "statistiqueProfil": 0,
        "statistiqueRessource": 0,
        "statusRequest": "ACCEPT",
        "typeRequest": "INVITATION"
      }
    ]
  }
};


interface RowTableProps {
  type: string;
  item: EserviceMembersListResponse;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  icon?: string;
}

export const RowTableWindowInvitation: FC<RowTableProps> = (props) => {
  const item = staticData.body.data[0];

  const getDropDownData = useCallback((type: string) => {
    if (!type) return;
    if (type === 'receivedWindowInvitation') {
      return USERS_ACTIONS_NONAP;
    }
    return USERS_ACTIONS_ALL;
  }, []);

  const handleSelect = (value: string) => {
    console.log('value', value);
  };

  const menuIcon = (
    <MaterialIcon label="more_vert" className={styles.menuIcon} />
  );

  const renderMenu = () => {
    if (props.type) {
      return (
        <Menu
          className={styles.moreIcon}
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={getDropDownData(props.type)}
        />
      );
    }
    return null;
  };

  if (!item.eserviceName || !item.guichetName) {
    return null; 
  }

  return (
    <tr key={item.idRequest}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <img
            src={item.eserviceLogo || logo}
            alt={`${item.eserviceLogo} logo`}
            className="w-8 h-8 rounded-full"
          />
        </div>
      </td>
      <td className={styles.nameCaseContainer}>
        <div className={`${styles.nameCase} !grid`}>
          <span>{item.eserviceName}</span>
          <span className="flex items-center gap-1">
            {item.guichetName}
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          </span>
        </div>
      </td>

      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>
          {item.roleUserDemanded === 'ADMINISTRATEUR' ? (
            <div>
              <span className={styles.statutContainerAdmin}>
                {item.roleUserDemanded}
              </span>
            </div>
          ) : (
            <div>
              <span className={styles.statutContainerCD}>
                {item.roleUserDemanded}
              </span>
            </div>
          )}
        </div>
      </td>

      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
          <div className={styles.headerIcons}>{renderMenu()}</div>
        </div>
      </td>
    </tr>
  );
};
