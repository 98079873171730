import {CheckboxProps as AntdCheckboxProps, ConfigProvider} from "antd";
import {Checkbox as AntdCheckbox} from "antd"
import {FC} from "react";

interface CheckboxProps extends AntdCheckboxProps {
    label:string
}



export const Checkbox:FC<CheckboxProps>=(props)=>{
    return(
        <ConfigProvider theme={KSW_ANTD_CHECKBOX_THEME} >
            <AntdCheckbox {...props} >{props.label}</AntdCheckbox>
        </ConfigProvider>
    )
}
 const KSW_ANTD_CHECKBOX_THEME={
    token: {
        colorPrimary:'#3B82F6',
        colorPrimaryBorder:'#3B82F6',
        colorPrimaryHover:'#2563EB',
        colorBorder:'#CBD5E1',
    },
}