import {FC, ReactNode, useEffect, useState} from "react";
import classNames from "classnames";
import styles from "./cards.module.css";
import {CheckboxLabel} from "kls-ui";
import {GuichetStatus} from "../../../../../shared/utils/enums";
import {CheckBadgeIcon, ClockIcon} from "@heroicons/react/16/solid";

interface OrganisationCardProps{
    cardKey:string;
    title: string;
    checked?:boolean
    checkBox?:boolean;
    icon:ReactNode;
    state:string;
    onCheck?:(key:string)=>void;
}


export const WidowElementCard:FC<OrganisationCardProps>=(props)=>{
    const {checked,checkBox}=props;
    const [isChecked,setIsChecked]=useState<boolean>(false)
   const handleCheckValue=(value:boolean)=>{
       if (checked===undefined) setIsChecked(value);
       if (props.onCheck) props.onCheck(props.cardKey);
   }
    useEffect(() => {
       if (checked !==undefined) setIsChecked(checked)
    }, [checked]);

    const rootStyle=classNames({
        [styles.orgCardContainer]:true,
        " transition-all duration-300 hover:bg-slate-50 hover:dark:bg-slate-900":true,
        [styles.orgCardSelectedContainer]:isChecked,
        ["cursor-pointer"]:!checkBox,
        [styles["onboarding-card-css-var"]]:true,
    })
    const renderStateIcon=()=>{
        switch (props.state){
            case GuichetStatus.IN_PROGRESS: return <ClockIcon className="size-3 text-orange-600 "/>;
            case GuichetStatus.VERIFIED:  return <CheckBadgeIcon className="size-3 text-emerald-500"/>
        }
    }
    const handleCardClick=()=>{
        if (!checkBox) handleCheckValue(!isChecked);
    }
    return (
        <div className={rootStyle} onClick={handleCardClick}>
            <div className="flex items-center gap-3">
                <div className="flex justify-center  h-10 w-10 aspect-square rounded-full [&>img]:w-full">{props.icon}</div>
                <div className="flex items-center gap-1">
                    <span className="font-medium text-sm text-slate-800 dark:text-white">{props.title}</span>
                    {renderStateIcon()}
                </div>

            </div>
            {checkBox &&  <CheckboxLabel Check={isChecked} handleChangeValue={handleCheckValue}/>}
        </div>
)
}
