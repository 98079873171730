import { NotificationType } from '../../utils/enums';
import {FC, ReactNode, useState} from 'react';
import classNames from 'classnames';
import styles from './cards.module.css';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import {MaterialIcon} from "../icons";

interface NotificationCardProps {
  type: NotificationType;
  children: ReactNode;
  className?: string;
  closeable?:boolean;
  onClose?:()=>void
}

export const NotificationCard: FC<NotificationCardProps> = (props) => {
  const { className = '',closeable=false } = props;

  const [isOpen,setOpenStatus]=useState<boolean>(true);
  const rootStyles = classNames({
    [className]: true,
    "opacity-100":isOpen,
    ["opacity-0 transition-all overflow-y-auto duration-400 h-0"]:!isOpen,
    [styles["portal-card-css-var"]]:true,
    [styles.notificationCardContainer]: true,
    [styles.notificationCardInfo]: props.type == NotificationType.INFO,
    [styles.notificationCardSuccess]: props.type == NotificationType.SUCCESS,
    [styles.notificationCardWarning]: props.type == NotificationType.WARNING,
    [styles.notificationCardError]: props.type == NotificationType.ERROR
  });

  const iconStyles=classNames({
    ["flex justify-between"]:true,
    [styles.notificationIconInfo]: props.type == NotificationType.INFO,
    [styles.notificationIconSuccess]: props.type == NotificationType.SUCCESS,
    [styles.notificationIconWarning]: props.type == NotificationType.WARNING,
    [styles.notificationIconError]: props.type == NotificationType.ERROR
  })
  const renderIcon = (type: NotificationType) => {
    switch (type) {
      case NotificationType.SUCCESS:
        return <CheckCircleIcon className={'size-5'} />;
      case NotificationType.ERROR:
        return <XCircleIcon className={'size-5'} />;
      case NotificationType.WARNING:
        return <ExclamationCircleIcon className={'size-5'} />;
      case NotificationType.INFO:
        return <ExclamationCircleIcon className={'size-5'} />;
    }
    return <></>;
  };
   const handleCloseCard=()=>{
     setOpenStatus(false);
     if (props.onClose) props.onClose();
   }
   const renderCloseIcon=()=>{
     if(closeable)
       return ( <MaterialIcon
         label="close"
         center
         onClick={handleCloseCard}
         className=" cursor-pointer hover:text-slate-700 dark:hover:text-slate-200 text-slate-500 text-lg dark:text-white"/>)
    return <></>
   }

  return (
      <div className={rootStyles}>
        <div className={iconStyles}>{renderIcon(props.type)}</div>
          {props.children}
        <div>
          {renderCloseIcon()}
        </div>
      </div>
  );
};
