import { useQuery } from '@tanstack/react-query';
import { ListEserviceRequest } from '../../types';
import {
  getEserviceMarketPlaceDetails,
  getEservicesMarketPlaceList,
  installEserviceMarketPlace
} from './api';
import { useHandleGenericResponse } from '../../../../shared/hooks';
import { EserviceMarketPlaceData, EserviceMarketPlaceDetailsRequest, InstallEserviceMarketPlaceRequest } from '../../types/data/eservice.types';
import { GenericResponseWrapper } from '../../../../shared/types';
import { PageResponse } from '../../../../shared/types/api.type';
import { transformGenericResponseWrapperResponse } from '../../../../shared/utils/helpers';

export const useGetEservicesMarketPlaceList = (data: ListEserviceRequest) => {
  const queryKey = ['list-services', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getEservicesMarketPlaceList(data),
    retry: 0,
    staleTime: 0,
    gcTime: 0
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useGetEserviceMarketPlaceDetails = (
  data: EserviceMarketPlaceDetailsRequest
) => {
  const queryKey = ['eservice-details', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getEserviceMarketPlaceDetails(data),
    retry: 0,
    enabled: data.serviceId!="",
    select:(data:GenericResponseWrapper<PageResponse<EserviceMarketPlaceData>>)=>{
      return transformGenericResponseWrapperResponse(data);
    },
    staleTime: 0
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useInstallEserviceMarketPlace = (
  data: InstallEserviceMarketPlaceRequest
) => {
  const queryKey = ['install-eservice', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => installEserviceMarketPlace(data),
    enabled: data.idService != "" && data.idGuichet != ""
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};
