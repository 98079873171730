import React, { useEffect, useState } from 'react';
import styles from './styles.module.css'
import classNames from "classnames";

type Options={ from: number ; to: number   ; pageSize: number ; maxPage: number ; }
interface PaginationProps {
    total: number;
    pageSize: number;
    maxPage?: number;
    current?: number;
    onChange?:(page:number,options:Options)=>void
}

const createArray=(start: number, end: number)=> {
    const result: number[] = [];
    for (let i: number = start; i <= end; i++) {
        result.push(i);
    }
    return result;
}
export const Pagination: React.FC<PaginationProps> = (props) => {
   const {  total, pageSize, maxPage=5, onChange,current=0 }=props;
    console.log("df",total,pageSize,maxPage)

    const totalPages = Math.ceil(total / pageSize);
    const [currentPage, setCurrentPage] = useState(current > 0 ? current : 1);

    const onPageChange = (page: number) => {
        if (page < 1 || page > totalPages ) return;
        const options={from:(page-1)*pageSize + 1 ,to: Math.min( page*pageSize , total ),pageSize,maxPage}
        if(onChange) onChange(page,options);
        setCurrentPage(page);
    };
    useEffect(() => {
            if(currentPage!==1 && currentPage> Math.ceil(total / pageSize)) setCurrentPage(Math.ceil(total / pageSize)) ;
        } , [total]
    )
    useEffect(() => {
        if(current > 0) setCurrentPage(current)
    },[current])

    useEffect(() => {
        if(currentPage && onChange) onPageChange(currentPage);
    },[currentPage])

    const renderPageNumbers = () => {
        let pageNumbers: JSX.Element[] = [];

        if (totalPages <= maxPage) {
            pageNumbers = createArray(1, totalPages).map((i) => (<ButtonPage  key={`page-${i}`} content={i} isSelected={currentPage===i} handleClick={() => onPageChange(i)} />));

        } else {
            let startPage = 1 ;
            let endPage = maxPage-2;
                
            if( currentPage > maxPage-3 ) {
                pageNumbers.push( <ButtonPage key={`page-1`} content={1} isSelected={currentPage === 1} handleClick={() => onPageChange(1)} /> );
                pageNumbers.push( <ButtonPage key={`page-`+(startPage-1)} content={<span className="mt-[5px]">...</span>} isSelected={false} handleClick={() => onPageChange(startPage-1)} /> );
            }
            
            if( currentPage <= totalPages && currentPage >= totalPages-maxPage+4 ) {
                startPage = totalPages-maxPage+3;
                endPage = totalPages;

            } else if( currentPage > maxPage-3 && currentPage < totalPages-maxPage+4 ) {
                startPage = Math.max(1, currentPage - Math.floor(maxPage / 2)+2);
                endPage = Math.min(totalPages, currentPage + Math.floor(maxPage / 2)-2);
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push( <ButtonPage  key={`page-${i}`} content={i} isSelected={currentPage===i} handleClick={() => onPageChange(i)} /> );
            }

            if( currentPage > totalPages || currentPage < totalPages-maxPage+4 ) {
                pageNumbers.push( <ButtonPage   key={`page-`+(endPage+1)} content={<span style={{marginTop:'-5px'}}>...</span>} isSelected={false} handleClick={() => onPageChange(endPage+1)} /> );
                pageNumbers.push( <ButtonPage   key={`page-`+totalPages} content={totalPages} isSelected={currentPage === totalPages} handleClick={() => onPageChange(totalPages)} /> );
            }
            
        }

        return pageNumbers;
    };
    const rootStyles=classNames({
        [styles.container]:true,
        [styles["kls-ui-pagination-css-var"]]:true,
    })
    return (

        renderPageNumbers().length ?
                
        <div className={rootStyles}>
            <ButtonDirection  direction='left' handleClick={()=>onPageChange(currentPage - 1)} disabled={currentPage<=1} />
            {renderPageNumbers()}
            <ButtonDirection direction='right' handleClick={() => onPageChange(currentPage + 1)} disabled={ currentPage >= totalPages } />
        </div>
        : <></>
    );
};

const ButtonPage = ( {content, isSelected, handleClick}:{content:unknown, isSelected:boolean, handleClick:()=>void} ) => {
    const buttonPageStyle=classNames({
        [styles.baseStyle]:true,
        [styles.pageItem]:true,
        [styles.selectedPageItem]:isSelected
    })
    return (
        <button className={buttonPageStyle}
                onClick={handleClick} >
                {content}
        </button>
    )
}

const ButtonDirection = ( { direction, disabled, handleClick } : {direction:('left'|'right'), disabled:boolean, handleClick:()=>void } ) => {
    const buttonDirectionStyle=classNames({
        [styles.baseStyle]:true,
        [styles.direction]:true,
        [styles.disabledDirection]:disabled
    })
    return (
        <button className={buttonDirectionStyle}
                onClick={handleClick} 
                disabled={disabled} >
            
            { direction==='left' && <span className={`material-icon ${styles.chevron}`}>chevron_left</span> }
            { direction==='right' && <span className={`material-icon ${styles.chevron}`}>chevron_right</span> }

        </button>
    )

}

