import { ApiJaxRsResponse } from '../../../../shared/types';

export const ESERVICE_MARKETPLACE_LIST_API_PATH: ApiJaxRsResponse = {
  path: '/karazal/ow-kls-extrat/marketplacemanager/allMarketPlaceServices',
  apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};

export const ESERVICE_MARKETPLACE_DETAILS_API_PATH: ApiJaxRsResponse = {
  path: '/karazal/ow-kls-extrat/marketplacemanager/serviceDetails',
  apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};

export const ESERVICE_MARKETPLACE_INSTALL_API_PATH: ApiJaxRsResponse = {
  path: '/karazal/ow-kls-extrat/marketplacemanager/installEserviceFromMarket',
  apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};
