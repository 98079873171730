import { BreadCrumb, Tabs } from "../../../../../../../shared/components";
import { FC, useState } from "react";
import styles from '../eservice.param.module.css';
import { PageContentContainer } from "../../../../../layouts";
import { EserviceMembresTab } from "../tabs/internal-eservice-tabs/EserviceMembresTab";
import { useGetIndustriesList } from "../../../../../api";
import { MaterialIcon } from "../../../../../../../shared/components/icons"
import { useHandleDomLocation } from "../../../../../hooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../shared/stores";
import {EServiceInfoExternalTab} from "../tabs/external-eservice-tabs/EserviceInfoExternalTab";

export const ExternalEserviceParamsInterface: FC = () => {
    const [selectedTab] = useState("eService");
    const getIndustriesList = useGetIndustriesList();
    const { profilePathName } = useHandleDomLocation();
    const { guichetId } = useParams<{ guichetId: string}>();
    const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
    const BreadCrumbItems = [
        { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}/guichets/${guichetId}` },
        { title: "e-Services", href: `/${profilePathName}/guichets/${guichetId}/eservices` },
        { title: selectedEservice.name || '', href: `/${profilePathName}/guichets/${guichetId}/eservices` }
    ]
    const tabList = [
        {
            name: 'eService', label: <><span className="icon">bubble_chart</span>E-service</>, children:
                <EServiceInfoExternalTab data={{ industriesList: getIndustriesList?.customHandler?.data }}
                                 isLoading={{ industriesList: getIndustriesList.isLoading }}
                                 isSuccess={{ industriesList: getIndustriesList.customHandler.isSuccess }} />
        },
        { name: 'membres', label: <><span className="icon">manage_accounts</span>Membres</>, children: <EserviceMembresTab /> },
        { name: 'fiche', label: <><span className="icon">description</span>Fiche description</>, children: <></>,disabled:true },
        { name: 'delete', label: <span className={styles.deleteEservice}><span className="icon">delete</span>Suppression du e-service</span>, children: <></>,disabled:true },
    ];

    return (
        <PageContentContainer className="flex flex-col gap-1 !h-[100%]">
            <div className='p-5'>
                <BreadCrumb items={BreadCrumbItems} ></BreadCrumb>
            </div>
            <div className={styles.tabsContainer}>
                <Tabs tabs={tabList} align="horizontal" current={selectedTab} defSelected={selectedTab}  />
            </div>
        </PageContentContainer >
    );
};

