import { DynamicListData, GenericResponse, GenericResponseWrapper } from "../../../../shared/types";
import { PageResponse } from "../../../../shared/types/api.type";
import { HttpMethods } from "../../../../shared/utils/enums";
import {
  fetchGenericJaxRsRequestFromFormData,
  fetchGenericJaxRsRequestFromJson,
} from "../../../../shared/utils/helpers";

import { EserviceCreationRequest, EserviceData, ListEserviceRequest, EserviceJoinRequest, ListRequestJoinEserviceData, RequestJoinEservice, EserviceAcceptJoin, FavoriteEserviceRequest, EserviceMembersListRequest, EserviceMembersListResponse, EServiceSettings, EServiceDetails } from "../../types";
import {
  AcceptInvitation,
  EserviceAddToEshopRequest,
  EserviceAnnulationInvitation,
  EserviceCreationExterneRequest,
  EServiceExterneSettings,
  EServiceExterneStatusRequestSettings,
  EServiceExterneTypePublicationRequestSettings,
  EserviceInvitationRequest
} from "../../types/data/eservice.types";
import {
  ACCEPT_REQUEST_JOIN_API_PATH,
  ADD_ESERVICE_TO_ESHOP_API_PATH,
  ADD_FAVORITE_ESERVICE_API_PATH,
  ANNULATION_INVITATION_ESERVICE_API_PATH,
  CATEGORIES_LIST_API_PATH,
  ESERVICE_CREATION_API_PATH,
  ESERVICE_LIST_API_PATH,
  ESERVICE_LIST_REQUEST_API_PATH,
  ESERVICE_MEMBERS_LIST_API_PATH,
  FETCH_ESERVICE_DETAILS,
  REFUSE_REQUEST_JOIN_API_PATH,
  REQUEST_JOIN_ESERVICE_API_PATH,
  SEND_INVITATION_ESERVICE_API_PATH,
  UPDATE_ESERVICE_INFO,
  INVITE_ADMINISTRATOR_PAGE_API_PATH,
  ACCEPT_INVITATION_API_PATH,
  REFUSE_INVITATION_API_PATH,
  UPDATE_ESERVICEEXTERNE_INFO, UPDATE_STATUS_ESERVICEEXTERNE_INFO, UPDATE_TYPEPUBLICATION_ESERVICEEXTERNE_INFO
} from "./apiPath";
import { AdministrateusPageForm } from "../../types/data.types";
export const getEservicesList = (data: ListEserviceRequest): Promise<GenericResponse<PageResponse<EserviceData[]>>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_LIST_API_PATH, HttpMethods.POST);
};


export const getRequestJoinEservicesList = (data: RequestJoinEservice): Promise<GenericResponse<PageResponse<ListRequestJoinEserviceData[]>>> => {
  return fetchGenericJaxRsRequestFromJson(data, ESERVICE_LIST_REQUEST_API_PATH, HttpMethods.POST);
};

export const getCategoriesList = (): Promise<GenericResponse<DynamicListData[]>> => {
  return fetchGenericJaxRsRequestFromFormData(undefined, CATEGORIES_LIST_API_PATH, HttpMethods.POST);
}

export const getEserviceMembersList = (data: EserviceMembersListRequest): Promise<GenericResponse<PageResponse<EserviceMembersListResponse[]>>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_MEMBERS_LIST_API_PATH, HttpMethods.POST);
};


export const addFavoriteEservice = (data: FavoriteEserviceRequest): Promise<GenericResponse<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ADD_FAVORITE_ESERVICE_API_PATH, HttpMethods.POST);
};

export const sendInvitationToJoinEservice = () => {
  return fetchGenericJaxRsRequestFromJson(undefined, ADD_FAVORITE_ESERVICE_API_PATH, HttpMethods.POST);
}

export const postCreateEservice = (data: EserviceCreationRequest): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_CREATION_API_PATH, HttpMethods.POST);
}
export const postCreateEserviceExterne = (data: EserviceCreationExterneRequest): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_CREATION_API_PATH, HttpMethods.POST);
}

export const postRequestJoinEservice = (data: EserviceJoinRequest): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, REQUEST_JOIN_ESERVICE_API_PATH, HttpMethods.POST);
}

export const postAcceptRequestJoinEservice = (data: EserviceAcceptJoin): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, ACCEPT_REQUEST_JOIN_API_PATH, HttpMethods.POST);
}

export const postRefuseRequestJoinEservice = (data: EserviceAcceptJoin): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, REFUSE_REQUEST_JOIN_API_PATH, HttpMethods.POST);
}

export const postAnnulationInvitation = (data: EserviceAnnulationInvitation): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, ANNULATION_INVITATION_ESERVICE_API_PATH, HttpMethods.POST);
};

export const postAddEserviceToEshop = (data: EserviceAddToEshopRequest): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data, ADD_ESERVICE_TO_ESHOP_API_PATH, HttpMethods.POST)
}
export const updateEserviceSettings = (data: EServiceSettings): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,UPDATE_ESERVICE_INFO,HttpMethods.PUT);
};
export const updateEserviceExternSettings = (data: EServiceExterneSettings): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,UPDATE_ESERVICEEXTERNE_INFO,HttpMethods.PUT);
};
export const updateStatusEserviceExternSettings = (data: EServiceExterneStatusRequestSettings): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,UPDATE_STATUS_ESERVICEEXTERNE_INFO,HttpMethods.PUT);
};
export const updateTypePublicationEserviceExternSettings = (data: EServiceExterneTypePublicationRequestSettings): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,UPDATE_TYPEPUBLICATION_ESERVICEEXTERNE_INFO,HttpMethods.PUT);
};

export const fetchEserviceDetails = (data: EServiceDetails): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,FETCH_ESERVICE_DETAILS,HttpMethods.POST);
};


export const postSendInvitation = (data: EserviceInvitationRequest): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, SEND_INVITATION_ESERVICE_API_PATH, HttpMethods.POST);
};

export const inviteAdministratorSettings = (data: AdministrateusPageForm): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromFormData(data,INVITE_ADMINISTRATOR_PAGE_API_PATH,HttpMethods.POST);
};

export const postAcceptInvitation = (data: AcceptInvitation): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, ACCEPT_INVITATION_API_PATH, HttpMethods.POST);
};

export const postRefuseInvitation = (data: EserviceAnnulationInvitation): Promise<GenericResponseWrapper<null>> => {
  return fetchGenericJaxRsRequestFromJson(data, REFUSE_INVITATION_API_PATH, HttpMethods.POST);
};

