import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialIcon } from '../icons';
import styles from './breadcrumb.module.css';
import classNames from "classnames";
export interface BreadcrumbProps {
  items: { title?: string; icon?: ReactNode; href?: string }[];
}
export const BreadCrumb: FC<BreadcrumbProps> = (props) => {
  const navigate = useNavigate();
  const renderJoinIcon = (render: boolean) => {
    if (render)
      return <MaterialIcon label="chevron_right" center className="text-sm" />;
    return;
  };
  const itemStyle=(href:string|undefined)=>classNames({
    ["flex gap-1 items-center px-1 py-0.5 text-slate-800 dark:text-white"]:true,
    ["rounded-md transition-all duration-300 cursor-pointer select-none hover:bg-slate-200 hover:dark:bg-slate-700"]:href,
  })

  return (
    <div className="flex gap-1 items-center">
      {props.items.map((item, index) => (
        <div key={index} className="flex gap-0.5 items-center">
          <div
            className={itemStyle(item.href)}
            onClick={() => item.href && navigate(item.href)}>
            {item.icon}
            {item.title && <span className={styles.title}>{item.title}</span>}
          </div>
          <span className="text-slate-800 dark:text-white">
            {renderJoinIcon(props.items.length > index + 1)}
          </span>
        </div>
      ))}
    </div>
  );
};
