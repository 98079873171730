import {AdvancedUploader, Button, FilesListViewer, NotificationCard} from "../../../../../../../shared/components";
import {FC, useEffect, useMemo, useState} from "react";
import {useGetCheckVerificationOrganizationStatus} from "../../../../../api";
import {useHandleDomLocation} from "../../../../../hooks";
import classNames from "classnames";
import {NotificationType} from "../../../../../../../shared/utils/enums";
import {Spin} from "antd";
import {MaterialIcon} from "../../../../../../../shared/components/icons";
import {getKarazFileUrl, openInNewTab} from "../../../../../../../shared/utils/helpers";

enum OrganizationVerificationInterfaces{
    NOT_ASSOCIATED="NOT_ASSOCIATED",
    VERIFIED="VERIFIED",
    NOT_VERIFIED="NOT_VERIFIED",
    REJECTED="REJECTED",
    IN_PROGRESS="IN_PROGRESS",
}
type OrganizationVerificationInterfacesTypes="IN_PROGRESS"|"REJECTED"|"NOT_VERIFIED"|"VERIFIED"|"NOT_ASSOCIATED"
interface OrganizationVerificationTabInterfacesProps {
    onSwitch?: (to: OrganizationVerificationInterfaces) => void;
    className?: string;
}
interface OrganizationVerificationDataInterfaceProps extends OrganizationVerificationTabInterfacesProps{
    data:unknown
}
const OrganizationNotVerifiedInterface:FC<OrganizationVerificationTabInterfacesProps>=(props)=>{
    const {className=""}=props;
    const [uploadedFiles,setUploadedFiles]=useState<File[]>([]);
    const filesList= useMemo(()=>uploadedFiles.map((file:File,index:number)=>({key:index.toString(),name:file.name,size:`${file.size/1000}Kb`})),[uploadedFiles])
    const rootStyles=classNames({
        [className]:true,
        "flex flex-col justify-center gap-7":true
    })
    const handleRemoveFile=(key:string)=>{
        console.log("ss")
        const remainedFiles=uploadedFiles.filter((_file,index)=>(key!==index.toString()))

        setUploadedFiles(remainedFiles);
    }
    const handlePreviewFile=(name:string)=>{
        const url=URL.createObjectURL(uploadedFiles.find((item:File)=>item.name===name));

        const encodedUrl=encodeURIComponent(url);

    openInNewTab(`/view-file/${encodedUrl}`)
    }
    return (
        <div className={rootStyles}>
            <div className="w-full flex justify-end">
                <div className="flex items-center gap-1">
                    <span className="font-medium text-[13px] text-slate-800">Statut vérification :</span>
                    <span className="font-normal text-[12px] text-slate-800">Non vérifiée</span>
                    <span className="bg-red-500 h-2 aspect-square rounded-full"></span>
                </div>
            </div>
            <div>
                <span className="font-semibold text-sm text-blue-700">Pièces justificatives</span>
                <AdvancedUploader title="Faites glisser ou déposez vos pièces justificatives ici"
                                  description="Formats pris en charge : PDF, PNG, JPEG"
                                  onUploadFiles={(files) => setUploadedFiles([...uploadedFiles, ...files])}/>
                <FilesListViewer files={filesList} onRemoveFile={handleRemoveFile}
                                 onPreviewFile={(index) => handlePreviewFile(index)}/>
            </div>
            <div className="flex justify-end w-full">
                <Button label="Vérifier l’organisation" styling="solid" size="lg" color="primary"/>
            </div>
        </div>
    )
}

const OrganizationVerificationInProgressInterface: FC<OrganizationVerificationDataInterfaceProps> = (props) => {
    const {className = ""} = props;
    const rootStyles = classNames({
        [className]: true,
        ["flex flex-col justify-center gap-7"]: true
    })
    const IndexedFilesData =useMemo(()=>{
        if(props.data)  return props.data.map((item:object,index:number)=>({key:index.toString(),...item}));
        return []
    },[props.data]);
    const filesList= useMemo(()=>IndexedFilesData.map((item:object,index:number)=>({key:index.toString(),name:item.name,size:`${(item.byteSize/1000).toFixed(2)}Kb`})),[props.data])

    const handlePreviewFile=(key:string)=>{
       const gedId =props.data.map((_data:unknown,index:number)=>(index.toString()===key)).gedId;
       const encodedUrl=encodeURIComponent(getKarazFileUrl(gedId));
       openInNewTab(`/view-file/${encodedUrl}`)
    }
    return (<div className={rootStyles}>
            <div className="w-full flex justify-end">
                <div className="flex items-center gap-1">
                    <div className="flex items-baseline gap-1">
                        <span className="font-medium text-[13px] text-slate-800">Statut vérification :</span>
                        <span className="font-normal text-[12px] text-slate-800">En attente vérification</span>
                    </div>
                    <MaterialIcon label="hourglass_top" center className="text-orange-500 text-md"/>
                </div>
            </div>
            <div>
                <span className="font-semibold text-sm text-blue-700">Pièces justificatives</span>
                <FilesListViewer files={filesList} readOnly onPreviewFile={(index) => handlePreviewFile(index)}/>
            </div>
        </div>
    )
}

const OrganizationRejectedInterface: FC<OrganizationVerificationDataInterfaceProps> = (props) => {
    const [uploadedFiles,setUploadedFiles]=useState<File[]>([]);
    const localFilesList = useMemo(() =>
            uploadedFiles.map((file: File) => ({
                name: file.name,
                size: `${file.size / 1000}Kb`,
                uploadType:"file"
            })),
        [uploadedFiles]
    );
   const karazFilesList= useMemo(() => {
          if (props.data) return  props.data.map((item: unknown) => ({
               name: item.name,
               size: `${(item.byteSize / 1000).toFixed(2)}Kb`,
              uploadType:item.uploadType
           }));
           return [];
          }
       , [props.data]
   );
    const allFiles=localFilesList.concat(karazFilesList)

    const {className = ""} = props;
    const rootStyles = classNames({
        "flex flex-col gap-7 justify-center":true,
        [className]: true
    })
    const handleRemoveFile=(name:string)=>{
        console.log("ss")
        const remainedFiles=uploadedFiles.filter((_file)=>(name!==_file.name))
        setUploadedFiles(remainedFiles);
    }
    const handlePreviewFile=(name:string)=>{
        const file= allFiles.find((file)=>name===file.name)
        if (file.uploadType==="gedId") previewKarazFile(file.gedId);
        else previewLocalFile(name);
    }
    const previewLocalFile=(name:string)=>{
      const file=uploadedFiles.find((file:File)=>file.name===name) as File
      const url=URL.createObjectURL(file);
      previewFile(url);
    }
    const previewKarazFile=(gedId:string)=>{
      const url =getKarazFileUrl(gedId);
      previewFile(url);
    }
    const previewFile=(url:string)=>{
        const encodedUrl=encodeURIComponent(url);
        openInNewTab(`/view-file/${encodedUrl}`)
    }
    return (<div className={rootStyles}>
        <div className="w-full flex justify-end">
            <div className="flex items-center gap-1">
                <span className="font-medium text-[13px] text-slate-800">Statut vérification :</span>
                <span className="font-normal text-[12px] text-slate-800">Non vérifiée</span>
                <span className="bg-red-500 h-2 aspect-square rounded-full"></span>
            </div>
        </div>
        <div className="flex w-full justify-center">
            <NotificationCard type={NotificationType.WARNING} className="w-3/4">
                <div className="text-slate-800 text-xs ">
                    <div className="font-semibold">Demande de vérification refusée</div>
                    <div className="font-normal">La demande a été refusée en raison de l&apos;absence du document justificatif attestant de
                        l&apos;accord du propriétaire légal de l&apos;organisation vous autorisant à créer un espace en son nom.</div>
                </div>
            </NotificationCard>
        </div>
        <div>
            <span className="font-semibold text-sm text-blue-700">Pièces justificatives</span>
            <AdvancedUploader title="Faites glisser ou déposez vos pièces justificatives ici"
                              description="Formats pris en charge : PDF, PNG, JPEG"
                              onUploadFiles={(files) => setUploadedFiles([...uploadedFiles, ...files])}/>
            <FilesListViewer files={allFiles} onRemoveFile={handleRemoveFile}
                             onPreviewFile={(index) => handlePreviewFile(index)}/>
        </div>
        <div className="flex justify-end w-full">
            <Button label="Vérifier l’organisation" styling="solid" size="lg" color="primary" />
        </div>

    </div>)
}

const OrganizationVerifiedInterface: FC<OrganizationVerificationTabInterfacesProps> = (props) => {
    const {className = ""} = props;
    const rootStyles = classNames({
        [className]: true
    })
    return (<div className={rootStyles}>
        <div className="w-full flex justify-end">
            <div className="flex items-center gap-1">
                <span className="font-medium text-[13px] text-slate-800">Statut vérification :</span>
                <span className="font-normal text-[12px] text-slate-800">Vérifiée</span>
                <MaterialIcon label="verified" center className="text-emerald-500 text-md"/>
            </div>
        </div>
        {/*<FilesListViewer files={} onRemoveFile={}/>*/}
    </div>)
}
const OrganizationNotAssociatedInterface: FC<OrganizationVerificationTabInterfacesProps> = (props) => {
    const {className = ""} = props;
    const rootStyles = classNames({
        "flex justify-center":true,
        [className]: true
    })
    return (<div className={rootStyles}>
        <NotificationCard type={NotificationType.WARNING} className="w-1/3">
            <div className="flex flex-col gap-1">
                <div className=" text-slate-800 text-xs font-semibold">Complétez les informations d’organisation</div>
                <div className=" text-slate-800 text-xs font-normal">Veuillez vous assurer de saisir et de compléter toutes les informations requises concernant votre organisation avant de procéder à la vérification!</div>
            </div>
        </NotificationCard>
    </div>)
}
export const OrganizationVerificationTab = () => {
    const [currentInterface, setCurrentInterface] = useState<OrganizationVerificationInterfaces>();
    const {windowId = ""} = useHandleDomLocation();
    const interfacesStyles = (_interface: OrganizationVerificationInterfaces) =>
        classNames({
            "transition-opacity duration-400": true,
            ['show']: currentInterface === _interface,
            ['notDisplayV2']: currentInterface !== _interface
        });
    const getCheckVerificationOrganizationStatus=useGetCheckVerificationOrganizationStatus(windowId)
    // const handleInterfaceSwitch=(_interface:OrganizationVerificationInterfaces)=>setCurrentInterface(_interface)

    useEffect(() => {
        if (getCheckVerificationOrganizationStatus.customHandler.isSuccess && !getCheckVerificationOrganizationStatus.isFetching){
            setCurrentInterface(OrganizationVerificationInterfaces[getCheckVerificationOrganizationStatus.customHandler.state as OrganizationVerificationInterfacesTypes ])}
    }, [getCheckVerificationOrganizationStatus.customHandler.isSuccess,getCheckVerificationOrganizationStatus.isFetching]);
  console.log(currentInterface,getCheckVerificationOrganizationStatus);
    return (
        <div>
            {
                getCheckVerificationOrganizationStatus.isFetching &&
                <div className="w-full flex justify-center min-h-[30vh] items-center">
                    <Spin size="large"/>
                </div>
            }
            {
                getCheckVerificationOrganizationStatus.customHandler.isSuccess &&  !getCheckVerificationOrganizationStatus.isFetching &&
                <>
                <OrganizationNotAssociatedInterface className={interfacesStyles(OrganizationVerificationInterfaces.NOT_ASSOCIATED)}   />
                <OrganizationVerifiedInterface className={interfacesStyles(OrganizationVerificationInterfaces.VERIFIED)}/>
                <OrganizationRejectedInterface className={interfacesStyles(OrganizationVerificationInterfaces.REJECTED)} data={getCheckVerificationOrganizationStatus.customHandler.data}/>
                <OrganizationVerificationInProgressInterface className={interfacesStyles(OrganizationVerificationInterfaces.IN_PROGRESS)} data={getCheckVerificationOrganizationStatus.customHandler.data}/>
                <OrganizationNotVerifiedInterface  className={interfacesStyles(OrganizationVerificationInterfaces.NOT_VERIFIED)}/>

                </>
            }


        </div>)
}
