import {ChangeEvent, forwardRef, ForwardRefRenderFunction,useMemo, useState} from "react";
import {InputText} from "../input-text/InputText";
import {Select} from "../../drop-down/select/Select";
import classNames from "classnames";
import globalStyles from "../input.module.css";
import {PHONE_NUMBER_DATA} from "../../../utils/constants";
import {InputPhoneProps} from "../../../types";
import {getFlagsUrl} from "../../../utils/helpers";

export const ForwardedInputPhone:ForwardRefRenderFunction<HTMLInputElement,InputPhoneProps>=(props)=>{

   const getErrorMessage = props.errorMessage ?? "";
   const {size="sm"}=props
   const [phoneNumber,setPhoneNumber]=useState<{prefix:string|undefined,suffix:string|undefined}>({prefix:undefined,suffix:undefined})
   const getOptionData = useMemo(
        () => () =>
            PHONE_NUMBER_DATA.map((item) => ({
                label: item.country,
                value: item.prefix,
                icon: <img alt="" src={getFlagsUrl(item.countryCode,16)}/>
            })),
        []
    );
    const getInputPlaceholder= useMemo(
        () => () =>
            PHONE_NUMBER_DATA.find((item) => (item.prefix===phoneNumber.prefix))?.placeholder,
        [phoneNumber]
    );
  const handleSelectChange=(e:Event)=>{
      const inputElement=e.target as HTMLInputElement
      if (!inputElement)  return ;
      const _phoneNumber={...phoneNumber,prefix:inputElement.value}
      handleChange(_phoneNumber);
  }
  const handleInputChange=(e:ChangeEvent<HTMLInputElement>)=>{
      const inputElement=e.target as HTMLInputElement
      if (!inputElement)  return ;
      const _phoneNumber={...phoneNumber,suffix:inputElement.value}
      handleChange(_phoneNumber);
 }
 const handleChange=(_phoneNumber:{prefix:string|undefined,suffix:string|undefined})=>{
     setPhoneNumber(_phoneNumber)
     if (_phoneNumber && props.onChange) props.onChange(_phoneNumber);
 }

    const footerStyles = classNames({
        ["transition-all duration-300 "]: true,
        ["show"]: props.errorMessage,
        ["notShow"]: !props.errorMessage,
        ["h-3"]: props.withError
    })
const rootStyles=classNames({
    [globalStyles.rootContainer]:true,
    [globalStyles["portal-input-css-var"]]:true,
})
const inputLabelStyle=classNames({
        [globalStyles.inputLabel]:true,
        ["text-xs"]:size==="sm",
        ["text-sm"]:["lg","md"].includes(size),
    })
    const renderLabel=()=>{
        if (props.label) return (
            <div className="flex justify-between w-full ">
                <span className={inputLabelStyle}>{props.label}</span>
                <span></span>
            </div>)
    }

 const renderSelectedValueSelectProps=()=>{
     if(props.value) return {selectedValues:props.value.prefix}
 }
 const renderSelectedValueInputProps=()=>{
        if(props.value) return {value:props.value.suffix}
    }
    const renderFooter = () => {
        if (props.withError) return (<span className="text-red-600 text-xs">{getErrorMessage}</span>)
        return (<></>)
    }
return (
    <div className={rootStyles}>
       {renderLabel()}
    <div className={"flex items-center w-full"}>
     <Select size={size} options={getOptionData()} disabled={props.disabled} placeholder={props.prefixPlaceholder}  onChange={handleSelectChange}  removeRightRadius  errorMessage={props.errorMessage} {...renderSelectedValueSelectProps()} />
     <InputText size={size} placeholder={getInputPlaceholder()||props.placeholder} disabled={props.disabled} onChange={handleInputChange} errorMessage={props.errorMessage}  {...renderSelectedValueInputProps()}  removeLeftRadius />
    </div>
        <div className={footerStyles}>
            {renderFooter()}
        </div>

    </div>)
}
export const InputPhone=forwardRef(ForwardedInputPhone)

