import styles from './button.module.css';
import classNames from "classnames";
import {ButtonProps} from "../../../types";
import {FC} from "react";

const Button: FC<ButtonProps> = (props) => {
    const {
        label,
        rightIcon,
        styling,
        color,
        size,
        leftIcon,
        disabled,
        onClick = () => {},
    } = props;

    const rootStyles = classNames({
        [styles.rootContainer]: true,
        [styles[styling]]: true,
        [styles[color]]: true,
        "opacity-30": disabled,
        "gap-0.5 py-[7px] px-[8px] text-[12px]": ["sm"].includes(size),
        "gap-1 py-[8.5px] px-[12px] text-[13px]": ["md"].includes(size),
        "gap-1 py-[10.5px] px-[12px] text-[13px]": ["lg"].includes(size),
    });

    const renderBeforeText = () => {
        if (leftIcon) return leftIcon; // Return directly
        return null;
    };

    const renderAfterText = () => {
        if (rightIcon) return rightIcon; // Return directly
        return null;
    };

    const renderLabel = () => {
        if (label) return <span className="flex items-center justify-center">{label}</span>;
    };

    return (
        <button className={rootStyles} onClick={onClick} disabled={disabled}>
            <>
                {renderBeforeText()}
                {renderLabel()}
                {renderAfterText()}
            </>
        </button>
    );
};

export { Button };
export type { ButtonProps };
