import React from 'react';
import EserviceInfo from './components/EserviceInfo';
import EserviceCarousel from './components/EserviceCarousel';
import { Tabs } from '../../../../../../../shared/components';
import EserviceFonctionality from './components/EserviceFonctionality';
import ServiceCard from '../../../../../components/ui/cards/eservice-card/ServiceCard';
import { EserviceMarketPlaceData } from '../../../../../types/data/eservice.types';

interface EserviceDetailsProps {
  service: EserviceMarketPlaceData;
  returnAction: () => void;
}
const EserviceDetails: React.FC<EserviceDetailsProps> = ({
  service,
  returnAction
}) => {
  const tabs = [
    {
      name: 'Snapshots',
      label: (
        <div className="flex items-center">
          <span>Snapshots</span>
        </div>
      ),
      children: (
        <>
          {service.snapshots && service.snapshots?.length > 0 && (
            <EserviceCarousel steps={service.snapshots} />
          )}
        </>
      )
    },
    {
      name: 'Fonctionnalités',
      label: (
        <div className="flex items-center">
          <span>Fonctionnalités</span>
        </div>
      ),
      children: (
        <>
          {service.features &&
            service.features?.length > 0 &&
            service.features.map((feature, index) => (
              <EserviceFonctionality
                key={index}
                imageURL={feature.iconURL || ''}
                description={feature.description || ''}
                label={feature.label || ''}
              />
            ))}
        </>
      )
    },
    {
      name: 'Personae',
      label: (
        <div className="flex items-center">
          <span>Personae</span>
        </div>
      ),
      children: (
        <>
          {service.personas &&
            service.personas?.length > 0 &&
            service.personas.map((persona, index) => (
              <EserviceFonctionality
                key={index}
                imageURL={persona.iconURL || ''}
                description={persona.description || ''}
                label={persona.label || ''}
              />
            ))}
        </>
      )
    },
    {
      name: 'Dépendances',
      label: (
        <div className="flex items-center">
          <span>Dépendances</span>
        </div>
      ),
      children: (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-2 overflow-y-auto h-[100%]">
          {service &&
            service.dependencies &&
            service.dependencies.length > 0 &&
            service.dependencies.map((dependency, index) => (
              <ServiceCard key={index} eserviceMarketPlace={dependency} />
            ))}
        </div>
      )
    },
    {
      name: 'Options tarifaires',
      label: (
        <div className="flex items-center">
          <span>Options tarifaires</span>
        </div>
      ),
      children: <div></div>
    }
  ];
  return (
    <div className="flex flex-col overflow-y-auto">
      <EserviceInfo
        cloneUrl={service.gitCloneUrl}
        idService={service.service}
        returnAction={returnAction}
        img={service.catalog.catalogImage || ''}
        logo={service.catalog.catalogIcon || ''}
        organization={service.catalog.guichet}
        title={service.catalog.titre}
        version={service.defaultVersion || ''}
        description={service.catalog.description || ''}
        isFree={service.servicePricingStatus == 'Gratuit'}
      />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default EserviceDetails;
