import {
  RoleEservice,
  RoleGuichet
} from '../../../../shared/permissions/role.types';
import {
  CARD_DROPDOWN_ALL, CARD_DROPDOWN_EXTERNE_ESERVICE,
  CARD_DROPDOWN_NO_ROLE
} from '../constants/components.constants';
import { hasPermission } from '../../../../shared/utils/helpers/permission.helper';
import { MaterialIcon } from '../../../../shared/components/icons';
import { useHandleDomLocation } from '../../hooks';
import { Tab } from '../../../../shared/components/tabs/Tabs';

export const getDropDownDataPermissions = (
  roles: RoleEservice[] | RoleGuichet[] | string[] | undefined,
  data: Permissions[keyof Permissions]['dataType'],


) => {
  const { profilePathName, isGuichetPage } = useHandleDomLocation();
  if (profilePathName === 'utilisateur') return CARD_DROPDOWN_ALL;

  if (!roles?.length && isGuichetPage)   return CARD_DROPDOWN_ALL;

  if (!roles?.length && !isGuichetPage)  return CARD_DROPDOWN_NO_ROLE;

  if (data.isKaraz === false ) return CARD_DROPDOWN_EXTERNE_ESERVICE;

  if (Array.isArray(roles)) {
    const dropdownItems = [];

    const hasContributeurAndCitizenDeveloper = roles.includes('Contributeur');
    if (hasContributeurAndCitizenDeveloper && roles.length === 1) {
      dropdownItems.push(...CARD_DROPDOWN_NO_ROLE);
    }
    if (
      hasPermission(
        'eservices',
        'actions',
        'devenirContributeur',
        roles,
        data
      ) &&
      !hasContributeurAndCitizenDeveloper
    ) {
      dropdownItems.push({
        label: 'Devenir contributeur',
        value: 'rejoindre',
        icon: <MaterialIcon label="add" className="text-red-500 text-base" />
      });
    }

    if (hasPermission('eservices', 'actions', 'editer', roles, data)) {
      dropdownItems.push({
        label: 'Éditer',
        value: 'edit',
        icon: <MaterialIcon label="edit" className="text-blue-500 text-base" />
      });
    }
    if (hasPermission('eservices', 'actions', 'aperçu', roles, data)) {
      dropdownItems.push({
        label: 'Aperçu',
        value: 'view',
        icon: (
          <MaterialIcon
            label="visibility"
            className="text-orange-500 text-base"
          />
        )
      });
    }
    if (hasPermission('eservices', 'actions', 'cloner', roles, data)) {
      dropdownItems.push({
        label: 'Cloner',
        value: 'clone',
        icon: (
          <MaterialIcon
            label="content_copy"
            className="text-emerald-600 text-base"
          />
        )
      });
    }
    if (hasPermission('eservices', 'actions', 'parametres', roles, data)) {
      dropdownItems.push({
        label: 'Paramètres',
        value: 'param',
        icon: (
          <MaterialIcon label="settings" className="text-red-500 text-base" />
        )
      });
    }

    return dropdownItems.length > 0 ? dropdownItems : CARD_DROPDOWN_ALL;
  }

  return CARD_DROPDOWN_ALL;
};
export const getTabListWithPermissions = (
  tabList: Tab[],
  roles: RoleEservice[]
) => {
  return tabList.filter((tab) => {
    switch (tab.name) {
      case 'eService':
        return (
          hasPermission(
            'eservices',
            'menus',
            'eService',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'modules':
        return (
          hasPermission(
            'eservices',
            'menus',
            'modules',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'schemaRelational':
        return (
          hasPermission(
            'eservices',
            'menus',
            'schemaRelational',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'membres':
        return (
          hasPermission(
            'eservices',
            'menus',
            'membres',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'contributeurs':
        return (
          hasPermission(
            'eservices',
            'menus',
            'membres',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'user':
        return (
          hasPermission(
            'eservices',
            'menus',
            'user',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'control':
        return (
          hasPermission(
            'eservices',
            'menus',
            'control',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'catalog':
        return (
          hasPermission(
            'eservices',
            'menus',
            'catalog',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      case 'delete':
        return (
          hasPermission(
            'eservices',
            'menus',
            'delete',
            roles,
            undefined,
            'topMenu'
          ) && tab
        );

      default:
        return tab;
    }
  });
};
