import {Tabs} from "../../../../../shared/components";
import {PageContentContainer} from "../../../layouts";
import {MaterialIcon} from "../../../../../shared/components/icons";
import {OrganizationIdentityTab} from "./tabs/organization-identity-tab/OrganizationIdentityTab";
import {OrganizationVerificationTab} from "./tabs/organization-verification-tab/OrganizationVerificationTab";

export const WindowIdentity =()=>{
    const tabs = [
        { name: 'e-service',
          label:
              <div className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="window"/>
                  <span>Guichet unique</span>
              </div>,
          children: <></>
        },
        {
          name: 'module-name',
          label:
              <div  className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="language"/>
                  <span>Nom de domaine</span>
              </div>,
          children: <div></div> },
        {
          name: 'organization',
          label:
              <div  className="flex gap-1 items-center">
                  <MaterialIcon className="text-base" label="corporate_fare"/>
                  <span>Organisation</span>
             </div>,
          children: <OrganizationIdentityTab/> },
        {
            name: 'verification-organization',
            label:
                <div  className="flex gap-1 items-center">
                    <MaterialIcon className="text-base" label="verified"/>
                    <span>Vérification organisation</span>
                </div>,
            children: <OrganizationVerificationTab/> },
    ];
    return (
        <PageContentContainer overflow className="px-6 py-8">
            <Tabs tabs={tabs} className="h-[70vh]" overflow/>
     </PageContentContainer>)
}