import { postFileCheck, postDataFileCheck } from '../../../../../shared/utils/helpers/api.helper';
import { API_PATHS } from './apiPath';

export const checkFile = async (file: File, guichetId: string) => {
    const responseData = await postFileCheck(API_PATHS.CHECK_FILE, file, guichetId);
    return {
        isValidFile: responseData.isValidFile,
        merged_cells_found: responseData.merged_cells_found,
        graphs_found: responseData.graphs_found,
        header_found: responseData.header_found,
        csvDelimitedError: responseData.csvDelimitedError
    };
};

export const checkForDataConsistency = async (fileName: string, guichetId: string) => {
    return postDataFileCheck(API_PATHS.CHECK_DATA_CONSISTENCY, fileName, guichetId);
};
