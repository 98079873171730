import { classNames } from "@react-pdf-viewer/core";
import { ReactNode, FC, useState, useEffect } from "react";

interface InputRadioButtonProps {
    label: string;
    icon?: ReactNode;
    selected?: boolean; 
    onchange?: (selected: boolean) => void;
}

export const InputRadioButton: FC<InputRadioButtonProps> = (props) => {
    const { icon = <></>, selected = false } = props; 
    const [isSelected, setIsSelected] = useState<boolean>(selected); 

    const rootStyle = classNames({
        "[&>*]:text-slate-400 text-sm dark:[&>*]:text-slate-500 flex gap-2 px-[10px] py-1 cursor-pointer items-center": true,
        "bg-blue-600 [&>*]:!text-white rounded-3xl": isSelected, 
    });

    const handleClick = () => {
        if (!isSelected) {
            setIsSelected(true); 
            if (props.onchange) props.onchange(true);
        }
    };

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div className={rootStyle} onClick={handleClick}>
            {icon}
            <span>{props.label}</span>
        </div>
    );
};
