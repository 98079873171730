import {List} from "../../list/List";
import {FC, ReactNode} from "react";
import {useHandleComponentVisibility} from "../../../hooks";
import classNames from "classnames";

interface MenuProps  {
    icon :ReactNode
    list?: {label:string,value:string,icon?:ReactNode,onClick?:(selectedValues:string[])=>void } [];
    onChange?:(selectedValues:string[])=>void
    size?:"sm"|"md",
    onClick?:()=>void
    className?:string,
    action?:boolean,
}
export const Menu:FC<MenuProps>=(props)=>{
   const {className="",onClick=()=>{},onChange=()=>{},action=false}=props;
    const {isOpen,setOpenStatus,ref}=useHandleComponentVisibility()
    const handleIconClick=()=>{
       if (props.list) setOpenStatus(true);
        onClick();
    }
    const rootStyles=classNames({
        [className]:true,
        "relative w-fit":true
    })
    return (
    <div className={rootStyles}  ref={ref}>
        <div className="cursor-pointer" onClick={handleIconClick}>
            {props.icon}
        </div>
        {props.list && <List  list={props.list} action={action} onChange={onChange} open={isOpen} className="top-6 right-0 z-30" setOpenStatus={setOpenStatus} closeAlways preventCheck  />}
    </div>)
}
