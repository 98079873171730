
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { message } from "antd";
import { useParams } from "react-router-dom";
import styles from '../../eservice.param.module.css';
import {usegetEserviceDetails, useStatusEServiceExterneSettings} from "../../../../../../api";
import {EServiceExterneSettingsSchema} from "../../../../../../holders/schemas";
import { EServiceDetails } from "../../../../../../types";
import { DynamicListData } from "../../../../../../../../shared/types";
import { NotificationType } from "../../../../../../../../shared/utils/enums";
import { useHandleTranslation } from "../../../../../../../../shared/hooks";
import { SimpleCard, NotificationCard, Button } from "../../../../../../../../shared/components";
import { InputText, Select, TextArea, InputColor, InputTags,MaterialIconSelect} from "../../../../../../../../shared/components/controlled-ui";
import {PubStates} from "../../../../../../components/ui/pubstates/PubStates";
import {
  useEServiceExterneSettings,
  useTypePublicationEServiceExterneSettings
} from "../../../../../../api/eservice/caller";
import { EServiceExterneSettings } from "../../../../../../types/data/eservice.types";
import { ConfirmModal } from "../../../eservices-list-modals/EservicePublicaionConfirmation";

interface ApiData { industriesList: DynamicListData[] | undefined; }

interface ApiStatus { industriesList: boolean | undefined; }
interface EServiceSettingsProps {
  rootClassName?: string;
  isError?: boolean | undefined;
  backButton?: { label: string, onClick: () => void }
  isLoading: ApiStatus;
  isSuccess?: ApiStatus
  data: ApiData;
}
interface Catalog {
  titre: string;
  guichet: string;
  owner: string;
  status:string;
  typePublication:string;
  urlWeb:string;
  publicationTime: string | null;
  iconMaterial: string;
  color: string;
  catalogImage: string;
  description: string;
  industrie: string;
  tags: string[];
  snapshots: string[];

}


interface TagData {
  tags: string[];
}
interface ApiResponse { industrie: string;status:string;typePublication:string;urlWeb:string; tags: TagData; catalog: Catalog ,snapshots:[]}

export const EServiceInfoExternalTab: FC<EServiceSettingsProps> = (props) => {
  const paramsID = useParams<{ eserviceID: string }>();
  const getEserviceDetails = usegetEserviceDetails();
  const updateEserviceExterneSettings = useEServiceExterneSettings();
  const updateStatusEserviceExterneSettings = useStatusEServiceExterneSettings();
  const updateTypePublicationEserviceExterneSettings = useTypePublicationEServiceExterneSettings();
  const { t: tO } = useHandleTranslation("window");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<string | null>(null);
  const [confirmTypePublication, setConfirmTypePublication] = useState<string | null>(null);
  const [isConfirmTypePublicationModalOpen, setConfirmTypePublicationModalOpen] = useState(false);

  const [catalogDetails, setCatalogDetails] = useState<Catalog>({ titre: '',guichet: '', owner: '', publicationTime: null,urlWeb:'', iconMaterial: '', color: '', catalogImage: '',description: '', industrie: '',typePublication:'',status:'', tags:[],snapshots:[]});
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);




  const { control, handleSubmit, formState: { errors }, reset } = useForm<EServiceExterneSettings>({
    resolver: yupResolver(EServiceExterneSettingsSchema), defaultValues: { color: "#0E7490" },
  });
  const errorMessageStyles = classNames({
    ["!my-4"]: true,
    ['notShow']: !isErrorDisplayed,
    ['show transition-all duration-500']: isErrorDisplayed
  });

  const renderindustriesOptions = () => {
    return props
      .data
      .industriesList
      ?.map((item: DynamicListData) => (
        {
          ...item,
          icon: <i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>
        }))
  }
  const handleSubmitEserviceSettings = (data: EServiceExterneSettings) => {
    updateEserviceExterneSettings.mutate(data);
  }
  const onSubmit = (formData: EServiceExterneSettings) => {
    handleSubmitEserviceSettings({
      ...formData, idEservice: paramsID.eserviceID
    });
    setIsEditMode(false);
  };
  const fetchEserviceDetails = (data: EServiceDetails) => {
    getEserviceDetails.mutate(data);
  }
  useEffect(() => {
    if (updateEserviceExterneSettings.customHandler.errorMessage) {
      setErrorMessage(updateEserviceExterneSettings.customHandler.errorMessage);
      setIsErrorDisplayed(true);
    } else {
      setIsErrorDisplayed(false);
    }
  }, [updateEserviceExterneSettings.customHandler.errorMessage]);

  useEffect(() => {
    if (paramsID?.eserviceID) {
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
    }
  }, [paramsID?.eserviceID]);

  useEffect(() => {
    const apiResponse = getEserviceDetails?.customHandler?.data as ApiResponse[] | null;
    if (apiResponse && Array.isArray(apiResponse) && apiResponse.length > 0) {
      const catalogResponse = apiResponse[0]['catalog'];
      const tagResponse = apiResponse[0]['tags'];
      const snapshotsResponse = apiResponse[0]['snapshots'];
      const urlWeb = apiResponse[0]['urlWeb'];
      const statusEsevice = apiResponse[0]['status'];
      const typePublicationEservice = apiResponse[0]['typePublication'];
      console.log("api response",apiResponse);
      console.log("snapshotsResponse",snapshotsResponse.length);
      if (catalogResponse) {

        // Ensure tagResponse is a string array
        const tags: string[] = Array.isArray(tagResponse) ? tagResponse : [];
        setCatalogDetails({
          titre: catalogResponse.titre || '',
          guichet: catalogResponse.guichet || '',
          owner: catalogResponse.owner || '',
          status: statusEsevice || '',
          typePublication:typePublicationEservice || '',
          publicationTime: catalogResponse.publicationTime || null,
          iconMaterial: catalogResponse.iconMaterial || '',
          color: catalogResponse.color || '',
          catalogImage: catalogResponse.catalogImage || '',
          description: catalogResponse.description || '',
          snapshots:snapshotsResponse,
          urlWeb,
          industrie: apiResponse[0]?.industrie || "",
          tags: tags,
        });
      }

    }
  }, [getEserviceDetails?.isSuccess]);

  const handleEdit = () => setIsEditMode(true);

  const handleCancel = () => {
    setIsEditMode(false);
    reset(catalogDetails);
  }


  const handleUpdateStatus = (newStatus: string) => {
    if (!paramsID?.eserviceID) {
      return;
    }
    updateStatusEserviceExterneSettings.mutate({ idEservice: paramsID.eserviceID, status: newStatus });
  };
  const handleUpdateTypePublication = (newTypePublication: string) => {
    if (!paramsID?.eserviceID) {
      return;
    }
    updateTypePublicationEserviceExterneSettings.mutate(
        { idEservice: paramsID.eserviceID, typePublication: newTypePublication },
    );
  };


  // Gestion du succès pour update status Eservice externe

  useEffect(() => {
    if (updateStatusEserviceExterneSettings.customHandler.isSuccess) {
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
      setConfirmModalOpen(false);
      message.success('Statut de publication modifié avec succès !', 2.5);
    }
  }, [updateStatusEserviceExterneSettings.customHandler.isSuccess]);


  // Gestion erreur pour update status Eservice externe

  useEffect(() => {
    if (updateStatusEserviceExterneSettings.customHandler.isError && updateStatusEserviceExterneSettings.customHandler.errorMessage === "FICHE_DESCRIPTIVE_EMPTY") {
      setConfirmModalOpen(false);
      messageApi.error({
        content: (
            <div className="flex flex-col gap-2">
              <strong className="font-semibold text-[13px]">Fiche descriptive non renseignée !</strong>
              <div className="font-medium text-[13px]">Veuillez renseigner la fiche descriptive avant de publier l&apos;e-service.</div>
            </div>
        ),
        duration: 2,
      });
    }
  }, [updateStatusEserviceExterneSettings.customHandler.isError]);


  //Gestion erreur pour update typePublication Eservice externe
  useEffect(() => {
    if (updateTypePublicationEserviceExterneSettings.customHandler.isError) {
      messageApi.error({
        content: 'Statut de visibilité modifié avec succès !',
        duration: 2,
        style: { marginTop: "12vh", zIndex: 1 },
      });
    }
  }, [updateTypePublicationEserviceExterneSettings.customHandler.isError]);

  // Gestion du succès pour update typePublication Eservice externe
  useEffect(() => {
    if (updateTypePublicationEserviceExterneSettings.customHandler.isSuccess) {
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
      setConfirmTypePublicationModalOpen(false);
      message.success('Statut de publication modifié avec succès !', 2.5);
    }
  }, [updateTypePublicationEserviceExterneSettings.customHandler.isSuccess]);


  useEffect(() => {
    console.log("reseet",catalogDetails.iconMaterial)
    reset({ name: catalogDetails?.titre === 'undefined' ? '' : catalogDetails?.titre, owner: catalogDetails?.owner === 'undefined' ? '' : catalogDetails.owner, description: catalogDetails?.description === 'undefined' ? '' : catalogDetails?.description, color: catalogDetails?.color || "#0E7490", industrie: catalogDetails?.industrie || "", iconMaterial: catalogDetails?.iconMaterial || "", tags: catalogDetails?.tags,urlWeb:catalogDetails?.urlWeb || "" });
  }, [catalogDetails, reset]);

  useEffect(() => {
    if (updateEserviceExterneSettings.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({ content: "e-Service modifié avec succès !", duration: 2, style: { marginTop: "12vh", marginLeft: "auto", marginRight: "auto", zIndex: "1", borderRadius: "8px", border: "1px solid var(--mapping-slate-200)", width: "fit-content", boxShadow: "0px 2px 4px -2px #0000001A , 0px 4px 6px -1px #0000001A", } });
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
    }
  }, [updateEserviceExterneSettings.customHandler.isSuccess]);


  return (
      <SimpleCard spin={updateEserviceExterneSettings.isPending || getEserviceDetails.isPending} className={styles.settingsContainer}>
      {isErrorDisplayed && <NotificationCard type={NotificationType.ERROR} className={errorMessageStyles}>{errorMessage} </NotificationCard>}
      {contextHolder}
      <div>
        <div className={styles.settingsForm}>
          <div className={styles.formHeader}>
            <InputText withError control={control} size="lg" label={tO("eService_name_label")}
                       placeholder={tO("eService_name_placeholder")} name="name" error={errors.name?.message}
                       errorMessage={errors.name?.message} disabled={!isEditMode} readOnly={!isEditMode}/>
            <InputText value={tO("eService_owner_placeholder")} disabled readOnly={true} control={control} size="lg"
                       label={tO("eService_owner_label")} placeholder={tO("eService_owner_placeholder")} name="owner"
                       error={errors.owner?.message} errorMessage={errors.owner?.message}/>
          </div>
          <div className={styles.formHeader}>
            <InputText withError control={control} size="lg" label={tO("eService_owner_link")}
                       placeholder={tO("eService_link_placeholder")} name="urlWeb" error={errors.urlWeb?.message}
                       errorMessage={errors.urlWeb?.message} disabled={!isEditMode} readOnly={!isEditMode}/>
          </div>
          <div className={styles.formdescription}>
            <TextArea control={control} error={errors.description?.message} name="description" size="lg"
                      label={tO("eService_description_label")} placeholder={tO("eService_description_placeholder")}
                      disabled={!isEditMode} readOnly={!isEditMode}/>
          </div>
        </div>
        <div className={styles.dividerLine}></div>
        <div className={styles.identityDetails}>
          <div className={styles.domainIconColor}>
            <div className={styles.dropdownDomain}>
              <Select showSelectedIcon size="lg" label={tO("eService_domain_label")}
                      placeholder={tO("eService_domain_placeholder")} options={renderindustriesOptions() || []}
                      alwaysClose name="industrie" selectedValues={control._defaultValues.industrie} control={control}
                      error={errors.industrie?.message} disabled={!isEditMode} readOnly={!isEditMode}/>
            </div>
            <div className={styles.dropdownIcon}>
              <MaterialIconSelect size="lg" label={tO("eService_icon_label")} error={errors.iconMaterial?.message}
                                  placeholder={tO("eService_icon_placeholder")} disabled={!isEditMode}
                                  readOnly={!isEditMode} control={control} name={"iconMaterial"} />
            </div>
            <div className={styles.colorPicker}>
              <InputColor
                  error={errors.color?.message}
                  errorMessage={errors.color?.message}
                  name="color"
                  control={control}
                  label={tO("eService_color_label")}
                  size="lg"
                  defaultValue={catalogDetails.color || "#0E7490"}
                  disabled={!isEditMode}
                  readOnly={!isEditMode}
              />
            </div>
          </div>
          <div className={styles.tagInput}>
            <InputTags disabled={!isEditMode}
                       readOnly={!isEditMode} label={tO("eService_tags_label")} placeholder={tO("eService_tags_placeholder")}
                       control={control} name="tags" size="lg"
                       errorMessage={errors.tags?.message}/>
          </div>
          <div className={styles.accountContainer}>
            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onCancel={() => setConfirmModalOpen(false)}
                onValidate={() => handleUpdateStatus(confirmStatus!)}
                message={confirmStatus === "Public"
                    ? tO("Confirmez-vous vouloir rendre l'e-service public ?")
                    : tO("Confirmez-vous vouloir rendre l'e-service non publié ?")}
            />
            {catalogDetails.status === "Brouillon" && (
                <PubStates
                    handleClick={() => {
                      setConfirmStatus('Public');
                      setConfirmModalOpen(true);
                    }}
                    disabled={!isEditMode}
                    status="Brouillon"
                    value="orange"
                    type="color"
                    description={tO("eService_pubStates_BrouillonDescription")}
                    actionText={tO("eService_pubStates_brouillonActionText")}
                />
            )}

            {catalogDetails.status === "Public" && (
                <PubStates
                    handleClick={() => {
                      setConfirmStatus('Non Publié');
                      setConfirmModalOpen(true);
                    }}
                    disabled={!isEditMode}
                    status="Publié"
                    value="green"
                    type="color"
                    description={tO("eService_pubStates_PublicDescription")}
                    actionText={tO("Rendre non publié")}
                />
            )}

            {catalogDetails.status === "Non Publié" && (
                <PubStates
                    handleClick={() => {
                      setConfirmStatus('Public');
                      setConfirmModalOpen(true);
                    }}
                    disabled={!isEditMode}
                    status="Non Publié"
                    value="red"
                    type="color"
                    actionText={tO("eService_pubStates_privateActionText")}
                    description="Ce e-service est actuellement non publié."
                />
            )}

            <ConfirmModal
                isOpen={isConfirmTypePublicationModalOpen}
                onCancel={() => setConfirmTypePublicationModalOpen(false)}
                onValidate={() => handleUpdateTypePublication(confirmTypePublication!)}
                message={confirmTypePublication === "Publish"
                    ? tO("Confirmez-vous vouloir rendre l'e-service publié ?")
                    : tO("Confirmez-vous vouloir rendre l'e-service privé ?")}
            />

            {catalogDetails.typePublication === "Private Service" && catalogDetails.status !== "Brouillon" && (
                <PubStates
                    handleClick={() => {
                      setConfirmTypePublication('Publish');
                      setConfirmTypePublicationModalOpen(true);
                    }}
                    disabled={!isEditMode}
                    status="Privé"
                    value="visibility_off"
                    type="icon"
                    actionText={tO("eService_pubStates_privateActionText")}
                    description="Le e-service est actuellement privé."
                />
            )}

            {catalogDetails.typePublication === "Publish" && catalogDetails.status !== "Brouillon"  && (
                <PubStates
                    handleClick={() => {
                      setConfirmTypePublication('Private Service');
                      setConfirmTypePublicationModalOpen(true);
                    }}
                    disabled={!isEditMode}
                    status="Public"
                    value="visibility"
                    type="icon"
                    actionText={tO("eService_pubStates_priveActionText")}
                    description="Le e-service est actuellement public."
                />
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {!isEditMode ? (
              <Button label={tO("Editer")} size="md" color="primary" styling={"solid"} onClick={handleEdit}/>
          ) : (
              <>
                <Button label={tO("Annuler")} size="md" color="primary" styling={"outline"} onClick={handleCancel}/>
                <Button label={tO("Enregistrer")} size="md" color="primary" styling={"solid"}
                        onClick={handleSubmit(onSubmit)}/>
              </>
          )}
        </div>
      </div>
    </SimpleCard>

  );
};