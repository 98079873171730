import styles from './signup-steps.module.css';
import SendMessageIcon from '../../../../../shared/assets/icons/send-message.svg';
import classNames from 'classnames';
import {
  InputPin,
  NotificationCard,
  SimpleCard
} from '../../../../../shared/components';
import { FC, useEffect } from 'react';
import { useHandleTranslation, useTimer } from '../../../../../shared/hooks';
import { message } from 'antd';
import { NotificationType } from '../../../../../shared/utils/enums';
import {useNavigate} from "react-router-dom";
import {setComingFromSignupStatus} from "../../../features/auth/authSlice";
import {useDispatch} from "react-redux";

interface SingUpStepTwoApiStatus {
  accountActivation: boolean | undefined;
  resendActivationToken: boolean | undefined;
}
interface SignUpSecondStepProps {
  onSubmit: (activationCode: string) => void;
  isLoading?: SingUpStepTwoApiStatus;
  isError?: SingUpStepTwoApiStatus;
  isSuccess?: SingUpStepTwoApiStatus;
  deadline?: string;
  submitError?: string | undefined;
  resendActivationCode?: () => void;
  className?: string;
}
const SignUpSecondStep: FC<SignUpSecondStepProps> = (props) => {
  const dispatch=useDispatch();
  const handleComingFromSignUpState=()=>{
    dispatch(setComingFromSignupStatus(true))
  }
  const {
    className = '',
    submitError = '',
    deadline,
    isError = { accountActivation: false, resendActivationToken: false },
    isLoading = { accountActivation: false, resendActivationToken: false },
    isSuccess = { accountActivation: false, resendActivationToken: false }
  } = props;
  const { t: tS } = useHandleTranslation('signup');
  const { isRunning: isTimerRunning, minutes, seconds } = useTimer(deadline);
  const navigate=useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const alertKey = 'signup-step-two';
  const containerStyles = classNames({
    [className]: true,
    [styles.secondStepRootContainer]: true
  });
  const submitData = (value: string) => {
    props.onSubmit(value);

  };
  const errorMessageStyles = classNames({
    ['notShow']:
      !props.isError?.accountActivation ||
      props.isLoading?.accountActivation ||
      !isTimerRunning,
    ['show transition-all duration-500']:
      props.isError?.accountActivation && isTimerRunning
  });
  useEffect(() => {
    if (isLoading.resendActivationToken) {
      messageApi.loading({
        key: alertKey,
        content: tS('sign_stepTwo_resendActivationCode_message_loading'),
        duration: 0
      });
    }
  }, [isLoading.resendActivationToken]);

  useEffect(() => {
    if (isError.resendActivationToken) {
      messageApi.error({
        key: alertKey,
        content: tS('sign_stepTwo_resendActivationCode_message_error'),
        duration: 2.5
      });
    }
  }, [isError.resendActivationToken]);

  useEffect(() => {
    if (isError.resendActivationToken) {
      messageApi.error({
        key: alertKey,
        content: tS('sign_stepTwo_resendActivationCode_message_error'),
        duration: 2.5
      });
    }
  }, [isError.resendActivationToken]);

  useEffect(() => {
    if (isSuccess.resendActivationToken) {
      messageApi.success({
        key: alertKey,
        content: tS('sign_stepTwo_resendActivationCode_message_success'),
        duration: 2.5
      });
    }
  }, [isSuccess.resendActivationToken]);

  useEffect(() => {
    if (isSuccess.accountActivation) {
      messageApi
        .success({
          key: alertKey,
          content: tS('sign_stepTwo_accountActivation_alert_success'),
          duration: 2.5
        })
        .then(() => {
         navigate("/authentication",{replace : true});
          handleComingFromSignUpState()
        });
    }
  }, [isSuccess.accountActivation]);

  const expiredTimerStyles = classNames({
    ['grid grid-cols-2 gap-3']: true,
    ['show']: isTimerRunning,
    ['notDisplayV2']: !isTimerRunning
  });
  const expiredAlertStyles = classNames({
    [`show ${styles.secondStepExpiredAlertContainer}`]: !isTimerRunning,
    ['notDisplayV2']: isTimerRunning
  });
  return (
    <SimpleCard className={containerStyles} spin={isLoading.accountActivation}>
      {contextHolder}
      <div className="flex flex-col gap-8 justify-center align-center">
        <div className={'flex justify-center'}>
          <img src={SendMessageIcon} alt="logo" />
        </div>
        <div className={'flex flex-col justify-center gap-4'}>
          <p className={styles.secondStepTitle}>{tS('sign_stepTwo_title')}</p>
          <p className={styles.secondStepDescription}>
            {tS('sign_stepTwo_description')}
          </p>
        </div>
        <div className="w-3/4 m-auto">
          <InputPin
            onSubmit={submitData}
            empty={isError.accountActivation}
            disabled={!isTimerRunning}
            upperCase
          />
        </div>
        <div className="flex flex-col gap-3 ">
          <NotificationCard
            type={NotificationType.ERROR}
            className={errorMessageStyles}>
            {tS(submitError)}
          </NotificationCard>
          <div className=" flex justify-center items-center min-h-9">
            <NotificationCard
              type={NotificationType.WARNING}
              className={expiredAlertStyles}>
              <div className={'flex gap-2'}>
                <span className={styles.secondStepExpiredAlertLabel}>
                  {tS('sign_stepTwo_expiredCode_alert_message')}
                </span>
                <span
                  onClick={props.resendActivationCode}
                  className={styles.secondStepResendActivationLabel}>
                  {tS('sign_stepTwo_resendActivationCode_link_label')}
                </span>
              </div>
            </NotificationCard>
            <div className={expiredTimerStyles}>
              <span className={styles.secondStepExpiredTimerLabel}>
                {tS('sign_stepTwo_timer_label')}
              </span>
              <span className={styles.secondStepTimer}>
                {`${minutes} ${tS('minute_unit_label')} ${seconds} ${tS('second_unit_label')}`}
              </span>
            </div>
          </div>

          <p className={styles.secondStepSpam}>
            {tS('sign_stepTwo_spam_message')}
          </p>
        </div>
      </div>
    </SimpleCard>
  );
};
export default SignUpSecondStep;
