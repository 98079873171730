import { Modal } from "kls-ui";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData, Switcher} from "../../../../shared/components";
import FilterSvg from "../../../../shared/components/icons/FilterSvg";
import TimeSvg from "../../../../shared/components/icons/TimeSVG";
import { RootState } from "../../../../shared/stores";
import { message } from "antd";
import logo from '../../assets/icons/logo-app.svg';
import {useGetEserviceMembersList, useAcceptInvitation, useAnnulationInvitationEservice} from "../../api";
import { Eservice } from "../../components/ui/cards/base-cards/CardEservice";
import { FilterBar } from "../../components/ui/filter-bar/FilterBar";
import { FilterBarContentProps } from "../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../components/ui/filter-bar/FilterBarInput";
import { FilterBarSortByProps } from "../../components/ui/filter-bar/FilterBarSortBy";
import { EserviceMembersListResponse, EserviceMembersListRequest } from "../../types";
import styles from './eservice.param.module.css';
import { Table } from "../../components/ui/table/Table";
import SendSVG from "../../../../shared/components/icons/SendSVG";
import CercleCheckSvg from "../../../../shared/components/icons/CercleCheckSVG";
import VerifiedSvg from "../../../../shared/components/icons/VerifiedSvg";
import { IconSize } from "../../../../shared/utils/enums";


export const RolesAndPermissionsPage = () => {

  const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
  const { eserviceID } = useParams<{ eserviceID: string }>();
  const id = (selectedEservice && (selectedEservice as Eservice).id) ? (selectedEservice as Eservice).id : eserviceID || '';
  const [showTable, setShowTable] = useState<boolean>(true);
  const [selectedRowOption, setSelectedRowOption] = useState<{
    id: string;
    selectedValue: string;
    emailPersonnel: string;
    nameUser: string;
    logoUser: string;
    role: string;
    ownerLogin: string;
    note: string;
    eserviceName?: string;
    roleUserDemanded?: string;
    roleUserApproved?: string;
    eserviceLogo?: string;
    organizationVerification?: string;
    guichetName?: string} | null>(null);
  const [totalNumber,setTotalNumber ] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [requestMembersData, setRequestMembersData] = useState<EserviceMembersListResponse[]>([]);
  const [typeRequest, setTypeRequest] = useState('');
  const [myRequest, ] = useState('');

  const [selectedButton, setSelectedButton] = useState('')
  const [statusRequest, setStatusRequest] = useState('');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [roleFiltre, setRoleFiltre] = useState<string>('');
  const [actifFiltre, setActifFiltre] = useState<string>('');
  const postAcceptInvitation = useAcceptInvitation();
  const postRefuseInvitation = useAnnulationInvitationEservice();
  const [messageApi, contextHolder] = message.useMessage();
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>


  const handleAcceptInvitation= () => {
    if (selectedRowOption?.id ) {
      postAcceptInvitation.mutate({
        demandeJoindreId: selectedRowOption.id,        
      });
    } else {
      console.error('Id is not available');
    }
  }

  useEffect(() => { 
    if (postAcceptInvitation.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Invitation acceptée avec succès !',
      });
      handleCloseJoin(); 

    }
    if (postAcceptInvitation.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postAcceptInvitation.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postAcceptInvitation.status]);

  useEffect(() => {
  
    if (postRefuseInvitation.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Invitation refusée avec succès !',
      });
      handleCloseJoin(); 

    }
    if (postRefuseInvitation.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postRefuseInvitation.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postRefuseInvitation.status]);
  const steps = [
    {
      leftBtn: {
        label: 'Refuser',
        color: 'error',
        styling:'solid',
        onClick: () => { setCurrentStep(steps[2]) },
      },
      rightBtn: {
        label: 'Approuver',
        color: 'success',
        onClick: () => { setCurrentStep(steps[1]) },
      },
      body: (<>
<div className="flex gap-5 items-center">
  <img
    src={selectedRowOption?.logoUser || logo}
    className="w-8 h-8 rounded-full"
  />
  <div>
    <div className={styles.nameUser}>{selectedRowOption?.eserviceName}</div>
    <div className="flex items-center gap-1 text-[13px]">
      <span className={`${styles.emailPersonnel} text-slate-500 text-xs`}>
        propriété de
      </span>
      <span className={styles.nameUser}>
        {selectedRowOption?.guichetName}
      </span>
      <VerifiedSvg
        size={IconSize.PX14}
        className={`${styles.cardTitleSvg} text-emerald-500`}
      />
    </div>
  </div>
</div>


       
        <div className={styles.endBlock}></div>

         <span className={styles.textNote}><span className={styles.textNoteLabel}>Rôle : {selectedRowOption?.roleUserDemanded}</span></span>

         <span className={styles.textNote}>
          <span className={styles.textNoteLabel}>Note : </span>
          {selectedRowOption?.note ? selectedRowOption.note : 'N/A'}
        </span>

      </>
      ),
    },
    {
      leftBtn: {
        
        label: 'Annuler',
        color:'neutral',
        styling: 'outline',

           size: "xs",
        onClick: () => { setCurrentStep(steps[0]) },
      },
      rightBtn: {
        label: 'Valider',
        color: 'primary',
        onClick: handleAcceptInvitation,
      },
      body: (
        <div className={styles.textConfirmation}>
          Êtes-vous sûr de vouloir <span className={styles.textConfirmationAccept}>accepter</span> l&apos;invitation ?
        </div>
      ),
    },
    {
      leftBtn: {
        color:'neutral',
        size: "xs",
        label: 'Annuler',
        styling: 'outline',
        onClick: () => { setCurrentStep(steps[0]) },
      },
      rightBtn: {
        label: 'Valider',
        color: 'primary',
        onClick: () => handleRefuseInvitation(),
      },
      body: (
        <div className={styles.textConfirmation}>
         Êtes-vous sûr de vouloir <span className={styles.textConfirmationRefuse}>refuser</span> l&apos;invitation ?

        </div>
      ),
    },
  ];

  type ButtonColor = 'error' | 'success' | 'neutral' | 'primary';
  const [currentStep, setCurrentStep] = useState(steps[0]);


  const 
  fBmember: FilterBarSortByProps = {
    items: [
      {
        data: [
          { label: 'Administrateur', value: 'ADMINISTRATEUR' },
          { label: 'Citizen developer', value: 'CITIZEN DEVELOPER' }
        ],
        label: 'Rôle',
        placeHolder: 'Rôle',
        selectedValue: roleFiltre,
        clearable: true,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }
          if (e == roleFiltre) return;
          setRequestMembersData([]);
          setRoleFiltre(e);
        }
      },
      ...(selectedButton === 'approuve'
        ? [
            {
              data: [
                { label: 'Actif', value: 'actif' },
                { label: 'Inactif', value: 'inactif' },
              ],
              label: 'Activation',
              placeHolder: 'Activation',
              selectedValue: actifFiltre,
              clearable: true,
              onChange: (e: string) => {
                if (page > 1) {
                  setPage(1);
                }
                if (e == actifFiltre) return;
                setRequestMembersData([]);
                setActifFiltre(e);
              }
            }
          ]
        : [])
    ]
  };

  const [allMembersParams, setAllMembersParams] =
    useState<EserviceMembersListRequest>({
      numPage: 1,
      pageSize: 10,
      key: '',
      idRequest: '',
      idEservice: id,
      typeRequest:'INVITATION',
      statusRequest: statusRequest,
      statusRole: '',
      role: 'ADMINISTRATEUR,CITIZEN DEVELOPER',
      Fields: '',
      myRequest:true
    });

  const getEserviceMembersList = useGetEserviceMembersList(allMembersParams);
  useEffect(() => {
    if (actifFiltre) {
      setResetFilters(true)
    } else {
      setResetFilters(false)
    }
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      typeRequest: typeRequest,
      statusRequest: statusRequest,
      key: searchValue,
      role: 'ADMINISTRATEUR,CITIZEN DEVELOPER',
      statusRole:actifFiltre,
      myRequest:true
    }));

  }, [searchValue, page, typeRequest, statusRequest,actifFiltre, myRequest]);
  useEffect(() => {
    if(roleFiltre)  {
      setResetFilters(true)
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      role:roleFiltre
    }));
    
  }else {
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      role:'ADMINISTRATEUR,CITIZEN DEVELOPER',
    }));
  }
  }, [roleFiltre]);

  const FilterBarContentEservice: FilterBarContentProps = {
    label: 'Mes e-services',
    searchLength: totalNumber,
    buttons: [
      {
        icon: CercleCheckSvg,
        label: 'Approuvés',
        isSelected: selectedButton === 'approuve',
        handleClick: () => {
          if (selectedButton === 'approuve') return;
          if (page > 1) {
            setPage(1);
          }
      
          setRequestMembersData([]); 
          setSelectedButton('approuve'); 
          setTypeRequest('INVITATION'); 
          setStatusRequest('ACCEPT'); 
        }
      
      },
      {
        icon: SendSVG,
        label: 'Invitations',
        isSelected: selectedButton === 'invitations',
        handleClick: () => {
          
            setShowTable(showTable);
          
          if (selectedButton === 'invitations') return;
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSelectedButton('invitations');
          setTypeRequest('INVITATION');
          setStatusRequest('Brouillon');
        }
      },
      {
        icon: TimeSvg,
        label: 'Demandes',
        isSelected: selectedButton === 'demandes',
        handleClick: () => {
          if (selectedButton === 'demandes') return;

          if (page > 1) {
            setPage(1);
          }
        }
      }
    ]
  }

  const FilterBarWindowContent: FilterBarContentProps = {
    label: 'Mes guichets',
    searchLength: 0,
    buttons: [
      {
        icon: CercleCheckSvg,
        label: 'Approuvés',
        isSelected: selectedButton === 'approuveWindow',
        handleClick: () => {
          if (selectedButton === 'approuveWindow') return;
          if (page > 1) {
            setPage(1);
          }      
          setRequestMembersData([]); 
          setSelectedButton('approuveWindow'); 
          setTypeRequest('INVITATION'); 
          setStatusRequest('ACCEPT'); 
        }
      
      },
      {
        icon: SendSVG,
        label: 'Invitations',
        isSelected: selectedButton === 'invitationsWindow',
        handleClick: () => {         
            setShowTable(showTable);          
          if (selectedButton === 'invitationsWindow') return;
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSelectedButton('invitationsWindow');
          setTypeRequest('INVITATION');
          setStatusRequest('Brouillon');
        }
      }
    ]
  }
  const handleCloseJoin = () => {
    setSelectedRowOption(null);
  }

  const handleRefuseInvitation = () => {
    if (selectedRowOption?.id) {
      postRefuseInvitation.mutate({
         demandeJoindreId: selectedRowOption.id,
        typeRequest:'supprimer'
      });
    }
    handleCloseJoin();
    // setSelectedRowOption(null);
  }
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters,
      reset: resetFilters,
      resetClick: () => {
       setActifFiltre('');
       setRoleFiltre('');
        setResetFilters(false);
      }
    },

    isGridDisplayMode: false,
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]); 
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]);
      setSearchValue(e);
    },
  };
  
  useEffect(() => {
    if (selectedRowOption) {
      setCurrentStep(steps[0]);
    }

  }, [selectedRowOption]);


  useEffect(() => {
    if (getEserviceMembersList.isSuccess) {
      const membersListData = getEserviceMembersList.data;
      const totalItems = getEserviceMembersList.data.body.pagination.totalLength;
      if (membersListData) {      
        if (typeRequest === "INVITATION" && totalItems !== undefined && statusRequest ==="Brouillon" ||statusRequest ==="ACCEPT"  ) {
          setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
          
          setRequestMembersData(membersListData.body.data);

        }
        setIsFetching(false);

      }
      return;
    }

  }, [getEserviceMembersList.fetchStatus])

  const handleTabsSelect = (optionName: string) => {
    setSelectedTab(optionName); 
    if (optionName === 'services') {
        setShowTable(!showTable); 
    } else {
      setShowTable(false);  
    }
  };



  const options=[
    {label:"Guichets",value:"guichets"},
    {label: "e-Services",value:"services"}
  ]
  const [selectedTab, setSelectedTab] = useState("services"); 
  useEffect(() => {
    if (selectedTab === "services") {
      setSelectedButton('invitations');
      setStatusRequest('Brouillon');
      setTypeRequest('INVITATION');
    }
  }, [selectedTab]);

  return (
  <div className="mt-5">
    <div className="flex justify-center items-center w-full">
      <Switcher 
          options={options} 
          onOptionSelect={handleTabsSelect} 
          defaultValue="services" />
    </div>
    {contextHolder}
     
      {selectedTab === "services" && (
        <div className={styles.topBar}> 
          <div className="mb-8"> 
            <FilterBar
              filterBarContent={FilterBarContentEservice}
              filterBarInput={fBi}
              filterBarSortBy={fBmember}
            />
    </div>
  </div>
)}
  {selectedTab === "guichets" && (
        <div className={styles.topBar}> 
          <div className="mb-8"> 
            <FilterBar
              filterBarContent={FilterBarWindowContent}
              filterBarInput={fBi}
              filterBarSortBy={fBmember}
            />
    </div>
  </div>
)}

    {requestMembersData.length < 1 ? (
      <EmptyData
        icon={emptyIcon}
        title={"Aucune invitation pour l'instant"}
        description={"La liste des invitations sera affichée ici"}
      />
    ) : (
      <>
        { (
          <>
          {selectedTab === "guichets" && selectedButton === "invitations" && (
              <Table
                isFitching={isFetching}
                type='receivedWindowInvitation'
                setCurrentPage={(page) => setPage(page)}
                currentPage={page}
                totalItems={totalNumber}
                onSelectDropDownForMembers={setSelectedRowOption}
                headerItems={{
                  items: ['e-Service', ' ', 'Rôle en attente', ' ']
                }}
                rows={requestMembersData} 
              />
            )}

            {showTable && statusRequest ==="Brouillon" &&(
              <Table
                isFitching={isFetching}
                type='receivedInvitation'
                setCurrentPage={(page) => setPage(page)}
                currentPage={page}
                totalItems={totalNumber}
                onSelectDropDownForMembers={setSelectedRowOption}
                headerItems={{
                  items: ['e-Service', ' ', 'Rôle en attente', ' ']
                }}
                rows={requestMembersData} 
              />
            )}
   {showTable && statusRequest ==="ACCEPT" &&
          <Table
            isFitching={isFetching}
            type="acceptedInvitation"
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalNumber}
            onSelectDropDownForMembers={setSelectedRowOption}
            headerItems={{
              items: ['e-Service', ' ', 'Rôle', 'Activation']
            }}
            rows={requestMembersData}
          />}
          </>
        )}
      </>
    )}

    {selectedRowOption && selectedRowOption?.selectedValue === 'traiter' && (
      <Modal onClose={handleCloseJoin} title={"Traitement invitation"} 
        leftBtnArgs={{
          label: currentStep.leftBtn.label,
          args: { onClick: currentStep.leftBtn.onClick },
          color: currentStep.leftBtn.color as ButtonColor,
          size: 'xs',
          styling: currentStep.leftBtn.styling === 'solid' ? 'solid' : 'outline',
        }}
        rightBtnArgs={{
          label: currentStep.rightBtn.label,
          args: { onClick: currentStep.rightBtn.onClick },
          color: currentStep.rightBtn.color as ButtonColor,
          size: 'xs',
          styling: 'solid'
        }}
        icon={""}
      >
        <div className="flex flex-col p-6 gap-5">
          {currentStep.body}
        </div>
      </Modal>
 
    )}
  </div>
);}