// src/features/guichet/guichetSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllGuichetsRequest } from '../../types';
import { Guichet } from '../../components/ui/cards/base-cards/CardGuichet';

const storedGuichet = localStorage.getItem("selectedGuichet");
const initialSelectedGuichet:Guichet = storedGuichet
  ? JSON.parse(storedGuichet)
  : {}; 
interface GuichetsState {
  selectedGuichet: Guichet | object;
  favorite: boolean;
  contribute: boolean;
  showFilters: boolean;
  showGrid: boolean;
  showTable: boolean;
  showMap: boolean;
  totalItems: number;
  sortBy: { value: string; label: string };
  searchValue: string;
  domaine: string;
  verificationGuichet: string;
  role: string;
  statutRole: string;
  page: number;
  isFetching: boolean;
  guichetsParams: AllGuichetsRequest;
  selectedCardOption: { id: string; selectedValue: string } | null;
}

const initialState: GuichetsState = {
  selectedGuichet: initialSelectedGuichet,
  favorite: false,
  contribute: false,
  showFilters: false,
  showGrid: true,
  showTable: false,
  showMap: false,
  totalItems: 0,
  sortBy: { value: 'recent', label: 'Récent' },
  searchValue: '',
  domaine: '',
  verificationGuichet: '',
  role: '',
  statutRole: '',
  page: 1,
  isFetching: false,
  guichetsParams: {
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    typeGuichet:"",
    organisme:"",
    reference: '',
    domainName: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
    objectType:""
  },
  selectedCardOption: null
};

const guichetsSlice = createSlice({
  name: 'guichet',
  initialState,
  reducers: {
    setSelectedGuichet(state, action: PayloadAction<Guichet>) {
      state.selectedGuichet = action.payload;
      try {
        localStorage.setItem("selectedGuichet", JSON.stringify(action.payload));
      } catch (e) {
        console.error("Error saving selectedGuichet to localStorage:", e);
      }
    },
    toggleFavorite(state) {
      state.favorite = !state.favorite;
    },
    toggleContribute(state) {
      state.contribute = !state.contribute;
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    setSortBy(state, action: PayloadAction<{ value: string; label: string }>) {
      state.sortBy = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setGuichetsParams(
      state,
      action: PayloadAction<Partial<AllGuichetsRequest>>
    ) {
      state.guichetsParams = { ...state.guichetsParams, ...action.payload };
    },
    setSelectedCardOption(
      state,
      action: PayloadAction<{ id: string; selectedValue: string } | null>
    ) {
      state.selectedCardOption = action.payload;
    },
    toggleShowGrid(state) {
      state.showGrid = !state.showGrid;
      state.showTable = false;
      state.showMap = false;
    },
    toggleShowTable(state) {
      state.showTable = !state.showTable;
      state.showGrid = false;
      state.showMap = false;
    },
    toggleShowMap(state) {
      state.showMap = !state.showMap;
      state.showGrid = false;
      state.showTable = false;
    }
  }
});

export const {
  setSelectedGuichet,
  toggleFavorite,
  toggleContribute,
  setSearchValue,
  setSortBy,
  setPage,
  setIsFetching,
  setGuichetsParams,
  setSelectedCardOption,
  toggleShowGrid,
  toggleShowTable,
  toggleShowMap
} = guichetsSlice.actions;
export default guichetsSlice.reducer;
