import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { UserSettingPage } from '../pages/Setting/UserSettingPage';

const ProfilSettingsRoutes: React.FC = () => {
  return (
    <Routes>
      {/* <Route index element={<UserSettingPage />} /> */}
      <Route path="Settings" element={<UserSettingPage />} />
 
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default ProfilSettingsRoutes;
