import { MaterialIcon } from "../../../../shared/components/icons";
import { ListItemType } from "../../../../shared/types";
import { Guichet } from "../../components/ui/cards/base-cards/CardGuichet";

export const formatGuichetToListItem = (guichets: Guichet[]): ListItemType[] => {
  return guichets.map((guichet) => ({
    label: guichet.name,
    value: guichet.id,
    icon: <MaterialIcon label="domain" className="text-blue-600 text-base" />,
  }));
};
