import {useMutation, useQuery} from "@tanstack/react-query";
import {useHandleGenericResponse} from "../../../../shared/hooks";
import {
    cancelJoiningOrganization,
    getAllOrganizations,
    getCheckVerificationOrganizationStatus,
    getOrganizationByWindow,
    getWindowOrganizationAssociationRequest,
    getWindowOrganizationStatus, postAcceptJoinOrganizationRequest,
    postCreateOrganization, postRejectJoinOrganizationRequest,
    requestAssociateOrganization
} from "./api";
import {
    AllGuichetsRequest,
    IdentityCreateOrganizationRequest,
    AssociateOrganizationRequest,
    WindowOrganizationRequestsRequest
} from "../../types";


export const usePostCreateOrganization=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (data:IdentityCreateOrganizationRequest) => postCreateOrganization(data),
    });
    const customHandler = useHandleGenericResponse(mutationQuery);

    return { ...mutationQuery,customHandler };
}
export const useRequestAssociateOrganization=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (data:AssociateOrganizationRequest) => requestAssociateOrganization(data),
    });
    const customHandler = useHandleGenericResponse(mutationQuery);
    return { ...mutationQuery,customHandler };
}
export const useGetAllOrganizations = (data: AllGuichetsRequest) => {
    const queryKey = ['all-organizations', JSON.stringify(data)];
    const reactQuery = useQuery({
        queryKey,
        queryFn: () => getAllOrganizations(data),
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery, customHandler };
};

export const  useGetOrganizationByWindow=(data:{guichetId:string|undefined})=>{
    const queryKey = ['organization-by-window',data.guichetId];
    const reactQuery = useQuery({
        queryKey,
        queryFn: () => getOrganizationByWindow(data),
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery, customHandler };
}
export const  useGetCheckWindowOrganizationStatus=(data:{guichetId:string|undefined})=>{
    const queryKey = ['organization-window-status',data.guichetId];
    const reactQuery = useQuery({
        queryKey,
        queryFn: () => getWindowOrganizationStatus(data),
        staleTime:0
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery, customHandler };
}
export const useCancelJoiningOrganization=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (data:{guichetId:string,organisationId:string,joindemandeId:string}) => cancelJoiningOrganization(data),
    });
    const customHandler = useHandleGenericResponse(mutationQuery);
    return { ...mutationQuery,customHandler };
}
export const  useGetWindowOrganizationAssociationRequests=(data:WindowOrganizationRequestsRequest)=>{
    const queryKey = ['window-organization-requests',data];
    const reactQuery = useQuery({
        queryKey,
        queryFn: () => getWindowOrganizationAssociationRequest(data),
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery, customHandler };
}
export const useGetCheckVerificationOrganizationStatus=(guichetId:string)=>{
    const queryKey= ['check-verification-organization-status',guichetId];
    const reactQuery = useQuery({
        queryKey,
        queryFn: () => getCheckVerificationOrganizationStatus(guichetId),
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery, customHandler };
}
export const usePostAcceptOrganizationRequest=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (requestId:string) => postAcceptJoinOrganizationRequest(requestId),
    });
    const customHandler = useHandleGenericResponse(mutationQuery);
    return { ...mutationQuery,customHandler };
}
export const usePostRejectOrganizationRequest=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (requestId:string) => postRejectJoinOrganizationRequest(requestId),
    });
    const customHandler = useHandleGenericResponse(mutationQuery);
    return { ...mutationQuery,customHandler };
}