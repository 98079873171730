
import styles from './landing.module.css'

export const VideoComponent =()=>{

    return <>
    <div className="flex items-center p-20 gap-7 dark:bg-slate-800">
    <span className={styles.textVideo}>
    Ne soyez plus perdus dans la multitude des sites web de services administratifs, Karaz sera désormais votre point de départ unique pour toutes vos démarches digitales.
    </span>
    <video className={styles.video} controls src=''>
    </video>
    </div>
    </>
}