import { FC, useCallback } from 'react';
import { HeaderTable, HeaderTableProps } from '../table-items/HeaderTable.tsx';
import { RowTable } from '../table-items/RowTable.tsx';
import styles from './table.module.css';
import { Pagination } from 'antd';
import { CARD_DROPDOWN_ADMIN, CARD_DROPDOWN_ALL, CARD_DROPDOWN_CITIZEN, CARD_DROPDOWN_CONTRIBUTOR_AD_CD, CARD_DROPDOWN_NO_ROLE} from '../../../utils/constants/components.constants.tsx';
import { DynamicListData } from '../../../../../shared/types/data.type.ts';
import { RowSkeleton } from '../table-items/RowSkeleton.tsx';
import { Guichet } from '../cards/base-cards/CardGuichet.tsx';
import { Eservice } from '../cards/base-cards/CardEservice.tsx';
import { Administrator } from '../cards/base-cards/CardAdministrators.tsx';
import { RowTableMember } from '../table-items/RowTableMember.tsx';
import { EserviceMembersListResponse, FavoriteEserviceRequest, FavoriteGuichetRequest, ListRequestJoinEserviceData, WindowInvitationListResponse } from '../../../types/index.ts';
import { RowTableSelectable } from '../table-items/RowTableSelectable.tsx';
import { RowTableEservice } from '../table-items/RowTableEservice.tsx';
import { RowTableEserviceUsager } from '../table-items/RowTableEserviceUsager.tsx';
import { useHandleDomLocation } from '../../../hooks/index.ts';
import { RowTableInvitation } from '../table-items/RowTableInvitation.tsx';
import { RowTableWindowInvitation } from '../table-items/RowTableWindowInvitation.tsx';
import { RowTableAdministratorInvitation } from '../table-items/RowTableAdministratorInvitation.tsx';
import { RowTableOrg } from '../table-items/RowTableOrg.tsx';
export interface TableProps {
  headerItems: HeaderTableProps;
  rows: Guichet[] | Eservice[] | ListRequestJoinEserviceData[] | EserviceMembersListResponse[] | Administrator[]| WindowInvitationListResponse[];
  totalItems: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
  onSelectDropDownForMembers?: (selection: {
    id: string,
    selectedValue: string,
    emailPersonnel: string,
    nameUser: string,
    logoUser: string,
    role: string,
    ownerLogin: string,
    note: string,
    roleUserApproved?: string;
    eserviceName?: string;
    eserviceLogo?:string;
    organizationVerification?:string;
    guichetName?:string;
  }) => void;
  onSelectDropDownForRequest?: (selection: {
    id: string;
    selectedValue: string;
    ownerlogin: string;
    role: string;
    idEservice: string;
  }) => void;
  onSelectDropDownForWindow?: (selection: {
    id: string,
    selectedValue: string,
    emailPersonnel: string,
    nameUser: string,
    logoUser: string,
    role: string,
    loginUser: string,
  }) => void;
  setFavoriteEservice?: (params: FavoriteEserviceRequest) => void;
  type: 'eservice' | 'guichet' | 'eserviceUsager' | 'memberApprouve' | 'memberNonApprouve' | 'contributeurNonApprouve' | 'contributeurApprouve' | 'users' | 'selectable' | 'administrateus' | 'invitationNonApprouve'|'receivedInvitation'
  |'acceptedInvitation' |'receivedWindowInvitation'|'WindowInvitation' | 'organisme' ;
  isFitching?: boolean | false;
  dataDomaine?: DynamicListData[] | undefined;
  selectedItems?: Array<Eservice>;
  setSelectedItems?: (items: Array<Eservice>) => void;
}
export const Table: FC<TableProps> = (props) => {
  const { profilePathName, isGuichetPage } = useHandleDomLocation()

  const getDropDownData = useCallback((role: string[] | string | undefined) => {
    if (profilePathName === 'utilisateur') return CARD_DROPDOWN_ALL;
    if (!role && isGuichetPage) return CARD_DROPDOWN_ALL;
    else if (!role && !isGuichetPage) {
      return CARD_DROPDOWN_NO_ROLE;
    }

    if (Array.isArray(role)) {
      if (role.includes('Contributeur') && role.includes('Citizen developer')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      if (role.includes('Contributeur') && role.includes('Administrateur')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }

      if (role.includes('Administrateur')) {
        return CARD_DROPDOWN_ADMIN;
      }

      if (role.includes('Citizen developer')) {
        return CARD_DROPDOWN_CITIZEN;
      }
    }

    return CARD_DROPDOWN_ALL;
  }, []);

  const handleSelectItem = (item: Eservice, isSelected: boolean) => {
    if (props.selectedItems && props.setSelectedItems) {
      const updatedSelection = isSelected
        ? [...props.selectedItems, item]
        : props.selectedItems.filter((selected) => selected.id !== item.id);

      props.setSelectedItems(updatedSelection);
    }
  };
  return (
    <>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.header}>
            <HeaderTable items={props.headerItems.items} />
          </thead>
          <tbody className={styles.body}>
            {!props.isFitching
              ? props.rows.map(
                (
                  row: Guichet | Eservice | ListRequestJoinEserviceData | EserviceMembersListResponse | Administrator| WindowInvitationListResponse,
                  index: number
                ) => {
                  if ('role' in row && props.type === 'guichet') {
                    return (
                      <RowTable
                        item={row as Guichet}
                        onSelectDropDown={props.onSelectDropDown}
                        setFavoriteParams={props.setFavoriteParams}
                        key={index}
                        icon={
                          props.dataDomaine &&
                            (row as Guichet).industry &&
                            props.dataDomaine.filter(
                              (domaine) =>
                                domaine.label === (row as Guichet).industry
                            )[0]?.icon
                            ? props.dataDomaine.filter(
                              (domaine) =>
                                domaine.label === (row as Guichet).industry
                            )[0].icon
                            : undefined
                        }
                        dropDownData={getDropDownData(row.role)}
                      />
                    );
                    
                  } else if (props.type === 'eservice') {
                    return (
                      <RowTableEservice
                        item={row as Eservice}
                        setFavoriteEservice={props.setFavoriteEservice}
                        key={index}
                        onSelectDropDown={props.onSelectDropDown}
                        dropDownData={getDropDownData((row as Eservice).role)}
                      />
                    );
                  } else if (props.type === 'eserviceUsager') {
                    return (
                      <RowTableEserviceUsager
                        item={row as Eservice}
                        setFavoriteEservice={props.setFavoriteEservice}
                        key={index}
                        onSelectDropDown={props.onSelectDropDown}
                        dropDownData={getDropDownData((row as Eservice).role)}
                      />
                    );
                  }
                  else if (props.type === 'organisme') {
                    return (
                      <RowTableOrg
                      item={row as Guichet}
                      onSelectDropDown={props.onSelectDropDown}
                      setFavoriteParams={props.setFavoriteParams}
                      key={index}
                      dropDownData={[]}
                      />
                    );}

                  else if (props.type === 'selectable' && props.selectedItems && 'id' in row) {
                    return (
                      <RowTableSelectable
                        key={index}
                        item={row as Eservice}
                        isSelected={props.selectedItems.some((selected) => selected.id === row.id)}
                        onSelect={handleSelectItem}
                      />
                    );

                  }
                  
                  else if (props.type === 'receivedInvitation' || props.type === 'acceptedInvitation') {  
                    return (
                      <RowTableInvitation
                        key={index}
                        item={row as EserviceMembersListResponse}
                        onSelectDropDown={props.onSelectDropDownForMembers}
                        type={props.type}
                      />
                    );
                  }      
                  else if (props.type === 'receivedWindowInvitation') {  
                    return (
                      <RowTableWindowInvitation
                        key={index}
                        item={row as EserviceMembersListResponse}
                        type={props.type}
                      />
                    );
                  }             
                  else if (props.type === 'memberApprouve' || props.type === 'memberNonApprouve' || props.type === 'contributeurNonApprouve' || props.type === 'contributeurApprouve' || props.type === 'users' || props.type === 'invitationNonApprouve') {
                    return (
                      <RowTableMember
                        item={row as EserviceMembersListResponse}
                        key={index}
                        onSelectDropDown={props.onSelectDropDownForMembers}
                        type={props.type} />
                    );
                  }
  
                  else if (props.type === 'WindowInvitation') {
                    return (
                      <RowTableAdministratorInvitation
                        item={row as WindowInvitationListResponse}
                        key={index}
                        onSelectDropDown={props.onSelectDropDownForWindow}
                        type={props.type}
                      />
                    );
                  }
                  return;
                }
              )
              : Array.from({ length: 6 }).map((_, index) => (
                <RowSkeleton key={index} />
              ))}
          </tbody>
        </table>
      </div>
      {props.totalItems > 10 && <Pagination
        onChange={(page: number) => {
          props.setCurrentPage(page);
        }}
        showSizeChanger={false}
        pageSize={10}
        total={props.totalItems}
        current={props.currentPage}
      />}
    </>
  );
};
