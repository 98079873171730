import classNames from 'classnames';
import React from 'react';
import styles from '../../../window-marketplace.module.css';
interface EserviceStepProps {
  imageURL: string; label: string; description: string 
}

const EserviceStep: React.FC<EserviceStepProps> = ({
  imageURL,
  label,
  description
}) => {
    const windowMarketplaceContainerStyle = classNames({
        [styles.windowMarketplaceContainer]: true,
        [styles['portal-window-marketplace-css-var']]: true,
      });
  return (
    <div className={windowMarketplaceContainerStyle}>
      <h4 className="text-xl font-semibold text-gray-800 dark:text-slate-50 text-center">
        {label}
      </h4>
      <p className="text-base text-gray-500 dark:text-slate-50 text-center">{description}</p>
      <img src={imageURL} className="rounded-md w-full h-60"></img>
    </div>
  );
};

export default EserviceStep;
