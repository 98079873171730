import React from 'react';
import { MaterialIcon } from '../../../../../../shared/components/icons';
import { EserviceData } from '../../../../types';

interface ServiceCardProps {
  eserviceMarketPlace: EserviceData;
  onClick?: (selectedItem: string) => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  eserviceMarketPlace,
  onClick
}) => {
  return (
    <div className="bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-700 shadow-sm p-2.5 gap-2.5 flex flex-col items-center">
      <div className="w-full h-fit bg-slate-50 dark:bg-slate-950 gap-2.5 px-7 py-10 flex flex-col items-center  justify-center rounded-xl">
        <div className="w-10 h-10 bg-cyan-800 text-xl text-white rounded-full flex items-center justify-center">
        <img src={eserviceMarketPlace.iconEservice} className="w-6 h-6"></img>
        </div>
        <span className="text-sm w-full font-medium truncate text-slate-950 dark:text-white text-center">
          {eserviceMarketPlace.nameEservice}
        </span>
      </div>
      <div className="w-full flex gap-2 justify-start items-center px-3.5">
        <img
          src="/pictures/example-air-royale.png"
          className="w-6 h-6 rounded-full"
        />
        <p className="text-xs text-slate-950 dark:text-white font-medium text-center">
          {eserviceMarketPlace.guichetName}
        </p>
        {eserviceMarketPlace.org_verification && (
          <MaterialIcon label="verified" className="text-emerald-500" />
        )}
      </div>
      <div className="w-full border-b border-slate-200 dark:border-slate-700" />
      <div className="w-full flex justify-between items-center p-2">
        <p className="text-xs font-semibold text-slate-950 dark:text-white">
          {eserviceMarketPlace.servicePricingStatus == 'Gratuit'
            ? 'Gratuit'
            : 'servicePrice' in eserviceMarketPlace
              ? eserviceMarketPlace.servicePrice
              : 'Payant'}
        </p>
        {onClick && (
          <span
            onClick={() => onClick(eserviceMarketPlace.idEservice)}
            className="text-blue-600 cursor-pointer text-xs flex gap-1 items-center">
            Voir plus{' '}
            <MaterialIcon className="text-base" label="chevron_right" />
          </span>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
