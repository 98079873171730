import styles from './landing.module.css';
import facebookLogo from '../../../../shared/assets/icons/facebook.svg';
import linkedinLogo from '../../../../shared/assets/icons/linkedin.svg';
import instagramLogo from '../../../../shared/assets/icons/instagram.svg';


export const FooterComponent = () => {
    return (
      <footer className='flex flex-col bg-[#0A76B0]'>
        <div className={styles.footer} >
        <div className={styles.footerLeft}>
          <img src="/pictures/ksw-white.svg" alt="Logo" className={styles.footerLogo} />
          
        </div>
  
        <div className={styles.footerLinks}>
          <div className={styles.footerColumn}>
            <h4>Karaz</h4>
            <a href="">Accès en mode Démo</a>
            <a href="">Qui est concerné ?</a>
            <a href="">Qui sommes nous ?</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Services</h4>
            <a href="">Catalogue e-services</a>
            <a href="">Recherche guichets</a>
            <a href="">Carte interactive </a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Ressources
            </h4>
            <a href="">Actualités</a>
            <a href="">Podcast Karib</a>
            <a href="">Communauté Karaz</a>
            <a href="">Assistance</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Espace partenaires</h4>
            <a href="">Devenez partenaire </a>
            <a href="">Offres Karaz Pro </a>
            <a href="">Karaz Academy</a>
            <a href="">Questions fréquentes   </a>
          </div>
        </div>
        </div>
  
          <div className='flex place-content-between  p-4'>
          <p className={styles.footerMadeby}>&copy;2024, Made with <span className='text-red-500'>&#10084;</span> by Ribatis
          </p>
          <div className={styles.footerSocial}>

        <a href="https://www.linkedin.com/company/ribatis" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <img src={linkedinLogo}/>

          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img src={facebookLogo}/>
          </a>
          <a href="https://www.instagram.com/_ribatis/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img src={instagramLogo}/>
          </a>
          </div>
        </div>
        
      </footer>
    );
  };
  
export default FooterComponent;