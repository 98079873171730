import { Carousel } from "antd";
import { Button } from "../../../../shared/components";
import { ESERVICEs_MOCK_DATA } from "../../../portal/mockdata/MockDataEservices";
import styles from './landing.module.css'
import { CardEservice } from "../../../portal/components/ui";

export const ServiceDigital = ()=>{

    return(<div className={`${styles.serviceContainer} flex flex-col items-center gap-7`}>
        <span className={styles.serviceTitle}>Services Digitaux en Vedette</span>
        <span className={styles.serviceText}>Accédez aux services Digitaux les plus demandées et simplifiez votre transition numérique  </span>
        <div  className="w-10/12">
        <Carousel dots={{className:styles.dotsCarousel}} >
            {ESERVICEs_MOCK_DATA.map((eservice, idx) => (
        <div className={styles.carouselContainer} key={idx} >
          {eservice.map((item, index) => (
            <CardEservice
              key={item.id || index} 
              eservice={item}
              cardAction={{ onSelectDropDown: () => {} }}
            />
          ))}
        </div>
      ))}
                        </Carousel>
                        </div>
        <Button size={'lg'} color={"secondary"} styling={"solid"} label="Explorez le référentiel e-services" />
            
    </div>);
}