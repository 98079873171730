import { Input } from "kls-ui";
import { AdvancedUploader, InputText, NotificationCard, SimpleModal } from "../../../../../../shared/components";
import { Spin } from "antd";
import { ChangeEvent, FC, useState } from "react";
import { useCheckFile, useCheckForDataConsistency } from "../../../../api";
import { MAX_CARACTER_NAME } from "../../../../utils/constants/helpers.constants";
import { NotificationType } from "../../../../../../shared/utils/enums";
import { cleanAndFormatNameResource } from "../../../../utils/helpers/validators.helper";
import { MessageInstance } from "antd/es/message/interface";
import { useParams } from "react-router-dom";
import { DocumentCard } from "../../../../../../shared/components/upload/upload-cards/DocumentCard";

interface EserviceExcelCreationModalProps {
  guichetName: string;
  messageApi: MessageInstance;
  isOpen: boolean;
  onCancel: () => void;
}
export const EserviceExcelCreationModal: FC<EserviceExcelCreationModalProps> = (props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileWarning, setFileWarning] = useState<{ isError: boolean; message: string | null, type?: NotificationType | null }>({
    isError: false,
    message: null,
    type: NotificationType.ERROR
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: checkFile } = useCheckFile();
  const { mutateAsync: checkDataConsistency } = useCheckForDataConsistency();
  const [errorMessageCrea, setErrorMessageCrea] = useState('');
  const [nomService, setNomService] = useState('');
  const { guichetId } = useParams<{ guichetId: string }>();

  const handleDelete = () => {
    setSelectedFile(null);
  }

  const handleExcelCreation = () => {
    if (!selectedFile) {
      setFileWarning({ isError: true, message: 'Veuillez importer un fichier Excel', type: NotificationType.ERROR });
    }

    if (!nomService) {
      setErrorMessageCrea("Ce champ est requis");
      return;
    }

    if (!fileWarning.isError && !errorMessageCrea && selectedFile) {
      const location = {
        pathname: `/studio/${guichetId}/editExcel/${nomService}/${selectedFile.name}`,
      };
      window.open(location.pathname, '_blank');
      handleCancel();
    }
  };

  const handleOpenFile = () => {
    const fileUrl = URL.createObjectURL(selectedFile!);
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setNomService('');
    setErrorMessageCrea('');
    setIsLoading(false)
    setIsLoading(false)
    setFileWarning({ isError: false, message: '' })
    props.onCancel();
  }

  const uploadFile = async (file: File) => {
    setIsLoading(true)
    setFileWarning({ isError: false, message: null, });
    if (file.name.endsWith('.csv') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
      await checkFile({file:file,guichetId:guichetId!}).then(async (result) => {
        if (result.isValidFile) {
          const dt_check = checkDataConsistency({fileName:file.name,guichetId:guichetId!});
          console.log("DATA TYPE CHECK: ", dt_check);
          setSelectedFile(file);
        }
        else if( result.csvDelimitedError ) setFileWarning({ isError: true, message: `L'entête de votre fichier CSV doit contenir un seul délimiteur, soit une virgule (",") soit un point-virgule (";").`, type: NotificationType.ERROR });
        else if ( result.graphs_found ) setFileWarning({ isError: true, message: 'Veuillez importer un fichier Excel valide sans graphiques', type: NotificationType.ERROR });
        else if ( result.merged_cells_found ) setFileWarning({ isError: true, message: 'Veuillez importer un fichier Excel valide sans cellules fusionnées', type: NotificationType.ERROR });
        else if ( !result.header_found ) setFileWarning({ isError: true, message: `Veuillez importer un fichier Excel valide avec un en-tête sur la première ligne et sans cellules vides.`, type: NotificationType.ERROR });
        setIsLoading(false);
      }).catch(() => {
        setSelectedFile(null);
        setFileWarning({ isError: true, message: 'Veuillez importer un fichier Excel valide', type: NotificationType.ERROR });
        setIsLoading(false);
      })
      return;
    }
    setSelectedFile(null);
    setFileWarning({ isError: true, message: 'Veuillez importez un fichier Excel', type: NotificationType.ERROR });
    setIsLoading(false);
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrorMessageCrea('');
    const value = e.target.value;
    if (value?.length <= MAX_CARACTER_NAME) {
      setNomService(value);
    }
  }

  return (
    <SimpleModal
      type="primary"
      cancelLabel="Annuler"
      validateLabel="Ajouter"
      isOpen={props.isOpen}
      onValidate={handleExcelCreation}
      onCancel={handleCancel}
      afterClose={handleCancel}
      title={"Application à partir d'Excel"}
    >
      <div className="flex flex-col gap-3 px-5 py-3 min-w-[450px]">
        {fileWarning.isError && <NotificationCard
          type={fileWarning.type ?? NotificationType.ERROR}>
          {fileWarning.message}
        </NotificationCard>
        }
        <Input
          errorMessage={errorMessageCrea}
          size="sm"
          label="Nom de e-service"
          helpText={`ID :${cleanAndFormatNameResource(nomService)}`}
          args={{
            placeholder: "Entrez le nom de votre e-service",
            value: nomService,
            onChange: handleNameChange
          }}
          charCounter={MAX_CARACTER_NAME}
        />
        <InputText
          label="Guichet"
          size="sm"
          value={props.guichetName}
          disabled
        />
        {
          !selectedFile
            ? <Spin spinning={isLoading} size="large">
              <AdvancedUploader title="Glissez et déposez un fichier de feuille ici"
                description="Formats supportés : xls, csv, xlsx"
                accept="application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onUploadFiles={(files) => { uploadFile(files[0]) }}
              />
            </Spin>
            : <DocumentCard
              title={selectedFile.name}
              size={`${(selectedFile.size / 1024).toFixed(2)}Kb`}
              onDownLoadClick={handleOpenFile}
              onRemoveClick={() => handleDelete()}
            />
        }
      </div>
    </SimpleModal>
  );
}