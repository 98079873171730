import { Eservice } from "../../components/ui/cards/base-cards/CardEservice";
import { EserviceData } from "../../types";


export const convertToEserviceCard = (eservices: EserviceData[] | undefined): Eservice[] => {
  if (!eservices) return [];
  return eservices.map(eservice => ({
    id: eservice.idEservice,
    name: eservice.nameEservice,
    industry: eservice.Industrie, 
    role:  eservice.roles?.map(r => r.role),
    status: eservice.status,
    verification: eservice.org_verification || "",
    favorite: eservice.favorite || false,
    imageUrl: eservice.imageEservice,
    guichetName: eservice.guichetName,
    dateModif: eservice.changeTime,
    isKaraz: eservice.sourceCreation,
    isPublic: eservice.typePublication || "",
    guichetId:eservice.idGuichet,
    moduleName:eservice.moduleName,
    isRaccourci:eservice.raccourci,
    urlWeb:eservice.urlWeb,
  }));
};
