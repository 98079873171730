import { SidebarMenuItemProps } from './SidebarMenuItem';
import { TopMenuItemProps } from './TopMenuItem';
import { SwitcherItemProps } from './SwitcherItem';
import { useHandleTranslation } from '../../../../shared/hooks';
import { RoleGuichet } from '../../../../shared/permissions/role.types';
import { hasPermission } from '../../../../shared/utils/helpers/permission.helper';


export const useMenuItemsWithPermissions = (
  selectedRoute: string,
  organisationId: string,
  roles: RoleGuichet[],
  typeSelectedGuichet:string
): SidebarMenuItemProps[] => {
  const { t: tSm } = useHandleTranslation("menu");
  const currentUrl = window.location.href;

  const allMenuItems: {
    key: string;
    category: "menus";
    typeGuichet:string[];
    item: SidebarMenuItemProps;
  }[] = [
    {
      key: "dashboard",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.dashboard"),
        materialIconLabel: "dashboard",
        link: currentUrl,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "identity",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.identity"),
        materialIconLabel: "verified",
        link: `${selectedRoute}/${organisationId}/identity`,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "eservices",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.eservices"),
        materialIconLabel: "apps",
        link: `${selectedRoute}/${organisationId}/eservices`,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "offre",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.offre"),
        materialIconLabel: "local_grocery_store",
        link: currentUrl,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "parametres",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.parametres"),
        materialIconLabel: "tune",
        link: currentUrl,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "monitoring",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.monitoring"),
        materialIconLabel: "multiline_chart",
        link: currentUrl,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "securite",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.securite"),
        materialIconLabel: "security",
        link: currentUrl,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "administrateurs",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.administrateurs"),
        materialIconLabel: "manage_accounts",
        link: `${selectedRoute}/${organisationId}/administrateurs`,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "membres",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.membres"),
        materialIconLabel: "group",
        link: currentUrl,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "developer_zone",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.developer_zone"),
        materialIconLabel: "code",
        link: `${selectedRoute}/${organisationId}/devzone`,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "market_place",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.market_place"),
        materialIconLabel: "interests",
        link: `${selectedRoute}/${organisationId}/marketplace`,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "communaute",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.communaute"),
        materialIconLabel: "person_search",
        link: `${selectedRoute}/${organisationId}/communaute`,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "client",
      category: "menus",
      typeGuichet:['e-Shop'],
      item: {
        label: tSm("menu.client"),
        materialIconLabel: "group",
        link: currentUrl,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "assistance",
      category: "menus",
      typeGuichet:['Guichet','e-Shop'],
      item: {
        label: tSm("menu.assistance"),
        materialIconLabel: "support_agent",
        link: currentUrl,
        blockEnd: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "suppression_organisation",
      category: "menus",
      typeGuichet:['Guichet'],
      item: {
        label: tSm("menu.suppression_organisation"),
        materialIconLabel: "remove_circle_outline",
        link: currentUrl,
        isForDelete: true,
        selectedItem: "",
        handleClick: () => {}
      }
    },
    {
      key: "suppression_eshop",
      category: "menus",
      typeGuichet:['e-Shop'],
      item: {
        label: tSm("menu.suppression_eshop"),
        materialIconLabel: "remove_circle_outline",
        link: currentUrl,
        isForDelete: true,
        selectedItem: "",
        handleClick: () => {}
      }
    }
  ];
  return allMenuItems
    .filter(({ key, category ,typeGuichet}) =>
      hasPermission("guichets", category, key, roles, undefined, "sideMenu") && typeGuichet?.includes(typeSelectedGuichet)
    )
    .map(({ item }) => item);
};


export const useMenuItems = (
  selectedRoute: string,
  organisationId: string
): SidebarMenuItemProps[] => {
  const { t: tSm } = useHandleTranslation('menu');
  const currentUrl = window.location.href;
  return [
    {
      label: tSm('menu.dashboard'),
      materialIconLabel: "dashboard",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.identity'),
      materialIconLabel: "verified",
      link: `${selectedRoute}/${organisationId}/identity`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.eservices'),
      materialIconLabel: "apps",
      link: `${selectedRoute}/${organisationId}/eservices`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.offre'),
      materialIconLabel: "local_grocery_store",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.parametres'),
      materialIconLabel: "tune",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.monitoring'),
      materialIconLabel: "multiline_chart",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.securite'),
      materialIconLabel: "security",
      link: currentUrl,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.contributeurs'),
      materialIconLabel: "manage_accounts",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.utilisateurs'),
      materialIconLabel: "group",
      link: currentUrl,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.groupes'),
      materialIconLabel: "group",
      link: currentUrl,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.administrateurs'),
      materialIconLabel: "manage_accounts",
      link: `${selectedRoute}/${organisationId}/administrateurs`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.developer_zone'),
      materialIconLabel: "code",
      link: `${selectedRoute}/${organisationId}/devzone`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.market_place'),
      materialIconLabel: "interests",
      link: `${selectedRoute}/${organisationId}/marketplace`,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.communaute'),
      materialIconLabel: "person_search",
      link: `${selectedRoute}/${organisationId}/communaute`,
      blockEnd: true,
      selectedItem: '',
      handleClick: () => {}
    },
    {
      label: tSm('menu.suppression_organisation'),
      materialIconLabel: "remove_circle_outline",
      link: `${selectedRoute}/${organisationId}/deleteorganisation`,
      isForDelete: true,
      selectedItem: '',
      handleClick: () => {}
    }
  ];
};

export const useTopMenuItems = (selectedRoute: string): TopMenuItemProps[] => {
  const { t: tTm } = useHandleTranslation('menu');

  return [
    {
      label: tTm('top_menu.accueil'),
      link: `${selectedRoute}`,
      selectedItem: '',
      materialIconLabel: "home",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.organisations'),
      link: `${selectedRoute}/orgs`,
      selectedItem: '',
      materialIconLabel:"business_center",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.guichets'),
      link: `${selectedRoute}/guichets`,
      selectedItem: '',
      materialIconLabel:"corporate_fare",
      handleClick: () => {}
    },
    {
      label: tTm('top_menu.catalogue'),
      link: `${selectedRoute}/catalogue`,
      selectedItem: '',
      materialIconLabel:"grid_view",
      handleClick: () => {}
    },
    // {
    //   label: tTm('top_menu.offre'),
    //   link: `${selectedRoute}/offre`,
    //   selectedItem: '',
    //   materialIconLabel: "local_grocery_store",
    //   handleClick: () => {}
    // },
    // {
    //   label: tTm('top_menu.academy'),
    //   link: `${selectedRoute}/academy`,
    //   selectedItem: '',
    //   materialIconLabel:"school",
    //   handleClick: () => {}
    // },
    // {
    //   label: tTm('top_menu.support'),
    //   link: `${selectedRoute}/support`,
    //   selectedItem: '',
    //   materialIconLabel:"help_outline",
    //   handleClick: () => {}
    // }
  ];
};

export const useSwitcherItems = (): SwitcherItemProps[] => {
  const { t: tSw } = useHandleTranslation('menu');

  return [
    {
      label: tSw('switcher.window'),
      link: 'guichet',
      selectedItem: '',
      materialIconLabel: "business",
      handleClick: () => {}
    },
    {
      label: tSw('switcher.user'),
      link: 'utilisateur',
      selectedItem: '',
      materialIconLabel:"person_outline",
      handleClick: () => {}
    }
  ];
};
