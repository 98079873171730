import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const SendSvg: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 14">
<path d="M6.00033 5.33333L2.00033 4.33333V2.00667L7.91366 4.5C8.54032 4.18667 9.24699 4 10.0003 4C10.067 4 10.127 4.00667 10.187 4.00667L0.666992 0V10.6667L5.33366 8.7C5.33366 8.68667 5.33366 8.68 5.33366 8.66667C5.33366 8.13333 5.42699 7.62667 5.59366 7.14667L2.00033 8.66V6.33333L6.00033 5.33333Z" />
<path d="M10.0003 5.33333C8.16033 5.33333 6.66699 6.82667 6.66699 8.66667C6.66699 10.5067 8.16033 12 10.0003 12C11.8403 12 13.3337 10.5067 13.3337 8.66667C13.3337 6.82667 11.8403 5.33333 10.0003 5.33333ZM11.1003 10.2333L9.66699 8.8V6.66667H10.3337V8.52667L11.567 9.76L11.1003 10.2333Z" />
   </AbstractSVGIcon>
);
export default SendSvg;
