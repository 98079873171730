
import { FC, useState } from "react";
import styles from './UserSettingPage.module.css';
import { useSelector } from "react-redux";
import { BreadCrumb, Tabs } from "../../../../shared/components";
import { MaterialIcon } from "../../../../shared/components/icons";
import { RootState } from "../../../../shared/stores";
import { useHandleDomLocation } from "../../hooks";
import { PageContentContainer } from "../../layouts";
import { RolesAndPermissionsPage } from "./RolesAndPermissionsPage";

export const UserSettingPage: FC = () => {
  const [selectedTab] = useState("eService");
  const { profilePathName } = useHandleDomLocation();
  const FullName = useSelector((state: RootState) => state.auth.userProfile?.fullName);
  const BreadCrumbItems = [
    {
      icon: <MaterialIcon center label="home" className="text-base" />,
      href: `/${profilePathName}`
    },
    { title: 'Paramètres du compte', href: '' }
  ];


  console.log('profilePathName',profilePathName);
  const tabList = [
    { name: 'monCompte', label: <><span className="icon">work_outline</span>Mon compte</>, children:<div></div> },
    { name: 'apparence ', label: <><span className="icon">format_paint</span>Apparence </>, children: <div></div> },
    { name: 'notifications', label: <><span className="icon">notifications_none</span>Notifications</>, children: <div></div> },
    { name: 'securite', label: <><span className="icon">lock</span>Accès et sécurité</>, children: <div></div> },
    { name: 'roles', label: <><span className="icon">rule</span>Rôles et permissions</>, children: <RolesAndPermissionsPage /> },
    { name: 'performance', label: <><span className="icon">multiline_chart</span>Performance</>, children: <div></div> },
    { name: 'delete', label: <span className= {`${styles.deleteEservice}  text-red-500`}><span className="icon">delete</span>Suppression de compte</span>, children: <div></div> },
  ];


  return (
    <PageContentContainer className="flex flex-col gap-1 !h-[100%]">
      <div className={styles.filterBarContainer}>
        <BreadCrumb items={BreadCrumbItems} ></BreadCrumb>
      
      <div className={styles.header}>
    <div className={`${styles.userFullName} dark:text-white`}>

        {FullName}

      <div className={styles.libele}> Compte personnel</div>
      </div>
    </div>
    
      <div className={styles.tabsContainer}>
        <Tabs tabs={tabList} align="horizontal" current={selectedTab} defSelected={selectedTab} />
      </div>
      </div>
        
    </PageContentContainer >
  );
};