import { useNavigate } from "react-router-dom";
import { useHandleDomLocation } from "../../hooks";
import { useEffect, useState } from "react";
import { AllGuichetsRequest, FavoriteGuichetRequest } from "../../types";
import { convertToGuichetCard, useGetAllGuichets } from "../../api";
import { useFavoriteGuichet } from "../../api/window/caller";
import { Guichet } from "../../components/ui/cards/base-cards/CardGuichet";
import { FilterBarContentProps } from "../../components/ui/filter-bar/FilterBarContent";
import StarSvg from "../../../../shared/components/icons/StarSvg";
import { FilterBarInputProps } from "../../components/ui/filter-bar/FilterBarInput";
import FilterSvg from "../../../../shared/components/icons/FilterSvg";
import GridSvg from "../../../../shared/components/icons/GridSvg";
import ListSvg from "../../../../shared/components/icons/ListSvg";
import LocalisationSvg from "../../../../shared/components/icons/LocalisationSvg";
import { FilterBarSortByProps } from "../../components/ui/filter-bar/FilterBarSortBy";
import { MaterialIcon } from "../../../../shared/components/icons";
import { PageContentContainer } from "../../layouts";
import { BreadCrumb, EmptyData, Map} from "../../../../shared/components";
import { FilterBar } from "../../components/ui/filter-bar/FilterBar";
import { CardGrid } from "../../components/ui/card-grid/CardGrid";
import { Table } from "../../components/ui/table/Table";


export const OrganisationsPage = () => {
  const navigate = useNavigate();

  const { profilePathName } = useHandleDomLocation();

  const [favorite, setFavorite] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [showGrid, setShowGrid] = useState<boolean>(true);

  const [showTable, setShowTable] = useState<boolean>(false);

  const [showMap, setShowMap] = useState<boolean>(false);

  const [totalItems, setTotalItems] = useState(0);

  const [sortBy, setSortBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'recent', label: 'Récent' });
 
  const [searchValue, setSearchValue] = useState<string>('');

  const [verification, setVerification] = useState<string>('');

  const [page, setPage] = useState<number>(1);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  const [dataFavoriteRequest, setDataFavoriteRequest] =
    useState<FavoriteGuichetRequest>({
      guichetId: '',
      favorite: false
    });

  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>

  const [organisationsParams, setOrganisationsParams] = useState<AllGuichetsRequest>({
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    typeGuichet: '',
    reference: '',
    domainName: '',
    organisme: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
    objectType: 'ORGANISM'
  });

  const [organismValue, setOrganismValue] = useState<Guichet[]>([]);
  const getOrganism = useGetAllGuichets(organisationsParams);

  const [position] = useState<L.LatLngLiteral>({
    lat: 31.7917,
    lng: -7.0926
    });


  useFavoriteGuichet(dataFavoriteRequest);
  useEffect(() => {
    if (verification) {
      setReset(true)
    } else {
      setReset(false)
    }
    setIsFetching(true);
    setOrganisationsParams((prevData) => ({
      ...prevData,
      name: searchValue,
      favorite: favorite,
      verification: verification,
      recent: sortBy.value,
      numPage: page,
      pageSize: showMap ? 1000 : 10
    }));
  }, [
    showMap,
    page,
    sortBy,
    searchValue,
    favorite,
    verification
  ]);
  useEffect(() => {
    if (getOrganism.isFetching) {
      setIsFetching(true);
    }
    console.log('favorite',favorite);

    if (getOrganism.isSuccess) {
      if ('error' in getOrganism.data) {
        navigate('/authentication', { replace: true });
        return;
      }
      const guichets = getOrganism.data.body.data;
      const totalItems = getOrganism.data.body.pagination.totalLength;
      if (guichets !== undefined) {
        if (totalItems !== undefined) {
          setTotalItems((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setOrganismValue((prevData) => {
          return showGrid && page > 1
            ? [...prevData, ...convertToGuichetCard(guichets)]
            : convertToGuichetCard(guichets);
        });
        setIsFetching(false);
      }
    }
  }, [organisationsParams, getOrganism.status]);

  const fBc: FilterBarContentProps = {
    label: 'Organisations',
    searchLength: totalItems,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setOrganismValue([]);
          setFavorite(!favorite);
        }
      },
    ],
  };

  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher...',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: !showMap
      ? {
          handleClick: (showFilters) => {
            setShowFilters(!showFilters);
          },
          icon: FilterSvg,
          label: 'Filtres',
          selected: showFilters,
          reset: reset,
          resetClick: () => {
            setVerification('');
            setReset(false);
          }
        }
      : undefined,
    mapButton: {
      handleClick: (showMap) => {
        if (showMap) {
          return;
        }
        setPage(1);
        setShowGrid(false);
        setShowTable(false);
        setShowMap(!showMap);
      },
      icon: LocalisationSvg,
      selected: showMap
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setPage(1);
        setShowMap(false);
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setPage(1);
        setShowMap(false);
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setOrganismValue([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setOrganismValue([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setOrganismValue([]);
      setSearchValue('');
    },
    onSearch: () => {}
  };
  const fBs: FilterBarSortByProps = {
    items: [
      {
        data: [
          {
            label: 'Vérifiée',
            value: 'Vérifiée'
          },
          {
            label: 'En attente vérification',
            value: 'En attente vérification'
          },
          {
            label: 'Non vérifiée',
            value: 'Non vérifiée'
          }
        ],
        label: 'Statut de vérification',
        placeHolder: 'Statut de vérification',
        selectedValue: verification,
        clearable: true,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }
          if (e == verification) return;
          setOrganismValue([]);
          setVerification(e);
        }
      },
    ]
  };
  const fBMap: FilterBarSortByProps = {
    items: [
      fBs.items[0],
      {
        data: [{ label: '', value: '' }],
        label: 'Préfecture/province',
        placeHolder: 'Préfecture/province',
        selectedValue: '',
        onChange: () => {}
      },
      {
        data: [
          {
            label: '',
            value: ''
          }
        ],
        label: 'Commune/arrondissement',
        placeHolder: 'Commune/arrondissement',
        selectedValue: '',
        onChange: () => {}
      }
    ]
  };
  const BreadCrumbItems = [
    {
      icon: <MaterialIcon center label="home" className="text-base" />,
      href: `/${profilePathName}`
    },
    { title: 'Organisations' }
  ];
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 h-[100%] rounded-md">

      <BreadCrumb items={BreadCrumbItems} />
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarSortBy={fBs}
      />

      {showGrid &&
        (organismValue.length > 0 || isFetching ? (
          <CardGrid
            setFavoriteParams={setDataFavoriteRequest}
            isFetching={isFetching}
            type="guichet"
            totalPages={Math.ceil(totalItems / 10)}
            currentPage={page}
            fetchMoreData={(nextPage: number) => {
              setPage(nextPage);
            }}
            items={organismValue}
            onSelectDropDown={()=>{}}
          />
        ) : (
          <EmptyData title={`Aucune organisation pour l'instant`} description="La liste des organisations sera affichée ici" icon={emptyIcon} />
        ))}

      {showTable &&
        (organismValue.length > 0 || isFetching ? (
          <Table
            isFitching={isFetching}
            setFavoriteParams={setDataFavoriteRequest}
            type="organisme"
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalItems}
            headerItems={{
              items: ['', 'Nom','Guichets','e-Services','Propriétaire','Date de création']
            }}
            rows={organismValue}
          />
        ) : (
          <EmptyData title={`Aucune organisation pour l'instant`} description="La liste des organisations sera affichée ici" icon={emptyIcon} />
        ))}
     
     {showMap && (
        <Map
          clearFilters={() => {
          
          }}
          objectType={organisationsParams.objectType}
          isFetching={isFetching}
          openAction={()=>{}}
          filtersData={fBMap}
          position={position}
          markersData={organismValue}
        />
      )} 
    </PageContentContainer>
  );
};
