import {ConfigProvider, RadioProps as AntdRadioProps} from "antd"
import {Radio as AntdRadio} from "antd"
import {FC} from "react";
interface RadioProps extends AntdRadioProps {
    label:string
}
export const Radio:FC<RadioProps>=(props)=>{
    return <>
        <ConfigProvider theme={KSW_ANTD_RADIO_THEME} >
        <AntdRadio {...props}>{props.label}</AntdRadio>
        </ConfigProvider>
    </>
}
const KSW_ANTD_RADIO_THEME={
    token: {
        colorPrimary:'#3B82F6',
        colorPrimaryBorder:'#3B82F6',
        colorPrimaryHover:'#2563EB',
        colorBorder:'#CBD5E1',
    },
}