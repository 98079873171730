import React from 'react';
import EserviceStep from './EserviceStep';
import { Snapshot } from '../../../../../../types/data/eservice.types';

interface EserviceCarouselProps {
  steps: Snapshot[] | undefined;
}

const EserviceCarousel: React.FC<EserviceCarouselProps> = ({ steps }) => {
  return (
    <div className="grid lg:grid-cols-3 gap-5 mx-2">
      {steps && steps.map((step, index) => (
        <EserviceStep
          key={index}
          imageURL={step.imageURL ||""}
          label={step.label ||""}
          description={step.description ||""}
        />
      ))}
    </div>
  );
};

export default EserviceCarousel;
