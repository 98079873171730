import { Button, InputText } from '../../../../shared/components';
import { MaterialIcon } from '../../../../shared/components/icons';
import { CardGrid } from '../../../portal/components/ui/card-grid/CardGrid';
import { ESERVICE_PUB_MOCK_DATA } from '../../utils/constants/helpers.constants';
import styles from './catalogue.module.css';
import { HeaderComponent } from './HeaderComponent';

export const CatalogComponent=()=>{
const  search= <MaterialIcon label="search" className='text-slate-400'/>;
const close=<MaterialIcon label="close" className='text-slate-400'/>;

return(<>
<div className={styles.catalogContainer}>
    <span className={styles.titleCatalogue}>Catalogue e-services</span>
    <div className='flex gap-5 w-3/5'>  
        <InputText
          icon={search}
          size='lg'
          placeholder="Mot clé"
          afterText={close}
        /> 
        <div className={styles.iconContainer}><MaterialIcon label='grid_view' className='text-slate-500 active:text-blue-600  dark:text-slate-400 dark:active:text-white '/></div>
        <div className={styles.iconContainer}><MaterialIcon label='format_list_bulleted' className='text-slate-500 active:text-blue-600 dark:text-slate-400 dark:active:text-white'/></div>

        </div>
</div>
<div className={styles.filtresContainer}>
  <div className='flex flex-col gap-5 dark:bg-slate-800 p-2 rounded'>
    <Button size={'lg'} color={'secondary'} styling={'outline'} label='Filtres' ></Button>
    <HeaderComponent title='Trier par' ><></></HeaderComponent>
    <HeaderComponent title='Catégorie' ><></></HeaderComponent>
    <HeaderComponent title='Propriétaire e-Service' ><></></HeaderComponent>
    <HeaderComponent title='Source e-Service' ><></></HeaderComponent>
    <HeaderComponent title='Type guichet' ><></></HeaderComponent>
    <HeaderComponent title='Secteur' ><></></HeaderComponent>

    </div>

  <CardGrid
                            currentPage={0}
                            totalPages={1}
                            fetchMoreData={() => {
                            }}
                            items={ESERVICE_PUB_MOCK_DATA}
                            type="eservice"
                            onSelectDropDown={()=>{}}
                        />
</div>
</>);
}