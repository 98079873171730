import {CardGuichet, Section} from "../../../components/ui";
import {useEffect, useState} from "react";
import {AllGuichetsRequest, FavoriteGuichetRequest} from "../../../types";
import {convertToGuichetCard, useGetAllGuichets, useMutateFavoriteGuichet} from "../../../api";
import {EmptyData, SimpleModal} from "../../../../../shared/components";
import {Card} from "../../../components/ui/cards/Card";
import {useNavigate} from "react-router-dom";
import {useHandleDomLocation} from "../../../hooks";
import {useDispatch} from "react-redux";
import {setSelectedGuichet} from "../../../features/guichets/guichetsSlice";
import {Guichet} from "../../../components/ui/cards/base-cards/CardGuichet";
import {useQueryClient} from "@tanstack/react-query";
import { ACCES_IMPOSSIBLE_MODAL } from "../../../../../shared/utils/constants";
import { getDropDownDataPermissions } from "../../../utils/helpers/permissions.helper";

export const FavoriteWindowWidget=()=> {
    const queryClient=useQueryClient()
    const navigate=useNavigate();
    const dispatch = useDispatch();

    const [guichetsParams,] = useState<AllGuichetsRequest>({
        numPage: 1,
        pageSize: 5,
        contributeur: false,
        key: '',
        name: '',
        status: '',
        statutRole: '',
        reference: '',
        domainName: '',
        email: '',
        activation: '',
        verification: '',
        tags: '',
        type: '',
        role: '',
        favorite: true,
        industry: '',
        entity: '',
        recent: '',
        Fields: 'ALL'
    });
    const getGuichets = useGetAllGuichets(guichetsParams);
    const {profilePathName}=useHandleDomLocation();
    const toggleFavoriteWindow =useMutateFavoriteGuichet();
    const [showNotImplemented,setShowNotImplemented]=useState<boolean>(false);

    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>,onClick:()=>navigate(`/${profilePathName}/guichets`)},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i> ,onClick:()=>setShowNotImplemented(!showNotImplemented)},
    ]
    const handleSetSelectedGuichet = (window: Guichet) => {
        dispatch(setSelectedGuichet(window));
    };
    const handleNavigateToGuichet = (windowId: string, guichetName: string) => {
        localStorage.setItem('guichetName', guichetName);
        navigate(`guichets/${windowId}/eservices`);
    };
    const handleSelectMenuWindow=(selection:unknown)=>{
        const selectedWindow = convertToGuichetCard(getGuichets.customHandler.data?.data).find(
            (window: Guichet) => window.id === selection.id
        );
        if (selectedWindow) {
            handleSetSelectedGuichet(selectedWindow);
            handleNavigateToGuichet(selectedWindow.id, selectedWindow.name);
        }
    }
    const handleToggleFavoriteWindow=(data:FavoriteGuichetRequest)=>{
        console.log("dd");
        toggleFavoriteWindow.mutate(data)
    }
    useEffect(() => {
        if (toggleFavoriteWindow.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['all-window'] });
    }, [toggleFavoriteWindow.customHandler.isSuccess]);
    const renderFavoriteWindowData=()=>{
        if((getGuichets.isFetching || getGuichets.customHandler.isSuccess) && (getGuichets.customHandler.data?.pagination.totalLength!==0)) return (
            <div className="grid grid-cols-3 gap-2 gap-y-4">
            { convertToGuichetCard(getGuichets.customHandler.data?.data).map(
                (window: Guichet, index: number) =>
                    <CardGuichet
                        key={index}
                        guichet={window}
                        setFavoriteParams={handleToggleFavoriteWindow}
                        dropDownData={getDropDownDataPermissions(window.role,window)}
                        cardAction={{
                            onSelectDropDown: (selection) => handleSelectMenuWindow(selection)
                        }}

                    />
            )}
            <Card toAdd onClick={()=>setShowNotImplemented(!showNotImplemented)}/>
          </div>)
        return (<div className="flex justify-center h-full items-center">
            <EmptyData title="Aucun guichet pour l'instant" description="La liste des guichets sera affichée ici"  searchIcon />
             </div>)
    }
    return (
        <Section title="Guichets favoris / fréquemment visités" materialIconLabel="corporate_fare" className="h-full" menuList={menuList}>
                {renderFavoriteWindowData()}
                <SimpleModal closeOnClickOutside={true}  isOpen={showNotImplemented} afterClose={()=>setShowNotImplemented(!showNotImplemented)} title={"Accès impossible"}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>
        </Section>
    )
}
