import React, { useState } from 'react';
import { MaterialIcon } from '../../../../shared/components/icons';
import styles from './catalogue.module.css';

interface HeaderComponentProps {
  title: string;
  children: React.ReactNode;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (<>
      <div className='flex justify-between p-3 text-slate-800 rounded border border-slate-100 dark:text-white dark:border-slate-600'>
        <span className={styles.titleHeader}>{title}</span>
        <span onClick={toggleOpen}>
        <MaterialIcon label={isOpen ? 'expand_more' : 'expand_less'} className='cursor-pointer' ></MaterialIcon>       
        </span>
        {isOpen && <>{children}</>}
      </div>
      
  
  </>);
};