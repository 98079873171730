import { useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
  addFavoriteEservice,
  getCategoriesList,
  getEserviceMembersList,
  getEservicesList,
  getRequestJoinEservicesList,
  postCreateEservice,
  postRefuseRequestJoinEservice,
  postRequestJoinEservice,
  postSendInvitation,
  updateEserviceSettings,
  fetchEserviceDetails,
  postAcceptRequestJoinEservice,
  postAddEserviceToEshop,
  postAnnulationInvitation,
  inviteAdministratorSettings,
  postAcceptInvitation,
  postCreateEserviceExterne,
  updateEserviceExternSettings,
  updateStatusEserviceExternSettings,
  updateTypePublicationEserviceExternSettings
} from "./api";
import { useHandleGenericResponse, useHandleStandardResult } from "../../../../shared/hooks";
import {
  AcceptInvitation,
  EserviceAcceptJoin,
  EserviceAddToEshopRequest,
  EserviceAnnulationInvitation,
  EserviceCreationExterneRequest,
  EserviceCreationRequest,
  EServiceDetails,
  EServiceExterneSettings,
  EServiceExterneStatusRequestSettings,
  EServiceExterneTypePublicationRequestSettings,
  EserviceInvitationRequest,
  EserviceJoinRequest,
  EserviceMembersListRequest,
  EServiceSettings,
  FavoriteEserviceRequest,
  ListEserviceRequest,
  RequestJoinEservice
} from "../../types/data/eservice.types";
import { AdministrateusPageForm } from "../../types/data.types";

export const useGetEserviceList = (data: ListEserviceRequest) => {
  const queryKey = ["list-services", JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getEservicesList(data),
    retry: 0,
    staleTime: 0,
    gcTime: 0
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};
export const useGetRequestJoinEserviceList = (data: RequestJoinEservice) => {
  const queryKey = ["list-request", JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getRequestJoinEservicesList(data),
    retry: 0,
    staleTime: 0,
    gcTime: 0
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useGetEserviceMembersList = (data: EserviceMembersListRequest) => {
  const queryKey = ["list-members", JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => getEserviceMembersList(data),
    retry: 0,
    staleTime: 0,
    gcTime: 0
  });

  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};

export const useGetCategoriesList = () => {
  const reactQuery = useQuery({
    queryKey: ["categories-list"],
    queryFn: () => getCategoriesList(),

  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
}

export const useFavoriteEservice = (data: FavoriteEserviceRequest) => {
  const queryKey = ['favorite-eservices', JSON.stringify(data)];
  const reactQuery = useQuery({
    queryKey,
    queryFn: () => addFavoriteEservice(data),
    retry: 0,
    enabled: data.idEservice != "",
    staleTime: 0
  });
  const customHandler = useHandleGenericResponse(reactQuery);
  return { ...reactQuery, customHandler };
};


export const useMutateFavoriteEservice=()=>{
  const mutationQuery = useMutation({
    mutationFn: (request:FavoriteEserviceRequest) => addFavoriteEservice(request)
  });
  const customHandler = useHandleGenericResponse(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useCreateEservice = () => {
  const queryClient = useQueryClient();

  const mutationQuery = useMutation({
    mutationFn: (data: EserviceCreationRequest) => postCreateEservice(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-services'] });
    },
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useCreateEserviceExterne = () => {
  const queryClient = useQueryClient();

  const mutationQuery = useMutation({
    mutationFn: (data: EserviceCreationExterneRequest) => postCreateEserviceExterne(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-services'] });
    },
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}


export const useRequestJoinEservice = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EserviceJoinRequest) => postRequestJoinEservice(data),
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useAcceptRequestJoinEservice = () => {
  const queryClient = useQueryClient();
  const mutationQuery = useMutation({
    mutationFn: (data: EserviceAcceptJoin) => postAcceptRequestJoinEservice(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-members"] });
    },

  });

  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useAnnulationInvitationEservice = () => {
  const queryClient = useQueryClient();
  const mutationQuery = useMutation({
    mutationFn: (data: EserviceAnnulationInvitation )=> postAnnulationInvitation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-members"] });
    },

  });

  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useSendInvitation = () => {
  const queryClient = useQueryClient();
  const mutationQuery = useMutation({
    mutationFn: (data: EserviceInvitationRequest) => postSendInvitation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-members'] });
    },
  });

  const customHandler = useHandleStandardResult(mutationQuery);

  return { ...mutationQuery, customHandler };
};

export const useAddEserrviceToEshop = () => {
  const queryClient = useQueryClient();

  const mutationQuery = useMutation({
    mutationFn: (data: EserviceAddToEshopRequest) => postAddEserviceToEshop(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-services'] });
    },
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

export const useEServiceSettings = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EServiceSettings) =>
      updateEserviceSettings(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};

export const useEServiceExterneSettings = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EServiceExterneSettings) =>
        updateEserviceExternSettings(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};

export const useStatusEServiceExterneSettings = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EServiceExterneStatusRequestSettings) =>
        updateStatusEserviceExternSettings(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};

export const useTypePublicationEServiceExterneSettings = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EServiceExterneTypePublicationRequestSettings) =>
        updateTypePublicationEserviceExternSettings(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};


export const usegetEserviceDetails = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: EServiceDetails) =>
      fetchEserviceDetails(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};

export const useRefuseRequestJoinEservice = () => {
  const queryClient = useQueryClient();
  const mutationQuery = useMutation({
    mutationFn: (data: EserviceAcceptJoin) =>
      postRefuseRequestJoinEservice(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['list-request'] });
    }
  });

  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};

export const inviteAdministrator = () => {
  const mutationQuery = useMutation({
    mutationFn: (data: AdministrateusPageForm) =>
      inviteAdministratorSettings(data)
  });
  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
};


export const useAcceptInvitation = () => {
  const queryClient = useQueryClient();
  const mutationQuery = useMutation({
    mutationFn: (data: AcceptInvitation) => postAcceptInvitation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list-members"] });
    },

  });

  const customHandler = useHandleStandardResult(mutationQuery);
  return { ...mutationQuery, customHandler };
}

