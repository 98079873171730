import React, {FC} from "react";
import {MessageInstance} from "antd/es/message/interface";
import {message} from "antd";

export interface AppProviderType {
    message: MessageInstance | undefined;
}
interface AppProviderProps{
    children: React.ReactNode;
}
export const AppProviderContext = React.createContext<AppProviderType>({message:undefined});
export const AppProvider:FC<AppProviderProps>=(props)=>{
    const [messageApi, contextHolder] = message.useMessage();
    return <AppProviderContext.Provider  value={{message: messageApi}}>
        {contextHolder}
        {props.children}
    </AppProviderContext.Provider>
}