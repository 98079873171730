import {FC, ReactNode} from "react";
import classNames from "classnames";
import styles from "../list-item/list-item.module.css"
interface  ListItemProps{
    label:string,
    icon:ReactNode,
    size?:"sm"|"md",
    onClick:()=>void
    selected?:boolean
}

export const ListVerticalItem:FC<ListItemProps>=(props)=>{
    const {size="sm",selected=false}=props
    const rootStyle=classNames({
        [styles.rootContainer]:true,
        [styles.selectedItemContainer]:selected,
        ["h-8"]:size==="sm" && false,
        ["h-9"]:size==="md" && false,
        [styles["portal-list-list_item-css-var"]]:true
    })

    const labelContainerStyle=classNames({
        "flex flex-col justify-center items-center gap-2 w-full py-2":true,
    })
    const labelStyle=classNames({
        [styles.label]:true,
        "text-center":true,
    })
    return (
        <div className={rootStyle} onClick={props.onClick} >
            <div className={labelContainerStyle}>
                {props.icon}
                <span className={labelStyle}>{props.label}</span>
            </div>
        </div>
    )
}