import { FC } from "react";
import styles from './catalogue.module.css';
import classNames from "classnames";
import { Star } from "@phosphor-icons/react";

export interface FeedbackComponentProps {
    feedback:string,
    writer :string,
    rating :number, 
}

export const FeedbackComponent:FC<FeedbackComponentProps> = (props)=>{
    const starsStyle = (index: number) => classNames({
        [styles.star]: true,
        ['!text-sky-700']: index < props.rating
    });

  


    const stars = Array.from({ length: 5 }, (_, index) => (
          <Star weight="fill" key={index} className={starsStyle(index)}></Star>
      ));
    return (<>
     <div className='flex flex-col gap-5 items-center p-5 border-[1px] border-zinc-200 rounded-lg dark:bg-slate-700 dark:text-white dark:border-slate-800'>
      <div className='flex gap-1'>{stars}</div>
      <p>{props.feedback}</p>
      <p>- {props.writer}</p>
    </div>
        </>);
}