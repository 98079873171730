import { FC, useState } from "react";
import { FeedbackComponent, FeedbackComponentProps } from "./FeedbackComponent";
import styles from './catalogue.module.css';
import { MaterialIcon } from "../../../../shared/components/icons";

interface FeedbackCarousselComponentProps{
    feedbacks:FeedbackComponentProps[]
}

export const FeedbackCarousselComponent:FC<FeedbackCarousselComponentProps> = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;

  const visibleFeedbacks = props.feedbacks.slice(
    currentIndex,
    currentIndex + itemsToShow
  );

  const handleNext = () => {
    if (currentIndex + itemsToShow < props.feedbacks.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };


    return (
        <div className={styles.carousel}>
          <button className={styles.navButton} onClick={handlePrev}>
          <MaterialIcon label="arrow_back_ios"  className="text-white"></MaterialIcon>
          </button>
          <div className={styles.feedbackContainer}>
            {visibleFeedbacks.map((feedback, index) => (
              <FeedbackComponent
                key={index}
                feedback={feedback.feedback}
                writer={feedback.writer}
                rating={feedback.rating}
              />
            ))}
          </div>
          <button className={styles.navButton} onClick={handleNext}>
            <MaterialIcon label="arrow_forward_ios" className="text-white"></MaterialIcon>
          </button>
        </div>
      );
    };