import classNames from 'classnames';
import React, { ReactNode, useRef, useEffect } from 'react';

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const MapModal: React.FC<MapModalProps> = ({ isOpen, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Modal styles
  const mapModalStyle = classNames({
    'absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50': true,
    'show': isOpen,
    'notDisplayV2': !isOpen,
  });

  return (
    <div className={mapModalStyle}>
      <div
        ref={modalRef}
        style={{ backgroundColor: 'var(--mapping-slate-000)' }}
        className="!w-full p-1 mx-10 rounded-lg shadow-lg"
      >
        {children}
      </div>
    </div>
  );
};

export default MapModal;
