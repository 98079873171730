import { FC } from "react";
import { SimpleModal } from "../../../../../../shared/components";

interface ConfirmModalProps {
    isOpen: boolean;
    onCancel: () => void;
    onValidate: () => void;
    message: string;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
                                                        isOpen,
                                                        onCancel,
                                                        onValidate,
                                                        message, // Use message prop
                                                    }) => {
    return (
        <SimpleModal
            type="primary"
            cancelLabel="Annuler"
            validateLabel="Valider"
            isOpen={isOpen}
            onValidate={onValidate}
            onCancel={onCancel}
            title="Statut publication"
        >
            <div className="flex flex-col px-[24px]  py-[16px] min-w-[450px] gap-3">
                <p>
                    {message}
                </p>
            </div>
        </SimpleModal>
    );
};
