import {createColumnHelper} from "@tanstack/react-table";
import {WindowOrganizationRequestsResponse} from "../../../../types";
import {getKarazFileUrl} from "../../../../../../shared/utils/helpers";
import {Menu} from "../../../../../../shared/components";
import {MaterialIcon} from "../../../../../../shared/components/icons";
import {Tag} from "antd";
import {REQUEST_MODULE_STATUS} from "../../../../../../shared/utils/constants";
import {usePostAcceptOrganizationRequest, usePostRejectOrganizationRequest} from "../../../../api";
import {useContext, useEffect} from "react";
import {AppProviderContext} from "../../../../../../shared/context/AppProvider";
import {useQueryClient} from "@tanstack/react-query";





export const getWindowRequestsColumns=()=> {
    const queryClient=useQueryClient();
    const {message}=useContext(AppProviderContext);
    const columnHelper = createColumnHelper<WindowOrganizationRequestsResponse>()
    const renderOrganizationImage=(gedId:string|undefined)=> {
        if (gedId) return <img className="rounded-full" src={getKarazFileUrl(gedId)} alt=""/>
        return <></>;
    }
    const acceptJoinOrganizationRequest=usePostAcceptOrganizationRequest();
    const rejectJoinOrganizationRequest=usePostRejectOrganizationRequest();

    const approveJoinRequest=(requestId:string)=>{
        acceptJoinOrganizationRequest.mutate(requestId)
    }
    const rejectJoinRequest=(requestId:string)=>{
        rejectJoinOrganizationRequest.mutate(requestId)}

    const alertKey = 'toggle-join-organization-request';

    useEffect(() => {
        if ((acceptJoinOrganizationRequest.isPending || rejectJoinOrganizationRequest.isPending) && message) {
             message.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [acceptJoinOrganizationRequest.isPending,rejectJoinOrganizationRequest.isPending]);

    useEffect(() => {
        if (acceptJoinOrganizationRequest.customHandler.isSuccess && message) {
               message.success({
                key: alertKey,
                content: acceptJoinOrganizationRequest.customHandler.successMessage,
                duration: 2.5
            });
            queryClient.invalidateQueries({queryKey: ['window-organization-requests']})
         }
    }, [acceptJoinOrganizationRequest.customHandler.isSuccess]);

    useEffect(() => {
        if (acceptJoinOrganizationRequest.customHandler.isError && message) {
            message.error({
                key: alertKey,
                content: acceptJoinOrganizationRequest.customHandler.errorMessage,
                duration: 2.5
            });
        }
    }, [acceptJoinOrganizationRequest.customHandler.isError]);

    useEffect(() => {
        if (rejectJoinOrganizationRequest.customHandler.isSuccess && message) {
            message.success({
                key: alertKey,
                content: rejectJoinOrganizationRequest.customHandler.successMessage,
                duration: 2.5
            });
            queryClient.invalidateQueries({queryKey: ['window-organization-requests']})
        }
    }, [rejectJoinOrganizationRequest.customHandler.isSuccess]);
    useEffect(() => {
        if (rejectJoinOrganizationRequest.customHandler.isError && message) {
             message.error({
                key: alertKey,
                content: rejectJoinOrganizationRequest.customHandler.errorMessage,
                duration: 2.5
            });
        }
    }, [rejectJoinOrganizationRequest.customHandler.isError]);

    const inProgressStatusMenuList=(requestId:string)=>[
        {label:"Approuver",value:"approve",icon:<MaterialIcon center label="check_circle" className="text-emerald-500 text-base"/>,onClick:()=>approveJoinRequest(requestId)},
        {label: "Refuser",value:"refuse",icon:<MaterialIcon center label="block" className="text-red-500 text-base"/>,onClick:()=>rejectJoinRequest(requestId)},
    ];
    const AcceptedStatusMenuList=[
        {label:"Retirer",value:"approve",icon:<MaterialIcon center label="delete" className="text-red-500 text-base"/>},
    ];
    const wrapStatusValue=(value:string)=>{
        switch (value){
            case REQUEST_MODULE_STATUS.DRAFT:return <Tag color="blue">En attente approbation</Tag>;
            case REQUEST_MODULE_STATUS.REJECTED:return <Tag color="red">Refusée</Tag>;
            case REQUEST_MODULE_STATUS.ACCEPTED:return <Tag color="green">Approuvé</Tag>;
            default : return value;
        }
    }
    const renderMenu=(value:string,requestId:string)=>{
       switch (value){
           case REQUEST_MODULE_STATUS.DRAFT:return <Menu list={inProgressStatusMenuList(requestId)} icon={menuIcon} action/>;
           case REQUEST_MODULE_STATUS.ACCEPTED:return <Menu list={AcceptedStatusMenuList} icon={menuIcon} action/> ;
       }
    }
    const menuIcon=<MaterialIcon label="more_vert" center className="text-md text-slate-600 text-xl"/>
    return ([
        columnHelper.accessor('organismName', {
            header:()=>("Guichet"),
            cell: info =>
                <div className="flex gap-2 items-center" >
                    <div className="flex justify-center  h-6 aspect-square rounded-full">{renderOrganizationImage(info.row.original.gedId)}</div>
                    <span className="font-medium text-sm">{info.getValue()}</span>
                </div>
        }),
        columnHelper.accessor('statusRequest', {
            header:()=>("Statut"),
            cell: info =>
                <div className="flex justify-between items-center el">
                    <span className="font-normal text-[13px] text-slate-700">{wrapStatusValue(info.getValue())}</span>
                    {renderMenu(info.getValue(),info.row.original.idRequest)}
               </div>
        }),
    ])
}