import styles from './landing.module.css'
import logo from '../../../../shared/assets/icons/ksw-logo.svg';
import { MaterialIcon } from '../../../../shared/components/icons';
import {  useEffect, useState,MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBarSelect from './NavBarSelect';
import { Button } from '../../../../shared/components';

const NavbarComponent = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

    const [theme, setTheme] = useState(
        localStorage.getItem('data-theme') !== null
          ? localStorage.getItem('data-theme')
          : 'light'
      );
      const navigate = useNavigate();
      const toggleTheme = () => {
        const newTheme = (theme== 'light' ? 'dark' : 'light');
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('data-theme', newTheme);
      };
      const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

      const toggleDropdown = (label: string) => {
        setActiveDropdown((prev) => (prev === label ? null : label));
      };
    
      useEffect(() => {
        const handleClickOutside = () => setActiveDropdown(null);
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
      }, []);
    
      const handleDropdownClick = (e:MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        console.log(selectedItem);
      };

      return (
    <nav className={ `dark:bg-gray-900 ${styles.navbar}`}>
      <div className={styles.navbarLeft } onClick={handleDropdownClick}>
        <img src={logo} alt="Logo" className={styles.navbarLogo}/>
        <NavBarSelect
                      label="Karaz"
                      options={['Accès en mode Démo', 'Qui est concerné ?', 'Qui sommes nous ?']}
                      isOpen={activeDropdown === 'Karaz'}
                      toggleDropdown={() => toggleDropdown('Karaz')}
                      onSelect={(option) => setSelectedItem(option)}
                    /> 
      <NavBarSelect
                    label="Services"
                    options={['Catalogue e-services', 'Recherche guichets', 'Carte interactive']}
                    isOpen={activeDropdown === 'Services'}
                    toggleDropdown={() => toggleDropdown('Services')}
                    onSelect={(option) => setSelectedItem(option)}
            /> 
          <NavBarSelect
                label="Ressources"
                options={['Actualités', 'Podcast Karib', 'Communauté Karaz','Assistance']}
                isOpen={activeDropdown === 'Support'}
                toggleDropdown={() => toggleDropdown('Support')}
                onSelect={(option) => setSelectedItem(option)}

            /> 
  
          <NavBarSelect
              label="Espace partenaire"
              options={['Devenez partenaire', 'Offres Karaz Pro', 'Karaz Academy','Questions fréquentes']}
              isOpen={activeDropdown === 'Espace partenaire'}
              toggleDropdown={() => toggleDropdown('Espace partenaire')}
              onSelect={(option) => setSelectedItem(option)}
            />
      </div>

      <div className={styles.navbarRight}>
                <Button size={'lg'} color={"secondary"} styling={"solid"}  label="Connexion" onClick={()=>navigate("/authentication")}/>
                <Button size={'lg'} color={"secondary"} styling={"outline"}  label="S'inscrire" onClick={()=>navigate("/signup")}/>
                <MaterialIcon label={theme == 'light' ? 'dark_mode' : 'light_mode'} className={styles.navIcons} onClick={()=>{toggleTheme()}}></MaterialIcon>

                <MaterialIcon label={'language'} className={styles.navIcons} ></MaterialIcon>
      </div>
    </nav>
  );
};

export default NavbarComponent;