import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  NotificationCard,
  SimpleCard
} from '../../../../../../shared/components';
import { OrganisationIcon } from '../../../icons';
import {
  Select,
  InputText
} from '../../../../../../shared/components/controlled-ui';
import styles from './steps.module.css';
import { Button } from 'kls-ui';
import { ImageUploader } from '../../uploader/image-uploader/ImageUploader';
import { StepsHeader } from '../layout/StepsHeader';
import classNames from 'classnames';
import { NotificationType } from '../../../../../../shared/utils/enums';
import { OnboardingGuichetCreationRequest } from '../../../../types';
import { FC, useCallback, useEffect, useState } from 'react';
import { OrgCreationStepSchema } from '../../../../holders/schemas';
import {
  DynamicListData,
  LogoUploadRequest
} from '../../../../../../shared/types';
import { useHandleTranslation } from '../../../../../../shared/hooks';
import {
  removePrefixFromBase64,
  transformExtension
} from '../../../../../../shared/utils/mapper';
import { RadioChangeEvent } from 'antd';
import { Radio, RadioGroup } from '../../../../../../shared/components/antd';
import MapModal from '../../../../../../shared/components/map/mapComponents/MapModal';
import { Map } from '../../../../../../shared/components/map/Map';
import { OnboardingGuichetCreationForm } from '../../../../types/data/window.types';
import { MaterialIcon } from '../../../../../../shared/components/icons';
interface ApiData {
  industriesList: DynamicListData[] | undefined;
}
interface ApiStatus {
  industriesList: boolean | undefined;
  postCreateOrganization: boolean | undefined;
}
interface OrgCreationStepProps {
  rootClassName?: string;
  onSubmit: (data: OnboardingGuichetCreationRequest) => void;
  submitError: string | undefined;
  backButton: { label: string; onClick: () => void };
  isLoading: ApiStatus;
  isSuccess?: ApiStatus;
  data: ApiData;
  inModal?: boolean;
}

export const OrgCreationStep: FC<OrgCreationStepProps> = (props) => {
  const { rootClassName = '', inModal = false } = props;
  const { t: tO } = useHandleTranslation('window');

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<OnboardingGuichetCreationForm>({
    resolver: yupResolver(OrgCreationStepSchema)
  });

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [imageData, setImageData] = useState<LogoUploadRequest | undefined>({
    format: '',
    base64: ''
  });
  const [position, setPosition] = useState<L.LatLngLiteral | undefined>(
    undefined
  );
  const selectedType = watch('typeAdress');
  const name = watch('name');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [windowType, setWindowType] = useState<'Guichet' | 'e-Shop'>('Guichet');
  const errorMessageStyles = classNames({
    ['!my-4']: true,
    ['notShow']: !isErrorDisplayed,
    ['show transition-all duration-500']: isErrorDisplayed
  });

  const cardStyle = classNames({
    [rootClassName]: true,
    ['w-1/2']: !inModal
  });

  const rootStyle = classNames({
    ['flex flex-col py-2']: true,
    [styles['onboarding-steps-css-var']]: true
  });

  const handleImageUploaderChange = useCallback(
    (
      image: string | undefined,
      error: string | undefined,
      format: string | undefined
    ) => {
      setIsErrorDisplayed(true);
      setErrorMessage(error);
      if (image && format) {
        setImageData({
          format: transformExtension(format),
          base64: removePrefixFromBase64(image)
        });
        setIsErrorDisplayed(false);
      } else setImageData(undefined);
    },
    [errorMessage, imageData]
  );
  const handleSubmitStep = (formData: OnboardingGuichetCreationForm) => {
    const positionRequest: { latitude?: string; longitude?: string } = {};
    if (!position && selectedType === 'Physique') {
      setIsErrorDisplayed(true);
      setErrorMessage('Veuillez sélectionnez l’emplacement de votre guichet !');
      return;
    }
    if (position && selectedType === 'Physique') {
      positionRequest.latitude = `${position.lat}`;
      positionRequest.longitude = `${position.lng}`;
    } else if (position && selectedType === 'Virtuel') {
      setPosition(undefined);
    }
    let imageDataRequest: {
      logoFormat: string | null;
      logoAsBase64: string | null;
    } = { logoFormat: '', logoAsBase64: '' };
    if (imageData?.base64 && imageData.format)
      imageDataRequest = {
        logoFormat: imageData.format,
        logoAsBase64: imageData.base64
      };
    props.onSubmit({
      ...formData,
      ...imageDataRequest,
      ...positionRequest,
      categorie_guichet: windowType,
      type: 'Organisation'
    });
  };
  useEffect(() => {
    if (windowType === 'e-Shop' && name) {
      const randomValue = Math.floor(Math.random() * 10000);
      setValue('domain', name.trim().toLowerCase() + randomValue);
      setValue('industrie', 'N/A');
    }
  }, [windowType, name]);
  useEffect(() => {
    if (props.submitError) {
      setErrorMessage(props.submitError);
      setIsErrorDisplayed(true);
    } else {
      setIsErrorDisplayed(false);
    }
  }, [props.submitError]);

  const renderIndustriesOptions = () => {
    return props.data.industriesList?.map((item: DynamicListData) => ({
      ...item,
      icon: (
        <i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>
      )
    }));
  };
  const handleWindowTypeChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'Guichet') {
      setValue('domain', '');
      setValue('industrie', '');
    }
    setWindowType(e.target.value);
  };
  const DomaineAfterText = (
    <span className="text-[#2563EB] text-[13px]">.karaz.org</span>
  );
  useEffect(() => setIsOpen(false), [position]);
  console.log('valuess', control._formValues);

  return (
    <>
      <SimpleCard
        spin={props.isLoading.postCreateOrganization}
        className={cardStyle}>
        <div className={rootStyle}>
          <StepsHeader
            icon={<OrganisationIcon className="size-12" />}
            title={tO('orgCreation_step_title')}
            description={tO('orgCreation_step_description')}
          />
          <NotificationCard
            type={NotificationType.ERROR}
            className={errorMessageStyles}>
            {errorMessage}
          </NotificationCard>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <ImageUploader onChange={handleImageUploaderChange} />
              <RadioGroup
                className="flex justify-center w-full py-2"
                onChange={handleWindowTypeChange}
                value={windowType}>
                <Radio value={'Guichet'} label="Guichet" />
                <Radio value={'e-Shop'} label="e-Shop" />
              </RadioGroup>
              <div className="flex flex-col py-3 gap-2">
                <InputText
                  size="md"
                  label={
                    windowType !== 'e-Shop'
                      ? tO('orgCreation_name_label')
                      : tO('orgCreation_eshop_name_label')
                  }
                  control={control}
                  name={'name'}
                  placeholder={tO('orgCreation_name_placeholder')}
                  error={errors.name?.message}
                  withError
                />
                {windowType === 'Guichet' && (
                  <>
                    <Select
                      control={control}
                      error={errors.industrie?.message}
                      placeholder="Chosissez un élément"
                      size="lg"
                      label={tO('orgCreation_industry_label')}
                      name="industrie"
                      showSelectedIcon
                      options={renderIndustriesOptions() || []}
                    />
                    <InputText
                      size="lg"
                      label={tO('orgCreation_domain_label')}
                      control={control}
                      name={'domain'}
                      placeholder={tO('orgCreation_domain_placeholder')}
                      error={errors.domain?.message}
                      afterText={DomaineAfterText}
                      withError
                    />
                  </>
                )}
                <div
                  style={{
                    gridTemplateColumns:
                      selectedType === 'Physique' ? '60% 40%' : '100%'
                  }}
                  className="grid ">
                  <Select
                    control={control}
                    error={errors.typeAdress?.message}
                    placeholder="Chosissez un élément"
                    size="lg"
                    label={tO('orgCreation_type_label')}
                    name="typeAdress"
                    options={[
                      { label: 'Physique', value: 'Physique' },
                      { label: 'Virtuel', value: 'Virtuel' }
                    ]}
                  />
                  {selectedType === 'Physique' && (
                    <div className="relative flex justify-center items-center pl-2 py-2">
                      <Button args={{ onClick: () => setIsOpen(!isOpen) }} size="sm" label="Sélectionner l'emplacementé" leftIcon={<MaterialIcon label="location_on"/>} color='primary' styling='outline'></Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <Button
                label={props.backButton.label}
                disabled={true}
                color="neutral"
                size="sm"
                styling="outline"
                args={{ onClick: props.backButton.onClick }}
              />
              <Button
                label={tO('orgCreation_submit_button')}
                color="primary"
                size="sm"
                styling="solid"
                args={{ onClick: handleSubmit(handleSubmitStep) }}
              />
            </div>
          </div>
        </div>
      </SimpleCard>
      <MapModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Map position={position} onChoosePosition={setPosition} />
      </MapModal>
    </>
  );
};
