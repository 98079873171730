import { object, string} from "yup";
import {validatePhoneNumber} from "../../../../shared/utils/helpers";

export const IdentityOrganizationSchema = object().shape({
    name: string().required('Le nom est obligatoire.'),
    acronym: string().default(""),
    description: string().default(""),
    email: string().required('L’email est obligatoire.'),
    phoneNumber: object().shape({prefix: string(), suffix: string(),}).test(
        '',
        "L'indicatif et le numéro de téléphone sont obligatoires.",
        (value) => validatePhoneNumber(value.prefix,value.suffix),
    ),
    identifier: string().required('L’ID de l’organisation est obligatoire.'),
    webSite: string().default(""),
    type: string().required('Le type est obligatoire.'),
});