import { Eservice } from "../components/ui/cards/base-cards/CardEservice";

export const ESERVICEs_MOCK_DATA: Eservice[][] = [
    [
        {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    },
    {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, ],
    [
    {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    },
    {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    },
],[
    {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    },
    {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    }, {
        id: 'ayoooooooo',
        name: 'Inwi',
        industry: 'Telecom',
        status: 'Approuvé',
        verification: 'En attente d’approbation',
        favorite: true,
        imageUrl: '',
        guichetName: 'souhaib',
        dateModif: 'hel',
        isKaraz: true,
        isPublic: '',
        role: [],
        guichetId: "",
        moduleName: ""
    },
],
]