import { FC, useState } from 'react';
import { IconSize } from '../../../../../shared/utils/enums';
import FavoriteSvg from '../../../../../shared/components/icons/FavoriteSvg.tsx';
import styles from './table-items.module.css';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg.tsx';
import logo from '../../../assets/icons/logo-app.svg';
import { MaterialIcon } from '../../../../../shared/components/icons/MaterialIcon.tsx';
import { Menu } from '../../../../../shared/components/index.tsx';
import { Guichet } from '../cards/base-cards/CardGuichet.tsx';
import {
  FavoriteEserviceRequest,
  FavoriteGuichetRequest
} from '../../../types/index.ts';
interface RowTableOrganismeProps {
  item: Guichet ;
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  setFavoriteEservice?: (params: FavoriteEserviceRequest) => void;
  onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  icon?: string;
}
export const RowTableOrg: FC<RowTableOrganismeProps> = (props) => {
  const [favorite, setFavorite] = useState<boolean>(
    props.item.favorite || false
  );
  const handleSelect = (value: string) => {
    if (props.onSelectDropDown) {
      props.onSelectDropDown({
        id: props.item.id,
        selectedValue: value
      });
      return;
    }
  };
  const menuIcon = (
    <MaterialIcon label="more_vert" className={styles.menuIcon} />
  );
  const renderMenu = () => {
    if (props.dropDownData) {
      return (
        <Menu
          key={props.item.id}
          className={styles.moreIcon}
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={props.dropDownData}
        />
      );
    }
    return null;
  };
  return (
    <tr key={props.item.id}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <span
            onClick={() => {
              setFavorite(!favorite);
              if (props.setFavoriteParams) {
                props.setFavoriteParams({
                  guichetId: props.item.id,
                  favorite: !props.item.favorite
                });
              } else if (props.setFavoriteEservice) {
                props.setFavoriteEservice({
                  idEservice: props.item.id,
                  favorite: !props.item.favorite
                });
              }
            }}
            className="cursor-pointer">
            {favorite ? (
              <FavoriteSvg className={styles.starF} size={IconSize.PX18} />
            ) : (
              <FavoriteSvg className={styles.starNF} size={IconSize.PX18} />
            )}
          </span>
          <img
            src={props.item.imageUrl || logo}
            alt={`${props.item.name} logo`}
            className="w-8 h-8 rounded-full"
          />
        </div>
      </td>
      <td className={styles.nameCaseContainer}>
        <div className={styles.nameCase}>
          <span>{props.item.name}</span>
          {props.item.verification && (
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          )}
        </div>
      </td>
      
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>
            { props.item.windowscount!= null ? props.item.windowscount : 'N/A'}
        </div>
      </td>

      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>
           {props.item.eservicesCount != null ? props.item.eservicesCount : 'N/A'}
        </div>
      </td>

      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>          
             { props.item.owner || 'N/A'}
        </div>
      </td>

    

      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
         <span>{props.item.creationTime}</span>
          <div className={styles.headerIcons}>{renderMenu()}</div>
        </div>
      </td>
    </tr>
  );
};
