import { ChangeEvent, FC, useState } from "react";
import { MAX_CARACTER_NAME } from "../../../../utils/constants/helpers.constants";
import { useCreateEserviceExterne } from "../../../../api";
import { useParams } from "react-router-dom";
import { Input } from "kls-ui";
import {InputText, SimpleModal} from "../../../../../../shared/components";
import { MessageInstance } from "antd/es/message/interface";

interface EserviceCreationExternalModalProps {
    guichetName: string;
    messageApi: MessageInstance;
    isOpen: boolean;
    onCancel: () => void;
}

export const EserviceCreationExternalModal: FC<EserviceCreationExternalModalProps> = (props) => {
    const [nomService, setNomService] = useState('');
    const [lien, setLien] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageLien, setErrorMessageLien] = useState('');
    const { guichetId } = useParams<{ guichetId: string }>();
    const postCreateEserviceExterne = useCreateEserviceExterne();

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage('');
        const value = e.target.value;
        if (value.length <= MAX_CARACTER_NAME) {
            setNomService(value);
        }
    };

    const handleLienChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLien(e.target.value);
    };

    const handleCancel = () => {
        setNomService('');
        setLien('');
        setErrorMessage('');
        setErrorMessageLien('')
        props.onCancel();
    };

    const handleCreation = () => {
        if (!nomService.trim()) {
            setErrorMessage("Ce champ est requis");
            return;
        }
        if (!lien.trim() ) {
            setErrorMessageLien("Ce champ est requis");
            return;
        }
        if (guichetId) {
            postCreateEserviceExterne.mutate(
                {
                    idGuichet: guichetId,
                    nameEservice: nomService,
                    urlWeb: lien,
                    type:'externe',
                    status: 'Brouillon',
                    source: 'false',
                    visibilite: 'Private Service'
                },
                {
                    onSuccess: () => {
                        props.messageApi.success({
                            content: 'E-service externe créé avec succès',
                            duration: 2.5
                        });
                        handleCancel();
                    },
                    onError: () => {
                        props.messageApi.error({
                            content: 'Une erreur est survenue lors de la création',
                            duration: 2.5
                        });
                    }
                }
            );
        } else {
            console.error('guichetId is not available', guichetId);
        }
    };

    return (
        <SimpleModal
            type="primary"
            cancelLabel="Annuler"
            validateLabel="Ajouter"
            isOpen={props.isOpen}
            onValidate={handleCreation}
            onCancel={handleCancel}
            afterClose={handleCancel}
            title="Nouveau e-service"
        >
            <div className="flex flex-col pt-[32px] pb-[32px] pl-[24px] pr-[24px] min-w-[450px] gap-3">
                <Input
                    errorMessage={errorMessage}
                    size="sm"
                    label="Nom du e-service"
                    args={{
                        placeholder: "Entrez le nom de votre e-service",
                        value: nomService,
                        onChange: handleNameChange
                    }}
                    charCounter={MAX_CARACTER_NAME}
                />
                <Input
                    errorMessage={errorMessageLien}
                    size="sm"
                    label="Lien"
                    args={{
                        placeholder: "Entrez le lien du e-service",
                        value: lien,
                        onChange: handleLienChange
                    }}
                />
                <InputText
                    label="Guichet"
                    size="sm"
                    value={props.guichetName}
                    disabled
                />
            </div>
        </SimpleModal>
    );
};
