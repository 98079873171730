import { MessageInstance } from "antd/es/message/interface";
import { MultipleAdvancedDropDownList, TextArea } from "kls-ui";
import { FC, useEffect, useState } from "react";
import { useRequestJoinEservice } from "../../../../api";
import { ROLE_LIST_ESERVICE } from "../../../../utils/constants/components.constants";
import styles from '../eservices-pages.module.css'
import { SimpleModal } from "../../../../../../shared/components";

interface EserviceJoindreEserviceModalProps {
    messageApi: MessageInstance;
    selectedCardOption:{ id: string; selectedValue: string } | null;
    isOpen:boolean;
    onCancel:()=>void;
    
}
export const EserviceJoindreEserviceModal :FC<EserviceJoindreEserviceModalProps> = (props)=>{

    const [note, setNote] = useState('');
    const postRequestJoinEservice = useRequestJoinEservice();
    const [values, setValues] = useState<string[]>([]);
    const [errorMessageJoin, setErrorMessageJoin] = useState('');

    const filteredRoleList = ROLE_LIST_ESERVICE.filter(role => {
      if (values.includes('Administrateur') && role.value === 'Citizen developer') {
        return false;
      }
      if (values.includes('Citizen developer') && role.value === 'Administrateur') {
        return false;
      }
      return true;
    }); 

    const onChange = (options: [{ label: string, value: string }]) => {
        console.log(options);
        const valuesArray = options.map(option => option.value);
        setValues(valuesArray);
      }
    
    const handleJoinEservice = () => {
        if (!values || values.length === 0) {
          setErrorMessageJoin("Ce champ est requis");
          return;
        }
        if (values.length >= 1) {
          if (props.selectedCardOption?.id) {
            postRequestJoinEservice.mutate({
              note: note,
              roles: values,
              EserviceId: props.selectedCardOption.id
            });
          } else {
            console.error('Id is not available');
          }

          handleCloseJoin();
        }
      }
    
    
      const handleCloseJoin = () => {
        props.onCancel();
        setNote('');
        setValues([]);
      }
      
      useEffect(() => {
        if (postRequestJoinEservice.isPending) {
          props.messageApi.loading({
            content: 'Loading...',
            duration: 0
          });
    
        }
        if (postRequestJoinEservice.customHandler.isSuccess) {
          props.messageApi.destroy();
          props.messageApi.success({
            content: 'Votre demande a été soumise avec succès',
            duration: 2.5
          });
        }
        if (postRequestJoinEservice.customHandler.isError) {
          props.messageApi.destroy();
    
          props.messageApi.error({
            content: postRequestJoinEservice.customHandler.errorMessage,
            duration: 2.5
          });
        }
    
    
      }, [postRequestJoinEservice.status]);
    
      
    return( <SimpleModal
      type="primary"
      cancelLabel="Annuler"
      validateLabel="Ajouter"
      isOpen={props.isOpen}
      onValidate={handleJoinEservice}
      onCancel={handleCloseJoin} 
      afterClose={handleCloseJoin}
      title={"Demande d’accès"}
     >
          <div className="flex flex-col p-6 gap-3">
            <span className={styles.textRequest}>Veuillez choisir le rôle que vous souhaitez occuper dans l&apos;e-service</span>
            <div className={styles.labelRole}>Rôle</div>
            <MultipleAdvancedDropDownList
              list={filteredRoleList}
              selectedValues={values}
              addNew={null}
              errorMessage={errorMessageJoin}
              handlerChange={onChange}
              size={3}
              label={undefined}
            />
            <TextArea
              size="sm"
              args={{
                placeholder: "Ajouter une note...",
                value: note,
                onChange: (e) => { setNote(e.target.value); }
              }}
              label="Note"
            />
          </div>
        </SimpleModal>);
}