import { useState, KeyboardEvent, forwardRef, useRef, ForwardRefRenderFunction, useEffect } from 'react';
import { TagElement } from '../../tags/tag-element/TagElement';
import { InputTagsProps } from '../../../types';
import { InputText } from '../../input/input-text/InputText';

const ForwardedTagInput: ForwardRefRenderFunction<HTMLInputElement, InputTagsProps> = (
    props,
    ref
) => {
  const { size = 'sm', value = [], onChange, errorMessage, placeholder, readOnly = false, disabled = false } = props; // On récupère disabled et readOnly
  const [tagsLabel, setTagsLabel] = useState<string[]>(value);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(inputRef.current);
    } else if (ref) {
      ref.current = inputRef.current;
    }
  }, [ref]);

  useEffect(() => {
    setTagsLabel(value);
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (readOnly || disabled) return; // On bloque l'ajout des tags si non modifiable
    const tag = e.currentTarget.value.trim();
    if (!tag || tagsLabel.includes(tag) || e.key !== 'Enter') return;

    const newTags = [...tagsLabel, tag];
    setTagsLabel(newTags);
    onChange?.(newTags);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleCloseTag = (tag: string) => {
    if (readOnly || disabled) return; // On bloque la suppression si non modifiable
    const newTags = tagsLabel.filter((label) => label !== tag);
    setTagsLabel(newTags);
    onChange?.(newTags);
  };

  const handleClearTags = () => {
    if (readOnly || disabled) return; // On bloque la suppression de tous les tags
    setTagsLabel([]);
    onChange?.([]);
  };

  const renderTagElements = () =>
      tagsLabel.map((label, index) => (
          <TagElement
              size={size}
              closable={!readOnly && !disabled} // L'icône de fermeture n'apparaît que si éditable
              key={index}
              label={label}
              onClose={() => handleCloseTag(label)}
          />
      ));

  return (
      <>
        <InputText
            disabled={disabled}
            readOnly={readOnly}
            isTag={true}
            placeholder={placeholder}
            size={size}
            ref={inputRef}
            label="Tags"
            clearable={!readOnly && !disabled} // On empêche le bouton de suppression de tout effacer
            onClear={handleClearTags}
            beforeText={tagsLabel.length > 0 && <div className="flex gap-1 flex-wrap">{renderTagElements()}</div>}
            onKeyDown={handleKeyDown}
            errorMessage={errorMessage}
        />
      </>
  );
};

export const TagInput = forwardRef(ForwardedTagInput);
