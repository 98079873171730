import { EserviceRoles } from "../../permissions/eservicesRoles";
import { GuichetRoles } from "../../permissions/guichetRoles";
import { PermissionsEservice, PermissionsGuichet } from "../../permissions/permission.types";
import { RoleEservice, RoleGuichet } from "../../permissions/role.types";


export function hasPermission<Resource extends 'eservices' | 'guichets'>(
  resource: Resource,
  category: 'actions' | 'menus',
  key: string,
  roles: Resource extends 'eservices' ? RoleEservice[] : RoleGuichet[],
  data?: Resource extends 'eservices' ? PermissionsEservice : PermissionsGuichet,
  typeMenu?: 'topMenu' | 'sideMenu',
): boolean {
  const rolePermissions = resource === 'eservices' ? EserviceRoles : GuichetRoles;
  return roles.some((role) => {
    const permissions = rolePermissions[role]?.[category as keyof typeof rolePermissions[typeof role]];
    const permission = !typeMenu ? permissions?.[key] : permissions?.[typeMenu]?.[key];
    if (permission === undefined) return false;

    return typeof permission === 'boolean' ? permission : permission(data);
  });
}
