import React, { useEffect, useState } from 'react';
import ServiceCard from '../../../../../components/ui/cards/eservice-card/ServiceCard';
import {
  FilterBarInput,
  FilterBarInputProps
} from '../../../../../components/ui/filter-bar/FilterBarInput';
import FilterSvg from '../../../../../../../shared/components/icons/FilterSvg';
import { useGetEservicesMarketPlaceList } from '../../../../../api/market-place/caller';
import { EserviceData, ListEserviceRequest } from '../../../../../types';
import { Spin } from 'antd';
interface EservicesMarketplaceTabProps {
  setSelectedItem: (selectedItemId: string) => void;
}
export const EservicesMarketplaceTab: React.FC<
  EservicesMarketplaceTabProps
> = ({ setSelectedItem }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const filters = [
    'Tous',
    'Aéronautique',
    'Éducation',
    'Logistique',
    'Gouvernement',
    'Commerce',
    'Fabrication',
    'Pharma'
  ];

  const handleFilterSelect = (filter: string) => {
    setAllEservicesParams((prevData) => {
      return { ...prevData, industry: filter };
    });
  };
  const [servicesMarketplaceValue, setServicesMarketplaceValue] = useState<
    EserviceData[]
  >([]);
  const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      status: '',
      reference: '',
      favorite: false,
      industry: '',
      entity: '',
      guichetName: '',
      idGuichet: '',
      typePublication: '',
      role: '',
      Fields: 'ALL'
    });
  const getServices = useGetEservicesMarketPlaceList(allEservicesParams);
  useEffect(() => {
    if (getServices.isSuccess) {
      const servicesMarketplace = getServices.data.body.data;
      if (servicesMarketplace !== undefined) {
        setServicesMarketplaceValue(servicesMarketplace);
      }
    }
  }, [allEservicesParams, getServices.status]);

  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher...',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: () => {},
      icon: FilterSvg,
      label: 'Filtres',
      selected: false,
      resetClick: () => {}
    },
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: {
        value: 'recent',
        label: 'Récent'
      },
      setSelectedValue: () => {}
    },
    onChange: (value) => {
      setSearchValue(value);
      setAllEservicesParams((prevData) => {
        return { ...prevData, name: value };
      });
    },
    onClear: () => {
      setSearchValue('');
      setAllEservicesParams((prevData) => {
        return { ...prevData, name: '' };
      });
    },
    onSearch: () => {}
  };
  return (
    <div className="flex overflow-hidden flex-col gap-7">
      <FilterButtonGroup
        filters={filters}
        onFilterSelect={handleFilterSelect}
      />
      <div className="flex flex-col gap-4 px-1.5">
        <FilterBarInput {...fBi} />
        <p className="text-xs text-gray-800">
          {servicesMarketplaceValue.length} e-services disponibles
        </p>
        {getServices.isFetching ? (
          <Spin className="mt-4" spinning size="large"></Spin>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 overflow-y-auto h-[100%]">
            {servicesMarketplaceValue.map((service, index) => (
              <ServiceCard
                onClick={setSelectedItem}
                eserviceMarketPlace={service}
                key={index}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
interface FilterButtonGroupProps {
  filters: string[];
  onFilterSelect: (filter: string) => void;
}

const FilterButtonGroup: React.FC<FilterButtonGroupProps> = ({
  filters,
  onFilterSelect
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('Tous');

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
    onFilterSelect(filter);
  };

  return (
    <div className="flex gap-1">
      {filters.map((filter) => (
        <button
          key={filter}
          onClick={() => handleFilterClick(filter)}
          className={`px-4 py-2 rounded-full text-xs ${
            selectedFilter === filter
              ? 'bg-blue-500 text-white border border-blue-600'
              : 'bg-white dark:bg-slate-800 text-slate-800 dark:text-white border border-slate-200 dark:border-slate-700 transition duration-200 ease-in-out hover:bg-slate-50 dark:hover:bg-slate-600'
          } `}>
          {filter}
        </button>
      ))}
    </div>
  );
};

export default FilterButtonGroup;
