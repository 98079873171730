import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import { TagInput as NativeInputTags } from './TagInput';
import { ControlledUiProps, InputTagsProps } from '../../../types';

export interface ControlledInputTagsProps<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
> extends InputTagsProps, ControlledUiProps<TFieldValues, TName> { }

export const InputTags = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>(
    props: ControlledInputTagsProps<TFieldValues, TName>
) => {
    const { readOnly = false, disabled = false } = props;

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, value, ref } }) => (
                <NativeInputTags
                    ref={ref}
                    {...props}
                    value={value || []}
                    onChange={(tags) => {
                        if (!readOnly && !disabled) {
                            onChange(tags);
                        }
                    }}
                    placeholder={!readOnly ? props.placeholder : undefined}

                />
            )}
        />
    );
};
