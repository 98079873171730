import { FC} from "react";

import { useSelector } from "react-redux";
import {RootState} from "../../../../../../shared/stores";
import {InternalEServiceParamsInterface} from "./interfaces/InternalEServiceParamsInterface";
import {ExternalEserviceParamsInterface} from "./interfaces/ExternalEserviceParamsInterface";

export const EserviceControllerParamsPage: FC = () => {

    const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
    const isKaraz ='isKaraz' in  selectedEservice ? selectedEservice.isKaraz : false;
    const renderParam = () => {
        if(isKaraz) return <InternalEServiceParamsInterface />
        else        return <ExternalEserviceParamsInterface  />;
    }
    return renderParam()
};

