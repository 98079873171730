import { FC, useEffect, useState } from 'react';
import { Organisation } from '../../../../shared/types/components.type';
import styles from './menuItem.module.css';
import { InputSearch, List } from '../../../../shared/components';
import { MaterialIcon } from '../../../../shared/components/icons';
import { convertToGuichetCard, useGetAllGuichets } from '../../api';
import { AllGuichetsRequest } from '../../types';
import { Guichet } from '../../components/ui/cards/base-cards/CardGuichet';
import { formatGuichetToListItem } from '../../utils/helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedGuichet } from '../../features/guichets/guichetsSlice';

export interface SidebarMenuHeaderProps {
  isOpen?: boolean;
  organisation: Organisation;
}

const SidebarMenuHeader: FC<SidebarMenuHeaderProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isListDisplayed, setIsListDiplayed] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [guichetsParams, setGuichetsParams] = useState<AllGuichetsRequest>({
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    typeGuichet: '',
    reference: '',
    domainName: '',
    organisme: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL',
    objectType: 'GUICHET'
  });
  const [guichetsValue, setGuichetsValue] = useState<Guichet[]>([]);
  const getGuichets = useGetAllGuichets(guichetsParams);
  useEffect(() => {
    if (getGuichets.isSuccess) {
      const guichets = getGuichets.data.body.data;
      if (guichets !== undefined) {
        setGuichetsValue(convertToGuichetCard(guichets));
      }
    }
  }, [getGuichets.status]);
  return (
    <div className={styles.innerBorder}>
      <div className="flex flex-row gap-2 items-center">
        <img
          className={styles.userMenuItemImage}
          src={props.organisation.organisationImgPath}
          alt=""
        />
        {props.isOpen && (
          <div className="overflow-hidden text-ellipsis whitespace-nowrap w-[170px]">
            {props.organisation.organisationName}
          </div>
        )}
      </div>
      <div className="flex">
        <span
          className="cursor-pointer"
          onClick={() => setIsListDiplayed(!isListDisplayed)}>
          <MaterialIcon
            label={
              !isListDisplayed ? 'keyboard_arrow_down' : ' keyboard_arrow_up'
            }
            className="text-base"
          />
        </span>
        
      </div>
      {isListDisplayed && (
          <div className="absolute z-10 flex flex-col gap-1 p-1 top-[100%] left-0 bg-white dark:bg-slate-950 rounded-md shadow-sm dark:shadow-slate-700 dark:shadow-sm w-[100%]">
            <InputSearch
              value={guichetsParams.name}
              onChange={(e) => {
                setGuichetsParams((prevData) => {
                  return { ...prevData, name: e.target.value };
                });
              }}
              onClear={() => {
                setGuichetsParams((prevData) => {
                  return { ...prevData, name: "" };
                });
              }}
              placeholder="Chercher..."
            />
            <List
              className="!relative"
              setOpenStatus={() => setIsListDiplayed(!isListDisplayed)}
              list={formatGuichetToListItem(guichetsValue)}
              onChange={(selectedValue) => {
                let path = location.pathname.split('/').filter(Boolean);
                path[2] = selectedValue[0];
                if (path.length > 3) {
                  path = path.slice(0, 3);
                }
                const selectedGuichet = guichetsValue.find(
                  (guichet) => guichet.id === selectedValue[0]
                );

                if (selectedGuichet) {
                  dispatch(setSelectedGuichet(selectedGuichet));
                }
                navigate(path.join('/'));
              }}
              open={isListDisplayed}
            />
          </div>
        )}
    </div>
  );
};

export default SidebarMenuHeader;
