import { classNames } from "@react-pdf-viewer/core";
import { FC, ReactNode, useState } from 'react';
import { InputRadioButton } from "./InputRadioButton";

interface SwitcherProps {
    options: { label: string, icon?: ReactNode, value: string }[];
    onOptionSelect?: (optionName: string) => void; 
    defaultValue?: string;
}

export const Switcher: FC<SwitcherProps> = (props) => {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(props.defaultValue);

    const rootClassName = classNames({
        "bg-slate-50 dark:bg-slate-900 rounded-3xl p-[3px] flex items-center gap-[3px]": true,
    });

    const rootStyle = {
        boxShadow: "4px 0px 6px -1px rgba(0, 0, 0, 0.10), 2px 0px 4px -2px rgba(0, 0, 0, 0.10)",
    };

    const handleChange = (isSelected: boolean, value: string) => {
        if (isSelected && selectedValue !== value) {
            setSelectedValue(value);
            if (props.onOptionSelect) {
                props.onOptionSelect(value);
            }
        }
    };

    return (
        <div className={rootClassName} style={rootStyle}>
            {
                props.options.map((element) => (
                    <InputRadioButton
                        key={element.value}
                        label={element.label}
                        selected={selectedValue === element.value}  
                        icon={element.icon}
                        onchange={(isSelected: boolean) => handleChange(isSelected, element.value)} 
                    />
                ))
            }
        </div>
    );
};
