import { FC, useEffect, useState } from 'react';
import '../../../../shared/styles/colors.css';
import SidebarMenuItem, {
  SidebarMenuItemProps
} from '../menu-item/SidebarMenuItem';
import SidebarMenuHeader from '../menu-item/SidebarMenuHeader';
import MenuButton from './MenuButton';
import { Organisation } from '../../../../shared/types/components.type';
import styles from './menu.module.css';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useMenuItemsWithPermissions } from '../menu-item/menuItems';
import { SimpleModal } from '../../../../shared/components';
import { ACCES_IMPOSSIBLE_MODAL } from '../../../../shared/utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../shared/stores';
import { RoleGuichet } from '../../../../shared/permissions/role.types';
import { useHandleDomLocation } from '../../hooks';
export interface SidebarMenuProps {
  organisation: Organisation;
}

const SidebarMenuStyle = (isOpen: boolean) =>
  classNames({
    [styles['kls-app-menu-module-css-var']]: true,
    [styles.sideBarOpen]: isOpen,
    [styles.sideBarClose]: !isOpen
  });

const SidebarMenu: FC<SidebarMenuProps> = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [selectedItem, setSelectedItem] = useState<string>('');
  const { profilePathName } = useHandleDomLocation();
  const { selectedGuichet } = useSelector((state: RootState) => state.guichets);
  const role =
    profilePathName === 'utilisateur' ||
    !('role' in selectedGuichet && selectedGuichet.role !== '')
      ? (['Usager'] as RoleGuichet[])
      : ([selectedGuichet.role] as RoleGuichet[]);
  console.log('Roles=======', role);

  const menuItemsData = useMenuItemsWithPermissions(
    location.pathname.split('/').filter(Boolean).length > 1
      ? location.pathname.split('/').filter(Boolean)[0] +
          '/' +
          location.pathname.split('/').filter(Boolean)[1]
      : '',
    location.pathname.split('/').filter(Boolean)[2],
    role,
    'typeGuichet' in selectedGuichet ? selectedGuichet?.typeGuichet : 'Guichet'
  );
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [showNotImplemented, setShowNotImplemented] = useState(false);
  const notImplemented = [
    'Dashboard',
    'Offre',
    'Paramètres',
    'Monitoring',
    'Sécurité',
    'Contributeurs',
    'Utilisateurs',
    'Groupes'
  ];
  const handleItemClick = (item: string) => {
    if (notImplemented.includes(item)) {
      setShowNotImplemented(!showNotImplemented);
    }
    setSelectedItem(item);
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length >= 3) {
      setSelectedItem(
        menuItemsData.find(
          (item) =>
            item.link ===
            pathParts[0] +
              '/' +
              pathParts[1] +
              '/' +
              pathParts[2] +
              '/' +
              pathParts[3]
        )?.label || 'Dashboard'
      );
    }
  }, [location]);

  return (
    <div className={SidebarMenuStyle(isOpen)}>
      <MenuButton isOpen={isOpen} onClick={toggleSidebar} />
      <SidebarMenuHeader organisation={props.organisation} isOpen={isOpen} />
      <div className={styles.sideBarItemsContainer}>
        {menuItemsData.map((item: SidebarMenuItemProps, index: number) => (
          <SidebarMenuItem
            handleClick={handleItemClick}
            selectedItem={selectedItem}
            key={index}
            label={item.label}
            link={item.link}
            materialIconLabel={item.materialIconLabel}
            blockEnd={item.blockEnd}
            isForDelete={item.isForDelete}
            isOpen={isOpen}></SidebarMenuItem>
        ))}
      </div>
      <SimpleModal
        isOpen={showNotImplemented}
        closeOnClickOutside={true}
        afterClose={() => setShowNotImplemented(!showNotImplemented)}
        title={'Accès impossible'}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>
    </div>
  );
};
export default SidebarMenu;
