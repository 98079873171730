export const USER_AVATAR_ICON_PATH={
    prefix:"/karazal/DownloadFile?gedId=",
    suffix:"&or=img/defaultAvatar.png"
}

export const USERS_FEEDBACK =[
    {
        feedback: "Cette plateforme unifiée est un vrai atout ! Elle rend les services plus accessibles et intuitifs, surtout pour ceux qui ne sont pas à l'aise avec la technologie. Bravo pour cette solution complète !",
        writer: "Salma, Citoyen",
        rating: 5, 
      },
      {
        feedback: "Excellente initiative ! Avoir une plateforme unifiée simplifie énormément les démarches. Plus besoin de jongler entre plusieurs sites, tout est centralisé et accessible en quelques clics",
        writer: "Abderazzak, Fonctionnaire",
        rating: 4, 
      },
      {
        feedback: "La plateforme permet de gagner du temps et d'avoir une vue d'ensemble sur tous les services, publics et privés, au même endroit.Je recommande ! ",
        writer: "Nora, Salarié ",
        rating: 4, 
      },
      {
        feedback: "Cette plateforme unifiée est un vrai atout ! Elle rend les services plus accessibles et intuitifs, surtout pour ceux qui ne sont pas à l'aise avec la technologie. Bravo pour cette solution complète !",
        writer: "Salma, Citoyen",
        rating: 5, 
      },
      {
        feedback: "Excellente initiative ! Avoir une plateforme unifiée simplifie énormément les démarches. Plus besoin de jongler entre plusieurs sites, tout est centralisé et accessible en quelques clics",
        writer: "Abderazzak, Fonctionnaire",
        rating: 4, 
      },
      {
        feedback: "La plateforme permet de gagner du temps et d'avoir une vue d'ensemble sur tous les services, publics et privés, au même endroit.Je recommande ! ",
        writer: "Nora, Salarié ",
        rating: 4, 
      },
]

export const ESERVICE_PUB_MOCK_DATA=[
  {
    id: 'ayoooooooo',
    name: 'Inwi',
    industry: 'Telecom',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: true,
    imageUrl: '',
    guichetName: 'souhaib',
    dateModif: 'hel',
    isKaraz: true,
    isPublic: '',
    role: [],
    guichetId: "",
    moduleName: ""
},
{
    id: 'ayoooooooo',
    name: 'Inwi',
    industry: 'Telecom',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: true,
    imageUrl: '',
    guichetName: 'souhaib',
    dateModif: 'hel',
    isKaraz: true,
    isPublic: '',
    role: [],
    guichetId: "",
    moduleName: ""
}, {
    id: 'ayoooooooo',
    name: 'Inwi',
    industry: 'Telecom',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: true,
    imageUrl: '',
    guichetName: 'souhaib',
    dateModif: 'hel',
    isKaraz: true,
    isPublic: '',
    role: [],
    guichetId: "",
    moduleName: ""
}, {
    id: 'ayoooooooo',
    name: 'Inwi',
    industry: 'Telecom',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: true,
    imageUrl: '',
    guichetName: 'souhaib',
    dateModif: 'hel',
    isKaraz: true,
    isPublic: '',
    role: [],
    guichetId: "",
    moduleName: ""
},
{
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
},
{
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
}, {
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
}, {
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
},
{
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
},
{
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
}, {
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
}, {
  id: 'ayoooooooo',
  name: 'Inwi',
  industry: 'Telecom',
  status: 'Approuvé',
  verification: 'En attente d’approbation',
  favorite: true,
  imageUrl: '',
  guichetName: 'souhaib',
  dateModif: 'hel',
  isKaraz: true,
  isPublic: '',
  role: [],
  guichetId: "",
  moduleName: ""
},
]