import {Controller, FieldPath, FieldValues} from "react-hook-form";
import {InputPhoneProps} from "../../types";
import {InputPhone as NativeInputPhone} from "../input/input-phone/InputPhone";
import {UncontrolledUiProps} from "../../types";

export interface ControlledInputPhoneProps<TFieldValues extends FieldValues,TName extends FieldPath<TFieldValues>>
    extends InputPhoneProps, UncontrolledUiProps<TFieldValues,TName>{}

export const InputPhone  = <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>
>
(props:ControlledInputPhoneProps<TFieldValues, TName>) => {
    const handleChange = (phoneNumber:{prefix:string|undefined,suffix:string|undefined}) => {
     if (props.formState.isSubmitted)  props.setter(props.name,phoneNumber,{shouldValidate: true })
     if (!props.formState.isSubmitted)  props.setter(props.name,phoneNumber,{})
    }
    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field: { ref } }) => (
                <NativeInputPhone
                    {...props}
                    ref={ref}
                    onChange={handleChange}
                    errorMessage={props.error}                />
            )}
        />
    );
};
