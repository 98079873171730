import {useEffect, useState } from "react";
import FilterSvg from "../../../../../shared/components/icons/FilterSvg";
import PeopleSvg from "../../../../../shared/components/icons/PeopleSvg";
import StarSvg from "../../../../../shared/components/icons/StarSvg";
import { FilterBar } from "../../../components/ui/filter-bar/FilterBar"
import { FilterBarContentProps } from "../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../components/ui/filter-bar/FilterBarInput";
import ListSvg from "../../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../../shared/components/icons/GridSvg";
import {  useFavoriteEservice, useGetCategoriesList, useGetEserviceList } from "../../../api";
import { CardGrid } from "../../../components/ui/card-grid/CardGrid";
import { PageContentContainer } from "../../../layouts";
import { convertToEserviceCard } from "../../../api/eservice/formatteur";
import { Table } from "../../../components/ui/table/Table";
import { useNavigate, useParams } from "react-router-dom";
import { FavoriteEserviceRequest, ListEserviceRequest } from "../../../types";
import { message } from "antd";
import {  BreadCrumb, EmptyData, SimpleModal } from "../../../../../shared/components";
import { MaterialIcon } from "../../../../../shared/components/icons";
import { FilterBarSortByProps } from "../../../components/ui/filter-bar/FilterBarSortBy";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEservice } from "../../../features/eservices/eservicesSlice";
import { useHandleDomLocation } from "../../../hooks";
import { Eservice } from "../../../components/ui/cards/base-cards/CardEservice";
import { RootState } from "../../../../../shared/stores/store";
import { EserviceAddEshopModal } from "./eservices-list-modals/EserviceAddEshopModal";
import { EserviceCreationModal } from "./eservices-list-modals/EserviceCreationModal";
import { EserviceExcelCreationModal } from "./eservices-list-modals/EserviceExcelCreationModal";
import { EserviceJoindreEserviceModal } from "./eservices-list-modals/EserviceJoindreEserviceModal";
import { ACCES_IMPOSSIBLE_MODAL } from "../../../../../shared/utils/constants";
import {EserviceCreationExternalModal} from "./eservices-list-modals/EserviceCreationExtreneModal";

export const EserviceListPage = () => {
  const dispatch = useDispatch();
  const selectedGuichet = useSelector((state: RootState) => state.guichets.selectedGuichet);
  const { profilePathName } = useHandleDomLocation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [contribute, setContribute] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{
    [key: string]: string | undefined;
  }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedElemnt, setSelectedElement] = useState<string>('');
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);
  const [totalNumber, setTotalNumber] = useState(0);
  const [reset, setReset] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
  const { guichetId } = useParams<{ guichetId: string }>();
  const navigate = useNavigate();
  const [raccourci]=useState(selectedGuichet.typeGuichet == 'e-Shop' ? 'true' : '')
  const [showEshop, setShowEshop] = useState(false);
  const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      status: '',
      reference: '',
      favorite: false,
      industry: '',
      entity: '',
      guichetName: '',
      idGuichet: guichetId || '',
      typePublication: '',
      role: '',
      isRaccourci:raccourci,
      Fields: 'ALL'
    });
  const [favoriteRequest, setFavoriteRequest] = useState<FavoriteEserviceRequest>({
    idEservice: '',
    favorite: false
  });
  const getEserviceList = useGetEserviceList(allEservicesParams);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [guichetName, setGuichetName] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [status, setStatus] = useState<string>(profilePathName =='guichet' ? '' : 'Public');
  const [visibility, setVisibility] = useState<string>(profilePathName =='guichet' ? '' : 'Publish');
  const [roleFiltre, setRoleFiltre] = useState<string>('');
  const getCategoriesList = useGetCategoriesList();
  const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
  useFavoriteEservice(favoriteRequest);
  const renderAddButton = () => {
    if (profilePathName === 'utilisateur') return {}
    else {
      if (selectedGuichet.typeGuichet == 'e-Shop') {
        return {
          buttonAddArgs: {
            label: 'Ajouter e-service',
            onClick: handlOpenEshop,
            color: 'primary',
            size: 'sm',
            styling: 'solid',
            leftIcon: <MaterialIcon label="add" />
          },
        }
      }
      else {
        return {
          dropDownListButton: {
            buttonAttribute: {
              label: 'Nouveau e-service',
              color: "primary",
              styling: "solid",
              size: "sm",
              rightIcon: <MaterialIcon label="expand_more" />
            },
            menuAttributes: {
              onChange: () => { },
              action: true,
              list: [
                {
                  label: 'À partir de zéro',
                  value: 'scratch',
                  icon: <MaterialIcon label="web_asset" className="text-base text-blue-600" />,
                  onClick: () => setSelectedElement('scratch')
                },
                {
                  label: 'À partir du catalogue',
                  value: 'catalogue',
                  icon: <MaterialIcon label="grid_view" className="text-base text-violet-600" />,
                  onClick: () => navigate("../marketplace", { relative: "path" })
                },
                {
                  label: `À partir d'excel`,
                  value: 'excel',
                  icon: <MaterialIcon label="table_chart" className="text-base text-pink-600" />,
                  onClick: () => setSelectedElement('excel')
                },
                {
                  label: 'Via l’assistant',
                  value: 'assistant',
                  icon: <MaterialIcon label="auto_fix_normal" className="text-base text-orange-600" />,
                  onClick: () => setSelectedElement('assistant')
                },
                {
                  label: 'Externe',
                  value: 'externe',
                  icon: <MaterialIcon label="link" className="text-base text-orange-600" />,
                  onClick: () => setSelectedElement('externe')
                },

              ]
            }
          }
        }
      }
    }
  }

  const renderContribute = () => {
    if (profilePathName === 'guichet' && !raccourci) {
      return [
        {
          icon: PeopleSvg,
          label: 'Où je participe',
          isSelected: contribute,
          handleClick: (contribute: boolean) => {
            if (page > 1) {
              setPage(1);
            }
            setCardPropsData([]);
            setContribute(!contribute);
          }
        }]
    }
    else return []
  }

  const renderFiltres = () => {
    if(raccourci) return[ {
      data: [],
      label: 'Guichet(s)',
      placeHolder: 'Guichet',
      clearable: true,
      selectedValue: industry,
      onChange: () => {
      }

    },
  ]
    if (profilePathName === 'guichet') {
      return [
        {
          data: [
            {
              label: 'Brouillon',
              value: 'Brouillon'
            },
            {
              label: 'Public',
              value: ''
            },
            {
              label: 'Non Publiée',
              value: ''
            },


          ],
          label: 'Statut',
          placeHolder: 'Statut',
          selectedValue: status,
          onChange: (e: string) => {
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == status) return;
              setCardPropsData([]);
              setStatus(e);
            }
          }

        },
        {
          data: [
            { label: 'Publique', value: '' },
            { label: 'Privé', value: '' },

          ],
          label: 'Visibilité',
          placeHolder: 'Visibilité',
          selectedValue: visibility,
          onChange: (e: string) => {
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == visibility) return;
              setCardPropsData([]);
              setVisibility(e);
            }
          }
        }
        , {
          data: [
            { label: 'Administrateur', value: 'Administrateur' },
            { label: 'Citizen Developer', value: 'Citizen Developer' },
            { label: 'Contibuteur', value: 'Contibuteur' }

          ],
          label: 'Rôle',
          placeHolder: 'Role',
          selectedValue: roleFiltre,
          onChange: (e: string) => {
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == roleFiltre) return;
              setCardPropsData([]);
              setRoleFiltre(e);
            }
          }
        }

      ]
    }

    else return []

  }

  const handlOpenEshop = () => {
    setShowEshop(!showEshop);
  }
  const fBsEserv: FilterBarSortByProps = {
    items: [
      {
        data: getCategoriesList.customHandler.data,
        label: 'Catégorie(s)',
        placeHolder: 'Catégorie(s)',
        multiple: true,
        clearable: true,
        selectedValue: industry,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == industry) return;
            setCardPropsData([]);
            setIndustry(e);
          }
        }

      },
      ...renderFiltres()


    ]
  };

  const fBc: FilterBarContentProps = {
    label: 'e-Services',
    searchLength: totalNumber,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setCardPropsData([]);
          setFavorite(!favorite);
        }
      },
      ...renderContribute()
    ],
    ...renderAddButton()
  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters,
      reset: reset,
      resetClick: () => {
        setRoleFiltre('');
        setIndustry('');
        setStatus(profilePathName =='guichet' ? '' : 'Public');
        setVisibility(profilePathName =='guichet' ? '' : 'Publish');
        setReset(false);
      }
    },

    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setCardPropsData([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
        listButton: {
          handleClick: (showTable) => {
            if (showTable) {
              return;
            }
            setShowGrid(false);
            setShowTable(!showTable);
          },
          icon: ListSvg,
          selected: showTable
        },
        gridButton: {
          handleClick: (showGrid) => {
            if (showGrid) {
              return;
            }
            setShowTable(false);
            setShowGrid(!showGrid);
          },
          icon: GridSvg,
          selected: showGrid
        },

  };


  useEffect(() => {
    const storedName = localStorage.getItem('guichetName') || '';
    setGuichetName(storedName);
  }, []);


   useEffect(() => {
    if (roleFiltre || (visibility && profilePathName =='guichet') || (status && profilePathName =='guichet') || industry) {
      setReset(true)
    } else {
      setReset(false)
    }
    setIsFetching(true);
    setAllEservicesParams((prevData) => ({
      ...prevData,

      recent: sortBy.value,
      name: searchValue,
      favorite: favorite,
      industry: industry,
      numPage: page,
      contributeur: contribute,
      status: status,
      typePublication: visibility,
      role: roleFiltre,
      isRaccourci:raccourci,
    }));



  }, [searchValue, page, contribute, favorite, sortBy, industry, status, visibility, roleFiltre,raccourci]);


  useEffect(() => {
    if (getEserviceList.isFetching) {
      setIsFetching(true);
    }
    if (getEserviceList.isSuccess) {
      const eserviceData = getEserviceList.data.body.data;
      const totalItems = getEserviceList.data.body.pagination.totalLength;

      if (eserviceData) {
        if (totalItems !== undefined) {
          setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setCardPropsData((prevData) => {
          console.log("eservices", eserviceData)
          return showGrid && page > 1
            ? [...prevData, ...convertToEserviceCard(eserviceData)]
            : convertToEserviceCard(eserviceData);
        });
        setIsFetching(false);
      }
      return;
    }
  }, [getEserviceList.status, allEservicesParams, getEserviceList.fetchStatus])

  useEffect(() => {
    if (selectedCardOption && selectedCardOption?.id) {
      const selectedEservice = cardPropsData.find((eservice) => eservice.id === selectedCardOption.id);

      if (selectedEservice && selectedCardOption.selectedValue === 'param') {
        dispatch(setSelectedEservice(selectedEservice));
          const newPath = `${window.location.pathname}/${selectedCardOption.id}`;
          console.log("path",newPath);
          navigate(newPath);
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'edit') {
        dispatch(setSelectedEservice(selectedEservice));
        const newPath = `/studio/${selectedEservice.guichetId}/${selectedEservice.id}/${selectedEservice.moduleName}`;
        window.open(newPath, '_blank');
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'access') {
        dispatch(setSelectedEservice(selectedEservice));
        const newPath = `/karazal/kas/publish.jsp?id=/${selectedEservice.guichetId}`;
        window.open(newPath, '_blank');
      }
    }
  }, [selectedCardOption]);


  const BreadCrumbItems = [
    { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}/guichets/${guichetId}` },
    { title: "e-Services", }
  ]
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 rounded-md">
      {contextHolder}
      <BreadCrumb items={BreadCrumbItems} />

      <FilterBar filterBarContent={fBc} filterBarInput={fBi} filterBarSortBy={fBsEserv} />
      {showGrid  && (
        <>
          {(cardPropsData.length >= 1 || isFetching) ? (
            <CardGrid
              isFetching={isFetching}
              currentPage={page}
              setFavoriteEservice={setFavoriteRequest}
              totalPages={Math.ceil(totalNumber / 10)}
              fetchMoreData={(nextPage: number) => {
                setPage(nextPage);
              }}
              items={cardPropsData}
              type="eservice"
              onSelectDropDown={setSelectedCardOption}
            />
          ) : (
            <EmptyData
              icon={emptyIcon}
              title={"Aucun e-service pour l'instant"}
              description={"La liste des e-services créés sera affichée ici"}
            />
          )}
        </>
      )}

      {showTable && (
        cardPropsData.length > 0 || isFetching ? <>
          {profilePathName == 'guichet' ? <Table
            isFitching={isFetching}
            setFavoriteEservice={setFavoriteRequest}
            type='eservice'
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalNumber}
            onSelectDropDown={setSelectedCardOption}
            headerItems={{
              items: raccourci ? ['', 'Nom', 'Source', 'Catégorie', 'Rôle', 'Modifié', '']
              :['', 'Nom', 'Source', 'Catégorie', 'Rôle', 'Modifié', 'Statut']
            }}
            rows={cardPropsData}
          />
            : <Table
              isFitching={isFetching}
              setFavoriteEservice={setFavoriteRequest}
              type='eserviceUsager'
              setCurrentPage={(page) => setPage(page)}
              currentPage={page}
              totalItems={totalNumber}
              onSelectDropDown={setSelectedCardOption}
              headerItems={{
                items: ['', 'Nom', 'Source', 'Catégorie']
              }}
              rows={cardPropsData}
            />}
        </>
          : (
            <EmptyData
              icon={emptyIcon}
              title={"Aucun e-service pour l'instant"}
              description={"La liste des e-services créés sera affichée ici"}
            />
          )
      )}

      <EserviceCreationModal isOpen={selectedElemnt=='scratch'} onCancel={()=>setSelectedElement('')} guichetName={guichetName} messageApi={messageApi} ></EserviceCreationModal>

      <EserviceExcelCreationModal   isOpen={selectedElemnt == 'excel'} onCancel={()=>setSelectedElement('')} guichetName={guichetName} messageApi={messageApi} ></EserviceExcelCreationModal>

      <EserviceJoindreEserviceModal isOpen={selectedCardOption?.selectedValue === 'rejoindre'} onCancel={()=>setSelectedCardOption(null)} selectedCardOption={selectedCardOption}  messageApi={messageApi} ></EserviceJoindreEserviceModal>
      <SimpleModal  closeOnClickOutside={true}  isOpen={(selectedCardOption?.selectedValue === 'view') || (selectedCardOption?.selectedValue === 'clone' ) || (selectedElemnt == 'assistant')}  afterClose={()=>{setSelectedCardOption(null); setSelectedElement('')}} title={"Accès impossible"}>
        {ACCES_IMPOSSIBLE_MODAL}
      </SimpleModal>
      <EserviceAddEshopModal onCancel={() => setShowEshop(!showEshop)} isOpen={showEshop} messageApi={messageApi} ></EserviceAddEshopModal>
      <EserviceCreationExternalModal isOpen={selectedElemnt=='externe'} onCancel={()=>setSelectedElement('')} guichetName={guichetName} messageApi={messageApi} ></EserviceCreationExternalModal>

    </PageContentContainer>
  );
}
