import { karazActionResponseAsMap, mapToUrlSearchParams } from '../mapper';
import {
  ApiJaxRsResponse,
  ApiRemoteAction,
  GenericResponse,
  GenericResponseWrapper
} from '../../types';
import { HttpMethods, ResponseResult } from '../enums';
import { getUserProfile } from './local-storage.helper';
import {KARAZ_FILE_BASE_URL} from "../constants";

export const postGenericKarazActionRequest = async <T extends object, R>(
  data: T,
  karazPath: ApiRemoteAction,
  responseType: ResponseResult
): Promise<R> => {
  const formData: URLSearchParams = mapToUrlSearchParams(data);
  Object.keys(karazPath).forEach((karazPathKey) => {
    formData.append(
      karazPathKey,
      karazPath[karazPathKey as keyof ApiRemoteAction]
    );
  });
  if (responseType === ResponseResult.JSON)
    return await postFormAsJson(formData, karazPath.path);
  return (await postFormAsMapResponse(formData, karazPath.path)) as R;
};

export const postFileCheck = async (apiPath: string, file: File, guichetId: string) => {
  const CHUNK_SIZE = 10 * 1024 * 1024;
  const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
  let chunkIndex = 0;
  let result;

  while (chunkIndex < totalChunks) {
    const start = chunkIndex * CHUNK_SIZE;
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append("file", chunk, file.name);
    formData.append("chunkIndex", chunkIndex.toString());
    formData.append("totalChunks", totalChunks.toString());
    formData.append("guichetId", guichetId);
    const response = await fetch(apiPath, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload chunk ${chunkIndex}`);
    }

    if( chunkIndex+1 === totalChunks ) {
      return response.json();
    }
    chunkIndex++;
  }
  return result;
};

export const postDataFileCheck = async (apiPath: string, fileName: string, guichetId: string) => {
  const data = {
    file_name: fileName,
    guichetId: guichetId
  };
  
  const response = await fetch(apiPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })

  if (!response.ok) {
    throw new Error(`Failed to check file at ${apiPath}`);
  }

  return response.json();
}

export const fetchGenericJaxRsRequestFromFormData = async <T extends object>(
  data: T | undefined,
  apiPath: ApiJaxRsResponse,
  method: HttpMethods
) => {
  let formData: URLSearchParams = new URLSearchParams();
  if (data) formData = mapToUrlSearchParams(data);
  const userId = getUserProfile()?.userId ?? '';
  const apiKey = apiPath.apiKey;

  formData.append('userId', userId);
  Object.keys(apiPath).forEach((ApiPathKey) => {
    formData.append(ApiPathKey, apiPath[ApiPathKey as keyof ApiJaxRsResponse]);
  });

  return fetch(apiPath.path, {
    method,
    body: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      userId: userId,
      apiKey: apiKey
    }
  }).then((result) => result.json());
};

export const fetchGenericJaxRsRequestFromJson = async <T extends object>(
  data: T | undefined,
  apiPath: ApiJaxRsResponse,
  method: HttpMethods
) => {
  const userId = getUserProfile()?.userId ?? '';

  const headers = new Headers({
    'userId': userId,
    'apiKey':apiPath.apiKey,
  });
  const requestData = { ...data, ...apiPath, userId };

  return fetch(apiPath.path, {
    method,
    headers,
    body: JSON.stringify(requestData),
  }).then((result) => result.json());
};

export const postFormAsMapResponse = async <R>(
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  })
    .then((r) => r.text())
    .then((data) => karazActionResponseAsMap(data) as R);
};

export const postFormAsJson = async <R>(
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  }).then((r) => r.json());
};

export const transformGenericResponseWrapperResponse = <T>(
  data: GenericResponseWrapper<T>,
  customFormatter: ((body: T) => Partial<T>) | undefined = undefined
): GenericResponse<T> => {
 if(customFormatter) return { ...data.data,...customFormatter(data.data.body)}
  return data.data
};
export const getKarazFileUrl=(gedId:string)=>(KARAZ_FILE_BASE_URL+gedId)
export const getFlagsUrl=(countryCode:string,size:16|24|32|48|64)=>(`https://flagsapi.com/${countryCode}/shiny/${size}.png`)