import {FC, useEffect, useMemo, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../../../shared/stores";
import {useParams} from "react-router-dom";
import {usePostCreateOrganization} from "../../../../../../api";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {IdentityCreateOrganizationForm} from "../../../../../../types";
import {yupResolver} from "@hookform/resolvers/yup";
import {IdentityOrganizationSchema} from "../../../../../../holders/schemas";
import {LogoUploadRequest} from "../../../../../../../../shared/types";
import {removePrefixFromBase64, transformExtension} from "../../../../../../../../shared/utils/mapper";
import {
    Button,
    InputText as NativeInputText,
    Map,
    MapModal,
    NotificationCard
} from "../../../../../../../../shared/components";
import {NotificationType} from "../../../../../../../../shared/utils/enums";
import {ImageUploader} from "../../../../../../components/ui";
import {InputPhone, InputText, Select, TextArea} from "../../../../../../../../shared/components/controlled-ui";
import {OrganizationIdentityTabsProps, OrganizationInterfaces} from "../OrganizationIdentityTab";

export const OrganizationCreationInterface: FC<OrganizationIdentityTabsProps> = (props) => {
    const queryClient = useQueryClient()
    const { userProfile } = useSelector(
        (state: RootState) => state.auth
    );
    const {className = ""} = props;
    const {guichetId} = useParams();
    const postCreateOrganization = usePostCreateOrganization();
    const sectionTitleStyle = classNames("text-blue-700 text-sm font-semibold")
    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState
    } = useForm<IdentityCreateOrganizationForm>({resolver: yupResolver(IdentityOrganizationSchema)});
    const selectedType = watch('type');
    const [position, setPosition] = useState<L.LatLngLiteral | undefined>(
        undefined
    );
    const{messageApi}=props;
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
    const [imageData,setImageData]=useState<LogoUploadRequest>({format:"",base64:""})
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const rootStyles = classNames({
        [className]: true
    })
    const fullName=useMemo(()=>{
        if (userProfile) return userProfile.fullName;
        return "";
    },[userProfile])
    const handleImageUploaderChange = (
        image: string | undefined,
        error: string | undefined,
        format: string | undefined
    ) => {
        setIsErrorDisplayed(true);
        setErrorMessage(error);
        if (image && format) {
            setImageData({
                format: `.${transformExtension(format)}`,
                base64: removePrefixFromBase64(image)
            });
            setIsErrorDisplayed(false);
        } else setImageData({format:null,base64:null});
    }

    const onSubmit=(data:IdentityCreateOrganizationForm)=>{
        const coordinates={latitude:position?.lat?.toString()?? null,longitude:position?.lng?.toString() ?? null};
        const result={
            ...data,
            coordinates,
            logo:imageData,
            windowId:guichetId
        };
        console.log("data is ",coordinates);
        postCreateOrganization.mutate(result);
    }
    const alertKey = 'create-organization-tab';

    useEffect(() => {
        if (postCreateOrganization.isPending) {
            messageApi.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [postCreateOrganization.isPending]);

    useEffect(() => {
        if (postCreateOrganization.customHandler.isError) {
            messageApi.error({
                key: alertKey,
                content:postCreateOrganization.customHandler.errorMessage ,
                duration: 2.5
            });
        }
    }, [postCreateOrganization.customHandler.isError]);


    useEffect(() => {
        if (postCreateOrganization.customHandler.isSuccess) {
            messageApi.success({
                key: alertKey,
                content: postCreateOrganization.customHandler.successMessage,
                duration: 2.5
            });
            if (postCreateOrganization.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
        }
    }, [postCreateOrganization.customHandler.isSuccess]);
    const handleSwitch=()=>{
        if (props.onSwitch) props.onSwitch(OrganizationInterfaces.NOT_ASSOCIATED);
    }

    return (<div className={rootStyles}>
        {isErrorDisplayed && <NotificationCard
            className="!mb-2"
            type={NotificationType.ERROR}>
            {errorMessage}
        </NotificationCard>}
        <div className="flex flex-col gap-7">
            <ImageUploader onChange={handleImageUploaderChange}/>
            <div className="flex flex-col gap-5 justify-center">
                <span className={sectionTitleStyle}>Informations générales</span>
                <div className="grid grid-cols-3 gap-4">
                    <InputText control={control} size="md" label="Nom" placeholder="Entrez le nom de votre organisation"
                               name="name" error={formState.errors.name?.message} withError/>
                    <InputText control={control} size="md" label="Acronyme" placeholder="Entrez l’acronyme de votre organisation" name="acronym" error={formState.errors.acronym?.message} withError/>
                    <NativeInputText  size="md" label="Propriétaire" value={fullName} disabled />
                </div>

                <TextArea label="Description" control={control} name="description" size="md" placeholder="Décrivez brièvement votre organisation" error={formState.errors.description?.message} withError/>
            </div>

            <div className="flex flex-col gap-4 justify-center">
                <span className={sectionTitleStyle}>Coordonnées</span>
                <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4">
                    <InputText control={control} size="md" label="Email" placeholder="Entrez l’email de votre organisation" name="email" error={formState.errors.email?.message} withError/>
                    <InputPhone control={control} label="Téléphone" prefixPlaceholder="Indicatif" placeholder="Tapez le numéro de téléphone" size="md"  error={formState.errors.phoneNumber?.message} withError formState={formState} setter={setValue} name="phoneNumber"/>
                    <InputText control={control} size="md" label="N° identifiant" placeholder="Entrez l’identifiant de votre organisation" name="identifier" error={formState.errors.identifier?.message} withError/>
                    <InputText control={control} size="md" label="Site web" placeholder="Entrez le site web de votre organisation" name="webSite" error={formState.errors.webSite?.message} withError/>
                </div>

            </div>


            <span className={sectionTitleStyle}>Adresse</span>
            <div
                style={{
                    gridTemplateColumns:
                        selectedType === 'Physique' ? '90% 10%' : '100%'
                }}
                className="grid">
                <Select
                    control={control}
                    error={""}
                    placeholder="Chosissez un élément"
                    size="lg"
                    label={"Type"}
                    name="type"
                    options={[{label: 'Physique', value: 'Physique'}, {label: 'Virtuel', value: 'Virtuel'}]}
                />
                {selectedType === 'Physique' && (
                    <div className="relative flex justify-center items-center">
                        {!position && (
                            <span
                                className="absolute text-red-600 font-bold left-[22%] top-[22%] text-lg material-flex-icon">
                          error
                        </span>
                        )}
                        <span
                            onClick={() => setIsOpen(!isOpen)}
                            className="material-flex-icon text-4xl text-blue-600 cursor-pointer">
                        location_on
                      </span>
                    </div>
                )}
            </div>
            <MapModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <Map position={position} onChoosePosition={(position)=>{
                    setPosition(position)
                    setIsOpen(false)
                }}/>
            </MapModal>
            <div className="flex gap-3 justify-end w-full">
                <Button size="sm" label="Associer organisation" color="primary" styling="outline" onClick={handleSwitch}/>
                <Button size="sm" label="Créer organisation" color="primary" styling="solid" onClick={handleSubmit(onSubmit,(errors)=>(console.log(errors)))}/>
            </div>
        </div>
    </div>)
}
