import { FC, useState } from 'react';
import { IconSize } from '../../../../../shared/utils/enums';
import FavoriteSvg from '../../../../../shared/components/icons/FavoriteSvg.tsx';
import styles from './table-items.module.css';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg.tsx';
import logo from '../../../assets/icons/logo-app.svg';
import { FavoriteEserviceRequest, FavoriteGuichetRequest } from '../../../types/data.types.ts';
import { MaterialIcon } from '../../../../../shared/components/icons/MaterialIcon.tsx';
import { Menu } from '../../../../../shared/components/index.tsx';
import { Eservice } from '../cards/base-cards/CardEservice.tsx';

interface RowTableProps {
  item:  Eservice;
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  setFavoriteEservice?:(params:FavoriteEserviceRequest)=>void;
  onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  icon?:string;
}
export const RowTableEserviceUsager: FC<RowTableProps> = (props) => {
  const [favorite, setFavorite] = useState<boolean>(
    props.item.favorite || false
  );
  const handleSelect = (value: string) => {
    if (props.onSelectDropDown) {
      props.onSelectDropDown({
        id: props.item.id,
        selectedValue: value
      });
      return;
    }
  };
  const menuIcon = (
    <MaterialIcon label="more_vert" className={styles.menuIcon} />
  );
  const renderMenu = () => {
    if (props.dropDownData) {
      return (
        <Menu
          className={styles.moreIcon}
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={props.dropDownData}
        />
      );
    }
    return null;
  };
  return (
    <tr key={props.item.id}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <span
            onClick={() => {
              setFavorite(!favorite);
              if (props.setFavoriteParams) {
                props.setFavoriteParams({
                  guichetId: props.item.id,
                  favorite: !props.item.favorite
                });
              }else if(props.setFavoriteEservice){
                props.setFavoriteEservice({
                  idEservice: props.item.id,
                  favorite: !props.item.favorite
                });
              }
            }}
            className="cursor-pointer">
            {favorite ? (
              <FavoriteSvg className={styles.starF} size={IconSize.PX18} />
            ) : (
              <FavoriteSvg className={styles.starNF} size={IconSize.PX18} />
            )}
          </span>
          <img
            src={props.item.imageUrl || logo}
            alt={`${props.item.name} logo`}
            className="w-8 h-8 rounded-full"
          />
        </div>
      </td>
      <td className={styles.nameCaseContainer}>
        <div className={`${styles.nameCase} !grid`}>
          <span>{props.item.name}</span>
          <span className='flex items-center gap-1'><span className={styles.emailPersonnel}>propriété de </span>{props.item.guichetName}
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          </span>
        </div>
      </td>
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>{props.item.isKaraz ? 'Karaz' :'Externe' }</div>
      </td>
    
      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
        {props.item.industry ? <><i className="material-flex-icon text-lg text-blue-600">{props.icon}</i>{props.item.industry} </>: 'N/A'}
          <div className={styles.headerIcons}>{renderMenu()}</div>
        </div>
      </td>
    </tr>
  );
};
