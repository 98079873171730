import {ApiJaxRsResponse, DynamicListJaxRsRequest} from "../../../../shared/types";
import {DYNAMIC_LIST_API_PATH} from "../../../../shared/utils/constants";

export const QUESTIONS_OPTION_API_PATH:ApiJaxRsResponse ={
 path:"/karazal/ow-kls-extrat/appmanager/getOnboardingQuestions",
 apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
}
export const INDUSTRIES_LIST_API_PATH:DynamicListJaxRsRequest ={
 ...DYNAMIC_LIST_API_PATH,
resource:"resources/compte/organisme/Industries"
}
export const SUBMIT_QA_API_PATH:ApiJaxRsResponse ={
 path:"/karazal/ow-kls-extrat/appmanager/submitFirstStep",
 apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
}

export const SUBMIT_ATTACH_DOCS_API_PATH:ApiJaxRsResponse ={
 path:"/karazal/ow-kls-extrat/appmanager/",
 apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
}



