import { useState, useEffect, SetStateAction} from "react";
import FilterSvg from "../../../../../shared/components/icons/FilterSvg";
import { FilterBar } from "../../../components/ui/filter-bar/FilterBar"
import { FilterBarContentProps } from "../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../components/ui/filter-bar/FilterBarInput";
import ListSvg from "../../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../../shared/components/icons/GridSvg";
import { PageContentContainer } from "../../../layouts";
import { Administrator } from "../../../components/ui/cards/base-cards/CardAdministrators";
import { BreadCrumb, EmptyData, NotificationCard, SimpleModal } from "../../../../../shared/components";
import { MaterialIcon } from "../../../../../shared/components/icons";
import { FilterBarSortByProps } from "../../../components/ui/filter-bar/FilterBarSortBy";
import { useHandleDomLocation } from "../../../hooks";
import CheckCircle from "../../../../../shared/components/icons/CheckCircle";
import { Table } from "../../../components/ui/table/Table";
import {  AdministrateusPageRequest } from "../../../types/data.types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../shared/stores/store";
import { useGetRequestJoinAdministrateusPage, useSendWindowInvitation } from "../../../api/window/caller";
import { message } from "antd";
import { UserCard } from "../../../components/ui";
import { InputText} from "../../../../../shared/components/controlled-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {  WindowInvitationRequest} from "../../../types/data/eservice.types";
import { useHandleTranslation } from "../../../../../shared/hooks";
import classNames from "classnames";
import { NotificationType } from "../../../../../shared/utils/enums/components.enum";
import SendSVG from "../../../../../shared/components/icons/SendSVG";
import {  WindowInvitationListResponse } from "../../../types/data/window.types";
import { InputText as NativeInputText} from "../../../../../shared/components";
import { senAdminInvitationschema } from "../../../holders/schemas/senAdminInvitationschema";


  export const AdministrateusPage = () => {
  const { profilePathName } = useHandleDomLocation()
  const [searchValue, setSearchValue] = useState<string>('');
  const [approved, setApproved] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);
  const [totalNumber,setTotalNumber ] = useState(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  
  const [AdministratorData, setAdministratorData] = useState<Administrator[]>([]);
  const [role, setRole] = useState<string>('');
  const [activation, setActivation] = useState<string>('');

  const login = useSelector((state: RootState) => state.auth.userProfile?.userId);
  console.log('login', login);

  const getRequestJoinAdministrateusPage = useGetRequestJoinAdministrateusPage({});
  
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>

  const [selectedRowOption, setSelectedRowOption] = useState<{ 
    id: string; 
    selectedValue: string; 
    emailPersonnel: string; 
    nameUser: string; 
    logoUser: string; 
    role: string; 
    ownerLogin: string } | null>(null);


 
    console.log('selectedRowOption.role', selectedRowOption?.role);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const postSendInvitation = useSendWindowInvitation();

  const selectedGuichet = useSelector((state: RootState) => state.guichets.selectedGuichet);
  const guichetId = 'id' in selectedGuichet ? selectedGuichet.id : null;
  const [, setRequestAdminData] = useState<WindowInvitationListResponse[]>([]);
  

  const [cardPropsData, setCardPropsData] = useState<Administrator[]>([]);
  const [selectedButton, setSelectedButton] = useState('approuve')
  const [, setStatusRequest] = useState('');


  const errorMessageStyles = classNames({
    ['!my-4']: true,
    ['notShow']: !isErrorDisplayed, 
    ['show transition-all duration-500']: isErrorDisplayed 
  });
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [actifFiltre, setActifFiltre] = useState<string>('');
  const [roleFiltre, setRoleFiltre] = useState<string>('');
  const { 
    control, 
    handleSubmit, 
    formState: { errors } ,
    reset
  } = 
  useForm({
    resolver: yupResolver(senAdminInvitationschema)
  });

  
const handleCloseAddAdminModal = () => {
  setShowAddAdminModal(false);
  reset({ email: '' });
  setErrorMessage('');
  setIsErrorDisplayed(false);
};


const { t: tO } = useHandleTranslation('window');

  
  const rolesList = [
    { value: 'SUPER_ADMINISTRATEUR', label: 'Super_administrateur' },
    { value: 'PROPRIETAIRE', label: 'Propriétaire' },
  ];

  const activationList = [
    { label: 'Actif', value: 'actif' },
    { label: 'Inactif', value: 'inactif' },
  ];
  useEffect(() => {
    if (actifFiltre) {
      setResetFilters(true)
    } else {
      setResetFilters(false)
    }
    if (getRequestJoinAdministrateusPage.isSuccess && getRequestJoinAdministrateusPage.data && getRequestJoinAdministrateusPage.data.status != 401) {
      const administrateusPageData = getRequestJoinAdministrateusPage.data;
      const totalItems = getRequestJoinAdministrateusPage.data.body.pagination.totalLength;
      if (administrateusPageData) {
        setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
        const transformedData: Administrator[] = administrateusPageData.body.data.map((item: AdministrateusPageRequest) => ({
          numPage: 1,       
           pageSize: 10,
          id: String(item.userId),
          email: item.emailPersonnel || '',
          firstName: item.nameUser || '',
          loginUser: item.loginUser,
          color: "var(--blue-700)",
          profilePicture: item.logoUser || '',
          statusRequest: item.statusRequest,
          idRequest: item.idRequest,
          roleDetails: {
            role: item.roleUserDemanded || '',
            status: item.roleStatusUser || '',
            roleType: item.statusRequest || '',
          }
          
        }));
    
        setAdministratorData(transformedData);
        setCardPropsData(transformedData);
      }
      return;
    }
  }, [getRequestJoinAdministrateusPage.fetchStatus]);
  useEffect(() => {
    let filteredData = [...AdministratorData];

    if (approved && !invitations) {
      filteredData = filteredData.filter(item => item.roleDetails?.roleType === "Acceptée");
    }

    if (invitations && !approved) {
      filteredData = filteredData.filter(item => item.roleDetails?.roleType === "Brouillon");
    }
    if (searchValue.trim() !== '') {
      filteredData = filteredData.filter(item =>
        item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.email.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (role) {
      filteredData = filteredData.filter(item =>
        item.roleDetails?.role?.toUpperCase() === role.toUpperCase()
      );
    }

    if (activation) {
      filteredData = filteredData.filter(item =>
        item.roleDetails?.status?.toUpperCase() === activation.toUpperCase()
      );
    }


    setCardPropsData(filteredData);
    setTotalNumber(filteredData.length);
    setIsFetching(false);

    const startIndex = (page - 1) * 10;
  const endIndex = startIndex + 10;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  setCardPropsData(paginatedData);
  setTotalNumber(filteredData.length);  

  setIsFetching(false);
  }, [approved, invitations, searchValue, role, activation, AdministratorData,page]);

  const fBsAdminList: FilterBarSortByProps = {
    items: [
      {
        data: rolesList,
        label: 'Rôle',
        placeHolder: 'Rôle',
        selectedValue: role,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == roleFiltre) return;
            setResetFilters(true)
            setRequestAdminData([]);
            setRole(e);
            setRoleFiltre(e);
          }
        }
      },
      {
        data: activationList,
        label: 'Activation',
        placeHolder: 'Activation',
        selectedValue: activation,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            setResetFilters(true);
            setActivation(e);
          }
        }
      },]
  };


  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [isModalOpen,setModalStatus]=useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");


  const handleAdminClick = () => {
    setModalStatus(true);
    setShowAddAdminModal(true);
    setErrorMessage(''); 
    setIsErrorDisplayed(false); 
};
const [messageApi, contextHolder] = message.useMessage();

const handleAddAdmin = (formData: { email: string; }) => {
  const { email} = formData;
  setErrorMessage('');
  let hasError = false;

  if (email ) {
    if (!guichetId) return;

    const data: WindowInvitationRequest = {
      role: "SUPER_ADMINISTRATEUR",
      guichetId: guichetId,
      email: email,
    };

    postSendInvitation.mutate(data, {
        onSuccess: (response: { message: { messageType: string; messageContent: SetStateAction<string>; }; }) => {
        if (response.message.messageType === "SUCCESS") {
          messageApi.success("Invitation envoyée avec succès !");
          reset({ email: '' });
          
          handleCloseAddAdminModal();
        } else {
          setErrorMessage(response.message.messageContent);
          setIsErrorDisplayed(true);
        }
      },
      onError: () => {
        setErrorMessage("Erreur lors de l'envoi de l'invitation");
        setIsErrorDisplayed(true);
      }
    });
  } else {
    if (!email) {
      hasError = true;
    }
    if (hasError) return;
  }
};



const fBc: FilterBarContentProps = {
  label: 'Administrateurs',
  searchLength: selectedButton === 'invitations' ? totalNumber : 0, 
  buttons: [
    {
      icon: CheckCircle,
      label: 'Approuvés',
      isSelected: selectedButton === 'approuve',
      handleClick: () => {
        if (selectedButton === 'approuve') return;
        if (page > 1) {
          setPage(1);
        }
        setSelectedButton('approuve');
        setStatusRequest('ACCEPT')

      }
    },
    {
      icon: SendSVG,
      label: 'Invitations',
      isSelected: selectedButton === 'invitations',
      handleClick: () => {
        if (selectedButton === 'invitations') return;  
        if (page > 1) {
          setPage(1);
        }
        setSelectedButton('invitations');
        setInvitations(true); 
        setApproved(false); 
      }
    },

  ],
  buttonAddArgs: {
    label: 'Ajouter administrateur',
    onClick: handleAdminClick,
    color: "primary",
    styling: "ghost",
    size: "sm",
    leftIcon: <MaterialIcon label="add" />
  },
};


const fBi: FilterBarInputProps = {
  filterBarInputId: 'search',
  filterBarInputPlaceHolder: 'Rechercher',
  filterBarInputType: 'text',
  filterBarInputValue: searchValue,

  filterButton: selectedButton === 'approuve' ? {
    handleClick: (showFilters) => {
      setShowFilters(!showFilters);
    },
    icon: FilterSvg,
    label: 'Filtres',
    selected: showFilters,
    reset: resetFilters,
    resetClick: () => {
      setActifFiltre('');
      setRoleFiltre('');
      setResetFilters(false);
    }
  } : undefined,

  isGridDisplayMode: false,

  onChange: (e) => {
    if (page > 1) {
      setPage(1);
    }
    setCardPropsData([]);
    setSearchValue(e);
  },
  onClear: () => {
    if (searchValue.trim() !== '') {
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue('');
    }
  },
  onSearch: (e) => {
    if (page > 1) {
      setPage(1);
    }
    const filteredData = AdministratorData.filter(item =>
      item.firstName.toLowerCase().includes(e.toLowerCase()) ||
      item.email.toLowerCase().includes(e.toLowerCase())
    );
    setSearchValue(e);
    setCardPropsData(filteredData);
  },

  listButton: {
    handleClick: (showTable) => {
      if (showTable) { return; }
      setShowGrid(false);
      setShowTable(!showTable);
    },
    icon: ListSvg,
    selected: showTable
  },
  gridButton: {
    handleClick: (showGrid) => {
      if (showGrid) { return; }
      setShowTable(false);
      setShowGrid(!showGrid);
    },
    icon: GridSvg,
    selected: showGrid
  },
};



  const BreadCrumbItems = [{ icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}` }, { title: "Administrateurs", href: "" }]
  console.log('selectedRowOption.selectedValue', selectedRowOption?.selectedValue)
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 rounded-md">
      {contextHolder}
      <BreadCrumb items={BreadCrumbItems} />
          <FilterBar
           filterBarContent={fBc} 
           filterBarInput={fBi} 
           filterBarSortBy={fBsAdminList} />


          {showAddAdminModal && (
      <SimpleModal
        type="primary"
        cancelLabel="Annuler"
        validateLabel="Ajouter"
        isOpen={isModalOpen}
        onValidate={handleSubmit(handleAddAdmin)}
        onCancel={handleCloseAddAdminModal} 
        afterClose={handleCloseAddAdminModal}
        title={"Ajout administrateur"}
        className=" w-[520px] h-fits"
      
       >
          <div className="flex flex-col gap-5 p-6">
          {errorMessage && (                
       <NotificationCard
          type={NotificationType.ERROR}
          className={errorMessageStyles} >
          {errorMessage}
        </NotificationCard>
      )}
           <InputText
              size="sm"
              label="Email"
              control={control}
              name="email"
              placeholder={tO('invite_Member_Email_placeholder')}
              error={errors.email?.message}
              withError
            />
            <NativeInputText  
              size="sm" 
              label="Rôle" 
              value="Super administrateur" 
              disabled 
          />

          </div>
          </SimpleModal>
      )}

          {(showGrid || showTable) && selectedButton === 'invitations'&& (cardPropsData.length === 0 && !isFetching) ? (<EmptyData icon={emptyIcon} title={"Aucun administrateus pour l'instant"} description={"La liste des administrateus créés sera affichée ici"} />) : showGrid && selectedButton === 'invitations'&& (cardPropsData.length >= 1 || isFetching) ?
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-auto overflow-auto">
              {cardPropsData.map(r => (
              <UserCard
              key={r.id}
              type={'admin'}
              color={r.color} 
              firstName={r.firstName} 
              email={r.email} 
              profilePicture={r.profilePicture ?? ""} 
              role={r.roleDetails}
              onSelectDropDownForMembers={setSelectedRowOption}
              />
              
            ))} </div>:
            showTable
             && selectedButton === 'invitations'
              && (cardPropsData.length > 0 || isFetching) ?
              (<Table 
                isFitching={isFetching} 
                type="WindowInvitation" 
                setCurrentPage={setPage} 
                currentPage={page} 
                totalItems={totalNumber} 
                onSelectDropDown={setSelectedCardOption} 
                
                headerItems={{ items: ['Administrateur','', 'Login', 'Rôle en attente',''] }} 
                rows={cardPropsData} />) 
              
            : null}
    </PageContentContainer>
  );
}
