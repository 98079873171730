import {FC, ReactNode, useEffect, useState} from "react";
import {Button} from "../button/button/Button";
import {MaterialIcon} from "../icons";
import {BaseModal} from "./base-modal/BaseModal";
import classNames from "classnames";

interface SimpleModal{
    type?:"primary" | "neutral" | "success" | "error" |"secondary";
    title?:string;
    isOpen:boolean;
    validateLabel?:string;
    cancelLabel?:string;
    onValidate?:()=>void;
    onCancel?:()=>void
    afterClose?:()=>void;
    disabled?:boolean;
    className?:string;
    children:ReactNode;
    closeOnClickOutside?:boolean
}
export const SimpleModal:FC<SimpleModal>=(props)=>{
    const {isOpen=false,title="",className="",type='primary'}=props;
    const [hasOpen,setOpenStatus]=useState<boolean>(false)
    useEffect(() => {
        setOpenStatus(isOpen)
    }, [isOpen]);

    const childrenStyle = classNames({
        ['bg-white dark:bg-slate-800']:true,
        [className]:true,
        ['rounded-lg']:!(props.cancelLabel || props.validateLabel)
    });
    const handleCloseIcon=()=>{
        setOpenStatus(false);
        if (props.afterClose) props.afterClose();
    }
    return <BaseModal isOpen={hasOpen} afterClose={handleCloseIcon} closeOnClickOutside={props.closeOnClickOutside} className="rounded-lg">
            <div className=" py-2 px-3 flex items-center justify-between border-b border-slate-200 dark:border-slate-700">
                <span className="text-slate-800 dark:text-white font-medium text-sm">{title}</span>
                <MaterialIcon label="close" className=" cursor-pointer text-base text-slate-500" onClick={handleCloseIcon}/>
            </div>

        <div className={childrenStyle}>
            {props.children}
        </div>
        {(props.validateLabel || props.cancelLabel) &&
        <div className=" rounded-lg py-2 px-3 flex justify-between items-center bg-slate-50 dark:bg-slate-950 border-t border-slate-200 dark:border-slate-700 ">
           {props.cancelLabel && <Button color="neutral" styling="outline" label={props.cancelLabel} onClick={props.onCancel} size="sm" />}
           {props.validateLabel && <Button color={type} styling="solid" label={props.validateLabel} onClick={props.onValidate} size="sm" disabled = {props.disabled}/>}
        </div>
        }
    </BaseModal>
}