import { FC, useCallback } from 'react';
import { IconSize } from '../../../../../shared/utils/enums';
import styles from './table-items.module.css';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg.tsx';
import logo from '../../../assets/icons/logo-app.svg';
import { MaterialIcon } from '../../../../../shared/components/icons/MaterialIcon.tsx';
import { Menu } from '../../../../../shared/components/index.tsx';
import { USERS_ACTIONS_NONAP } from '../../../utils/constants/components.constants.tsx';
import { EserviceMembersListResponse } from '../../../types/index.ts';
import { capitalizeFirstLetter } from '../../../../../shared/utils/helpers/components.helper.ts';

interface RowTableProps {
  type: string;
  item: EserviceMembersListResponse;
  onSelectDropDown?: (selection: {
    id: string;
    selectedValue: string;
    logoUser: string;
    eserviceName?: string;
    roleUserDemanded: string;
    organizationVerification?: string;
    emailPersonnel: string;
    nameUser: string;
    role: string;
    ownerLogin: string;
    note: string;
    roleUserApproved?: string;
    eserviceLogo?: string;
    guichetName?: string;
  }) => void;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  icon?: string;
}

export const RowTableInvitation: FC<RowTableProps> = (props) => {
  const getDropDownData = useCallback((type: string) => {
    if (!type) return;
    if (type === 'receivedInvitation') {
      return USERS_ACTIONS_NONAP;
    }
  }, []);

  const handleSelect = (value: string) => {
    if (props.onSelectDropDown) {
      props.onSelectDropDown({
        id: props.item.idRequest,
        selectedValue: value,
        logoUser: props.item.logoUser,
        eserviceName: props.item.eserviceName || '',
        roleUserDemanded: props.item.roleUserDemanded,
        emailPersonnel: props.item.emailPersonnel,
        nameUser: props.item.nameUser,
        role: props.item.roleUserDemanded,
        ownerLogin: props.item.loginUser,
        note: props.item.note,
        guichetName: props.item.guichetName,
      });
    }
  };

  const menuIcon = <MaterialIcon label="more_vert" className={styles.menuIcon} />;

  const renderMenu = () => {
    if (props.type === 'receivedInvitation') { 
      return (
        <Menu
          className={styles.moreIcon}
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={getDropDownData(props.type)}
        />
      );
    }
    return null; 
  };
  

  if (!props.item.eserviceName || !props.item.guichetName) {
    return null;
  }

  return (
    <tr key={props.item.idRequest}>

      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <img
            src={props.item.eserviceLogo || logo}
            alt={`${props.item.eserviceLogo} logo`}
            className="w-8 h-8 rounded-full"
          />
        </div>
      </td>

      <td className={`${styles.nameCaseContainer}  h-12`}>
          <span className='dark:text-white'>{props.item.eserviceName}</span>
          <span className="flex items-center gap-1">
            <span className={styles.emailPersonnel}>propriété de </span>
            {/* {props.item.guichetName} */}
            <span className="dark:text-white">{props.item.guichetName}</span>
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          </span>
      </td>

      

      <td className={`${styles.roleCaseContainer}`}>
        <div className={styles.aCase}>
          {props.item.roleUserDemanded === 'ADMINISTRATEUR' ? (
            <div>
              <span className={styles.statutContainerAdmin}>
                {props.item.roleUserDemanded}
              </span>
            </div>
          ) : (
            <div>
              <span className={styles.statutContainerCD}>
                {props.item.roleUserDemanded}
              </span>
            </div>
          )
          }
        </div>
      </td>

      {props.type === 'acceptedInvitation' && (
        <td className={`${styles.activationCaseContainer} dark:border-b-slate-700`}>
          <div className={styles.lCase}>
            <span>{capitalizeFirstLetter(props.item.roleStatusUser)}</span>
          </div>
        </td>
      )}

      {props.type === 'receivedInvitation' && (
        <td className={`${styles.lCaseContainer} w-12 h-12 dark:border-b-slate-700`}>
          <div className={styles.lCase}>
            <div className={styles.headerIcons}>{renderMenu()}</div>
          </div>
        </td>
      )}
    </tr>
  );
};
